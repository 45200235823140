import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'antd';
import authActions from 'src/modules/auth/authActions';
import { useDispatch } from 'react-redux';
import { i18n } from 'src/i18n';

const TimerModal = (props) => {
  const seconds = 60 * 5;

  const secondsToTime = (timeInSec) => {
    let hours = Math.floor(timeInSec / (60 * 60));

    let divisor_for_minutes = timeInSec % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  const [timeLeft, setTimeLeft] = useState(seconds);
  const [displayTime, setDisplayTime] = useState(
    secondsToTime(seconds),
  );
  const [intervalID, setIntervalID] = useState(null);
  const dispatch = useDispatch();

  const countDown = () => {
    setTimeLeft(timeLeft - 1);
    setDisplayTime(secondsToTime(timeLeft - 1));
  };

  const signOut = () => {
    clearInterval(intervalID);
    props.handleTimerModalVisible(false);
    setTimeLeft(seconds);
    dispatch(authActions.doSignout());
  };

  const continueAndResetTimer = () => {
    clearInterval(intervalID);
    props.handleTimerModalVisible(false);
    setTimeLeft(seconds);
    setDisplayTime(secondsToTime(seconds));
  };

  useEffect(() => {
    if (props.visible) {
      if (!timeLeft) dispatch(authActions.doSignout());

      const intervalId = setInterval(() => {
        countDown();
      }, 1000);

      setIntervalID(intervalID);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft, props.visible]);

  return (
    <div>
      {props.visible && (
        <Modal
          title="Timeout"
          visible={props.visible}
          closable={false}
          maskClosable={false}
          onOk={continueAndResetTimer}
          onCancel={signOut}
          okText="Click to Continue"
          cancelText="Click to Sign out"
        >
          <div>{i18n('common.auth.timeout')}</div>
          <div>
            {displayTime.h} {i18n('common.hours')}{' '}
            {displayTime.m} {i18n('common.minutes')}{' '}
            {displayTime.s} {i18n('common.seconds')}{' '}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TimerModal;
