import DependentService from 'src/modules/dependent/dependentService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const tenantId = AuthCurrentTenant.get();

const prefix = 'DEPENDENT';

const dependentFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInitMain: (id) => async (dispatch) => {
    try {
      dispatch({
        type: dependentFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        // record = await DependentService.findMain(id);
        record = await TenantService.tenant_Find_Type('Dependent', 'Harta');	
      console.log("return record",record)
      }
// console.log("record",record)
      dispatch({
        type: dependentFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dependentFormActions.INIT_ERROR,
      });

      getHistory().push('/dependent');
    }
  },

  doCreateMain: (values) => async (dispatch) => {
    try {
      dispatch({
        type: dependentFormActions.CREATE_STARTED,
      });

      await DependentService.create(values,true);

      dispatch({
        type: dependentFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.dependent.create.success'),
      );

      getHistory().push('/willListPage');
      // getHistory().push('/dependent');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dependentFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdateMain: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: dependentFormActions.UPDATE_STARTED,
      });

      await DependentService.update(id, values,true);

      dispatch({
        type: dependentFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.dependent.update.success'),
      );

      getHistory().push('/willListPage');
      // getHistory().push(`/dependent/${id}`);

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dependentFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default dependentFormActions;
