import ExecutorService from 'src/modules/executor/executorService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import TenantService from 'src/modules/tenant/tenantService';
import globalActions from 'src/modules/global/globalActions';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const prefix = 'EXECUTOR_MAIN';

const executorMainActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInitMain: (id) => async (dispatch) => {
    try {
      dispatch({
        type: executorMainActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);
      var tenant = AuthCurrentTenant.get();
      const platform = window.location.pathname.includes('hibah') ? 'Hibah' :null;
      
      if (isEdit) {
        // record = await ExecutorService.findMain(id);
        record = await TenantService.tenant_Find_Type('Executor',platform);	
      }

      dispatch({
        type: executorMainActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: executorMainActions.INIT_ERROR,
      });

      getHistory().push('/executor');
    }
  },

  doCreateMain: (values) => async (dispatch) => {
    try {
      dispatch({
        type: executorMainActions.CREATE_STARTED,
      });

      await ExecutorService.create(values,true);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: executorMainActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.executor.create.success'),
      );

      getHistory().push('/willListPage');
      // getHistory().push('/executor');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: executorMainActions.CREATE_ERROR,
      });
    }
  },

  doUpdateMain: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: executorMainActions.UPDATE_STARTED,
      });

      await ExecutorService.update(id, values,true);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: executorMainActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.executor.update.success'),
      );

      getHistory().push('/willListPage');
      // getHistory().push(`/executor/${id}`);

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: executorMainActions.UPDATE_ERROR,
      });
    }
  },
};

export default executorMainActions;
