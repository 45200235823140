import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  // {
  //   name: 'id',
  //   label: i18n('entities.account.fields.id'),
  // },
  // {
  //   name: 'accType',
  //   label: i18n('entities.account.fields.accType'),
  // },
  // {
  //   name: 'accInstitution',
  //   label: i18n('entities.account.fields.accInstitution'),
  // },
  // {
  //   name: 'accNumber',
  //   label: i18n('entities.account.fields.accNumber'),
  // },
  // {
  //   name: 'accValue',
  //   label: i18n('entities.account.fields.accValue'),
  //   render: exporterRenders.decimal(),
  // },
  // {
  //   name: 'accCurrency',
  //   label: i18n('entities.account.fields.accCurrency'),
  // },
  // {
  //   name: 'accFile',
  //   label: i18n('entities.account.fields.accFile'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'accFileLocation',
  //   label: i18n('entities.account.fields.accFileLocation'),
  // },
  // {
  //   name: 'accRemarks',
  //   label: i18n('entities.account.fields.accRemarks'),
  // },
  {
    name: 'createdAt',
    label: i18n('entities.account.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
];
