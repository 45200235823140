import {
  CloseOutlined,
  CrownOutlined,
  FileAddOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SettingOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  Alert,
  Link as Link2,
  Snackbar,
  Typography,
} from '@mui/material';
import {
  Avatar,
  Card,
  Dropdown,
  Layout,
  Menu,
  Tooltip,
} from 'antd';
import {
  default as Moment,
  default as moment,
} from 'moment';
import { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getConfig } from 'src/config';
import { i18n } from 'src/i18n';
import assistantService from 'src/modules/assistant/assistantService';
import { doAccessListRequest } from 'src/modules/auth/accessListChecker';
import authActions from 'src/modules/auth/authActions';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import EncryptionTool from 'src/modules/encryption/dataEncryption';
import globalActions from 'src/modules/global/globalActions';
import globalSelectors from 'src/modules/global/globalSelectors';
import layoutActions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import { getHistory } from 'src/modules/store';
import submenus_settings from 'src/view/dashboard/subMenu/submenus_settings';
import HeaderWrapper from 'src/view/layout/styles/HeaderWrapper';
import Ticket from 'src/view/layout/Ticket';
import NotificationBell from 'src/view/notification/NotificationBell';
// import SpecificGiftListFilter from 'src/view/specificGift/list/SpecificGiftListFilter';
import AppReleaseNoteModal from './AppReleaseNoteModal';

const { Header: AntHeader } = Layout;

const Header = (props) => {
  const dispatch = useDispatch();
  const userDropdownAvatar = useSelector(
    authSelectors.selectCurrentUserAvatar,
  );

  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const logoUrl = useSelector(
    authSelectors.selectMenuLogoUrl,
  );

  const doSignout = () => {
    localStorage.removeItem('tenant');
    localStorage.removeItem('tenantAssistant');
    AuthCurrentTenant.clearAllLocal();
    dispatch(authActions.doSignout());
  };

  const doNavigateToProfile = () => {
    getHistory().push('/profile');
  };

  const doNavigateToSettings = () => {
    getHistory().push('/user-settings');
  };

  const isEditForm = useSelector(
    globalSelectors.selectIsEditForm,
  );

  const [open, setOpen] = useState(false);
  const [noShowAgain, setNoShowAgain] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const isEditFormLocal = EncryptionTool.decrypt(
      localStorage.getItem('isEditForm'),
    );

    if (
      (isEditForm === true ||
        isEditFormLocal?.toLowerCase?.() === 'true') &&
      location.pathname !== '/will-preview'
    ) {
      setOpen(true);
    }
  }, [isEditForm, location]);

  const noshowEditForm = () => {
    localStorage.setItem(
      'isEditForm',
      EncryptionTool.encrypt(JSON.stringify(false)),
    );

    dispatch(globalActions.doCloseEditForm());
  };

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const [isAdmin, setIsAdmin] = useState(false);
  const [
    assistantHasAccessRight,
    setAssistantHasAccessRight,
  ] = useState(false);

  const [shouldOpenModal, setShouldOpenModal] =
    useState(false);
  const handleCloseModal = () => {
    setShouldOpenModal(false); // Set shouldOpenModal to false here
  };

  useEffect(() => {
    async function fetchPermission() {
      if (
        currentTenant['createdBy'] === currentUser['id']
      ) {
        setIsAdmin(true);
        setAssistantHasAccessRight(true);
      } else {
        setIsAdmin(false);
        let assistant = await assistantService.findByUserId(
          currentUser['id'],
        );
        let accessRightVal = assistant['accessRight'];
        setAssistantHasAccessRight(accessRightVal);
      }
    }
    fetchPermission();
  }, [isAdmin]);

  const userMenu = (
    <Menu selectedKeys={[]}>
      <Menu.Item
        onClick={doNavigateToProfile}
        key="userCenter"
      >
        <UserOutlined />
        {i18n('auth.profile.title')}
      </Menu.Item>
      <Menu.Item
        onClick={doNavigateToSettings}
        key="userSettings"
      >
        <SettingOutlined />
        {i18n('auth.profile.settings')}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          getHistory().push({
            pathname: '/will-plan',
            state: { page: 'Plan_Page' },
          });
        }}
        key="plan"
      >
        <CrownOutlined />
        {i18n('app.plan')}
      </Menu.Item>
      <Menu.Divider />
      {assistantHasAccessRight &&
        submenus_settings.map((menu) => (
          <Menu.Item key={menu.path}>
            <Link to={menu.path}>
              {menu.icon}
              <span>{menu.label}</span>
            </Link>
          </Menu.Item>
        ))}

      <Menu.Item
        onClick={() => setShouldOpenModal(true)}
        key="appReleaseNoteMI"
      >
        <InfoCircleOutlined />
        {getConfig().REACT_APP_VERSION}
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item onClick={doSignout} key="logout">
        <LogoutOutlined />
        {i18n('auth.signout')}
      </Menu.Item>
    </Menu>
  );

  const [toggle, setToggle] = useState(false);
  const isToggle = () => {
    setToggle(!toggle);
  };

  const helpMenu = (
    <Menu selectedKeys={[]}>
      <Menu.Item onClick={isToggle} key="raiseTicket">
        <FileAddOutlined />
        {i18n('ticket.title')}
      </Menu.Item>
      <Ticket
        url={props.match.url}
        toggle={toggle}
        isToggle={isToggle}
      />
      <Menu.Item
        onClick={() => getHistory().push('/learnMore')}
        key="userGuide"
      >
        <ReadOutlined />
        {i18n('app.userGuide')}
      </Menu.Item>
    </Menu>
  );

  const CalcRemainingDay = () => {
    var date1 = new Date(currentTenant.planUpdated);

    var subcYear = date1.getFullYear();
    var subMonth = date1.getMonth();
    var subDay = date1.getDate();
    var expiredDate = new Date(
      subcYear + 1,
      subMonth,
      subDay,
    );

    var Difference_In_Time =
      expiredDate.getTime() - date1.getTime();
    var Difference_In_Days =
      Difference_In_Time / (1000 * 3600 * 24);

    return {
      // day: Math.ceil(Difference_In_Days),
      day: 1,
      date: Moment(expiredDate).format('YYYY-MM-DD'),
    };
  };

  const zoomIn = () => {
    var zoomScale = props.zoomScale;
    if (props.zoomScale > 500) zoomScale = props.zoomScale;
    else
      zoomScale =
        (props.zoomScale !== undefined
          ? props.zoomScale
          : 100) + 10;
    props.onZoomChange(zoomScale);
  };

  const zoomOut = () => {
    var zoomScale = props.zoomScale;
    if (props.zoomScale <= 50) zoomScale = props.zoomScale;
    else
      zoomScale =
        (props.zoomScale !== undefined
          ? props.zoomScale
          : 100) - 10;

    props.onZoomChange(zoomScale);
  };

  const resetTransform = () => {
    props.onZoomChange(100);
  };

  const [showToolBar, setShowToolBar] = useState(false);
  const handleToolbar = () => {
    setShowToolBar(!showToolBar);
  };

  //#region
  useEffect(() => {
    // AuthCurrentTenant.clearAccessList();
    var accesslist = AuthCurrentTenant.getAccessList();
    if (accesslist === null) doAccessListRequest();
    else {
      var duration = moment.duration(
        moment().diff(moment(accesslist.retrieve_time)),
      );
      if (duration.asMinutes() > 15) doAccessListRequest();
    }
  }, []);

  //#endregion

  return (
    <HeaderWrapper>
      <AntHeader
        style={{
          position: isMobile ? 'inherit' : 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <Grid container>
          <Grid
            item
            xs={
              isMobile && !isTablet ? 4 : isTablet ? 8 : 9
            }
            style={{ height: '40px' }}
          >
            {logoUrl ? (
              <Link to="/">
                <img
                  src={logoUrl}
                  width={props.isMobile ? '110px' : '140px'}
                  alt={i18n('app.title')}
                />
              </Link>
            ) : (
              <h2>
                <Link to="/">{i18n('app.title')}</Link>
              </h2>
            )}
          </Grid>
          <Grid
            container
            item
            xs={
              isMobile && !isTablet ? 8 : isTablet ? 4 : 3
            }
            style={{
              justifyContent: isMobile
                ? 'space-between'
                : 'flex-end',
            }}
          >
            {!props.isMobile && (
              <>
                <Grid item xs={4}>
                  {currentTenant.plan !==
                    'comprehensive' && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        getHistory().push({
                          pathname: '/will-plan',
                          state: { page: 'Plan_Page' },
                        });
                      }}
                      style={{
                        color: 'blue',
                        borderColor: 'blue',
                      }}
                    >
                      {i18n('plan.upgrade')}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Dropdown
                    overlay={helpMenu}
                    trigger={['click']}
                  >
                    <IconButton
                      style={{
                        backgroundColor: 'White',
                        height: 20,
                      }}
                    >
                      <QuestionCircleOutlined /> &nbsp;{' '}
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: 'normal',
                        }}
                      >
                        {i18n('app.getHelp')}
                      </span>
                    </IconButton>
                  </Dropdown>
                </Grid>
                <Grid item xs={2}>
                  <NotificationBell />
                </Grid>
                <Grid item xs={2}>
                  <Dropdown
                    className="user-dropdown"
                    overlay={userMenu}
                    trigger={['click']}
                  >
                    <div className="user-dropdown-content">
                      <Avatar
                        className="user-dropdown-avatar"
                        size={27}
                        src={
                          userDropdownAvatar || undefined
                        }
                        alt="avatar"
                        icon={
                          userDropdownAvatar ? undefined : (
                            <UserOutlined />
                          )
                        }
                      />
                    </div>
                  </Dropdown>
                </Grid>
              </>
            )}

            {props.isMobile && (
              <>
                <Grid item xs={5}>
                  {currentTenant.plan !==
                    'comprehensive' && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        getHistory().push({
                          pathname: '/will-plan',
                          state: { page: 'Plan_Page' },
                        });
                      }}
                      style={{
                        color: 'blue',
                        borderColor: 'blue',
                      }}
                    >
                      {i18n('plan.upgrade')}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={handleToolbar}
                    style={{
                      backgroundColor: 'White',
                      width: 30,
                      height: 20,
                    }}
                  >
                    <ToolOutlined />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <NotificationBell />
                </Grid>
                <Grid item xs={2}>
                  <Dropdown
                    className="user-dropdown"
                    overlay={userMenu}
                    trigger={['click']}
                  >
                    <div className="user-dropdown-content">
                      <Avatar
                        className="user-dropdown-avatar"
                        size={27}
                        src={
                          userDropdownAvatar || undefined
                        }
                        alt="avatar"
                        icon={
                          userDropdownAvatar ? undefined : (
                            <UserOutlined />
                          )
                        }
                      />
                    </div>
                  </Dropdown>
                </Grid>
                <Grid item xs={1}>
                  {menuVisible ? (
                    <CloseOutlined onClick={doToggleMenu} />
                  ) : (
                    <MenuFoldOutlined
                      onClick={doToggleMenu}
                    />
                  )}
                </Grid>
                {showToolBar && (
                  <Card
                    style={{
                      width: '100%',
                      left: 0,
                      top: 50,
                      position: 'absolute',
                      zIndex: 1000,
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      style={{ width: '100%' }}
                    >
                      <Grid container item xs={9}>
                        <Grid item xs={2}>
                          <Tooltip title="Zoom Out">
                            <IconButton
                              onClick={zoomOut}
                              style={{
                                backgroundColor: 'White',
                                width: 30,
                                height: 20,
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {props.zoomScale + '%'}
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title={'Zoom In'}>
                            <IconButton
                              onClick={zoomIn}
                              style={{
                                backgroundColor: 'White',
                                width: 30,
                                height: 20,
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      {/* <Grid container item xs={3} justify={'flex-end'}> */}
                      <Grid
                        container
                        xs={3}
                        justify={'flex-end'}
                      >
                        <Grid item xs={4}>
                          <Tooltip title="Reset">
                            <IconButton
                              onClick={resetTransform}
                              style={{
                                backgroundColor: 'White',
                                width: 30,
                                height: 20,
                              }}
                            >
                              <CachedIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Dropdown
                            overlay={helpMenu}
                            trigger={['click']}
                          >
                            <IconButton
                              style={{
                                backgroundColor: 'White',
                                width: 30,
                                height: 20,
                              }}
                            >
                              <QuestionCircleOutlined />
                            </IconButton>
                          </Dropdown>
                        </Grid>
                      </Grid>
                      {/* </Grid> */}
                    </Grid>
                  </Card>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </AntHeader>
      {shouldOpenModal && (
        <AppReleaseNoteModal
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          severity="info"
          onClose={() => {
            setOpen(false);
          }}
        >
          {i18n(
            'entities.notification.will.changesDetected',
          )}
          <br />
          <div style={{ textAlign: 'center' }}>
            <Link2
              component="button"
              onClick={() => {
                getHistory().push('/will-preview');
                setOpen(false);
                if (noShowAgain) {
                  noshowEditForm();
                }
              }}
            >
              <Typography
                style={{ textDecoration: 'underline' }}
                display="inline"
              >
                {i18n(
                  'entities.notification.will.generatenow',
                )}
              </Typography>
            </Link2>
          </div>
        </Alert>
      </Snackbar>
    </HeaderWrapper>
  );
};

export default Header;
