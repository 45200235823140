import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.solicitor.fields.id'),
  },
  {
    name: 'solicitorPhoto',
    label: i18n('entities.solicitor.fields.solicitorPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'solicitorName',
    label: i18n('entities.solicitor.fields.solicitorName'),
  },
  {
    name: 'solicitorPhone',
    label: i18n('entities.solicitor.fields.solicitorPhone'),
  },
  {
    name: 'solicitorPhone2',
    label: i18n('entities.solicitor.fields.solicitorPhone2'),
  },
  {
    name: 'solicitorEmail',
    label: i18n('entities.solicitor.fields.solicitorEmail'),
  },
  {
    name: 'solicitorAddress',
    label: i18n('entities.solicitor.fields.solicitorAddress'),
  },
  {
    name: 'solicitorRemarks',
    label: i18n('entities.solicitor.fields.solicitorRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.solicitor.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.solicitor.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
