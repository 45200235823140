import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

// import BeneficiaryService from 'src/modules/beneficiary/beneficiaryService';
// import ExecutorService from 'src/modules/executor/executorService';
// import LegalGuardianService from 'src/modules/legalGuardian/legalGuardianService';
import ContactListService from 'src/modules/contactList/contactListService';
import TenantService from 'src/modules/tenant/tenantService';
import globalActions from 'src/modules/global/globalActions';
import { formActionNavigator } from 'src/utils/functions';
// import WitnessService from 'src/modules/witness/witnessService';

const prefix = 'CONTACTLIST_FORM';

const doTableEmailUpdate = async (props) => {
  const id = props.planInvitationUserId;
  // var values
  // switch(props.planInvitationRole){
  //   case 'Beneficiary':
  //     values = {
  //       benEmail: props.planInvitationEmail
  //     }
  //     await BeneficiaryService.update(id, values);
  //     break
  //   case 'Executor':
  //     values = {
  //       executorEmail: props.planInvitationEmail
  //     }
  //     await ExecutorService.update(id, values);
  //     break
  //   case 'Trustee':
  //     values = {
  //       executorEmail: props.planInvitationEmail
  //     }
  //     await ExecutorService.update(id, values);
  //     break
  //   case 'Legal Guardian':
  //     values = {
  //       legalGuardianEmail: props.planInvitationEmail
  //     }
  //     await LegalGuardianService.update(id, values);
  //     break
  //   case 'Other Contact':
  //     values = {
  //       contactListEmail: props.planInvitationEmail
  //     }
  //     await ContactListService.update(id, values);
  //     break
  //   case 'Witness':
  //     values = {
  //       witnessEmail: props.planInvitationEmail
  //     }
  //     await WitnessService.update(id, values);
  //     break
  // }
  var values = {
    contactListEmail: props.planInvitationEmail,
  };
  await ContactListService.update(id, values);
};

const contactListFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: contactListFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        // record = await ContactListService.find(id);
        record = await TenantService.findContactById(id);
      }

      dispatch({
        type: contactListFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactListFormActions.INIT_ERROR,
      });

      getHistory().push('/contact-list');
    }
  },

  doCreate:
    (values, page = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: contactListFormActions.CREATE_STARTED,
        });

        var newValues = values;
        newValues.contactListRole = 'Beneficiary';

        var request = {
          record_type: 'Contact_List',
          record_data: newValues,
        };

        await TenantService.Update_Tenant_Data(request);
        // await ContactListService.create(values);
        dispatch(globalActions.doEditForm());
        dispatch({
          type: contactListFormActions.CREATE_SUCCESS,
        });

        Message.success(
          i18n('entities.contactList.create.success'),
        );

        getHistory().push(
          !page ? `/contact-list` : `/` + page,
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: contactListFormActions.CREATE_ERROR,
        });
      }
    },

  doUpdate:
    (id, values, fromPage = null) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: contactListFormActions.UPDATE_STARTED,
        });

        await ContactListService.update(id, values);
        dispatch(globalActions.doEditForm());
        dispatch({
          type: contactListFormActions.UPDATE_SUCCESS,
        });

        Message.success(
          i18n('entities.contactList.update.success'),
        );

        // getHistory().push(`/contact-list/${id}`);
        switch (fromPage) {
          case 'beneficiary':
            formActionNavigator('beneficiary', 'home');
            break;
          default:
            getHistory().push(`/contact-list`);
            break;
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: contactListFormActions.UPDATE_ERROR,
        });
      }
    },

  doEmailUpdate: (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: contactListFormActions.UPDATE_STARTED,
      });

      await doTableEmailUpdate(values);

      dispatch({
        type: contactListFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.contactList.update.success'),
      );

      getHistory().push(`/contact-list`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactListFormActions.UPDATE_ERROR,
      });
    }
  },

  do_Tenant_Update_Specific_Data:
    (values) => async (dispatch, getState) => {
      try {
        dispatch({
          type: contactListFormActions.UPDATE_STARTED,
        });

        await TenantService.Update_Tenant_Specific_Data(
          values,
        );

        dispatch({
          type: contactListFormActions.UPDATE_SUCCESS,
        });

        Message.success(
          i18n('entities.contactList.update.success'),
        );

        getHistory().push(`/contact-list`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: contactListFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default contactListFormActions;
