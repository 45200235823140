import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.promotion.fields.id'),
  },
  {
    name: 'promTitle',
    label: i18n('entities.promotion.fields.promTitle'),
  },
  {
    name: 'promDescription',
    label: i18n('entities.promotion.fields.promDescription'),
  },
  {
    name: 'promType',
    label: i18n('entities.promotion.fields.promType'),
  },
  {
    name: 'promValue',
    label: i18n('entities.promotion.fields.promValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'promTargetPlan',
    label: i18n('entities.promotion.fields.promType'),
  },
  {
    name: 'promStartDate',
    label: i18n('entities.promotion.fields.promStartDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'promEndDate',
    label: i18n('entities.promotion.fields.promEndDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'promAvailability',
    label: i18n('entities.promotion.fields.promAvailability'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'promCode',
    label: i18n('entities.promotion.fields.promCode'),
  },
  {
    name: 'promPicture',
    label: i18n('entities.promotion.fields.promPicture'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'promRemarks',
    label: i18n('entities.promotion.fields.promRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.promotion.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.promotion.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
