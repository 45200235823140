import styled from 'styled-components';

const SiderWrapper = styled.div`
  .ant-layout-sider {
    min-height: 100vh;
    height: 100%;

    border-right-color: rgb(232, 232, 232);
    border-right-width: 1px;
    border-right-style: solid;
  }

  .ant-menu-inline {
    border: 0;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    text-align: center;
  }

  .logo a {
    color: white;
    text-decoration: none;
  }

  .ant-menu-item-only-child .anticon {
    margin-right: 10px;
  }

  .navi-color {
    color: #161A4C;
  }

  .ant-menu-submenu-title {
    font-weight: normal;
  }

  .ant-menu-submenu {
    font-weight: normal;
  }

  .ant-menu-sub {
    background-color: #CCCBCC37;

  }
  .ant-menu-item-selected {
    font-weight: bold;
    background-color: inherit !important;
  }

  .ant-menu-item-selected:after {
    border-right-color: #C1C1C1;
  }
`;

export default SiderWrapper;
