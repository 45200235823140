import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import beneficiary from 'src/modules/beneficiary/beneficiaryReducers';
import executor from 'src/modules/executor/executorReducers';
import account from 'src/modules/account/accountReducers';
import property from 'src/modules/property/propertyReducers';
import otherAsset from 'src/modules/otherAsset/otherAssetReducers';
// import cash from 'src/modules/cash/cashReducers';
import debt from 'src/modules/debt/debtReducers';
import digital from 'src/modules/digital/digitalReducers';
import guardians from 'src/modules/guardians/guardiansReducers';
import residuary from 'src/modules/residuary/residuaryReducers';
import lastInstructions from 'src/modules/lastInstructions/lastInstructionsReducers';
import promotion from 'src/modules/promotion/promotionReducers';
import legalGuardian from 'src/modules/legalGuardian/legalGuardianReducers';
import location from 'src/modules/location/locationReducers';
import assetCategory from 'src/modules/assetCategory/assetCategoryReducers';
import contactList from 'src/modules/contactList/contactListReducers';
import videoChat from 'src/modules/videoChat/videoChatReducers';
import videoRoom from 'src/modules/videoRoom/videoRoomReducers';
import accessList from 'src/modules/accessList/accessListReducers';
import { combineReducers } from 'redux';
import plan from 'src/modules/plan/planReducers';
import pledge from 'src/modules/pledge/pledgeReducers';
import willSettings from 'src/modules/willSettings/willSettingsReducers';
import userSettings from 'src/modules/userSettings/userSettingsReducers';
import global from 'src/modules/global/globalReducers';
import witness from 'src/modules/witness/witnessReducers';
import advertisement from 'src/modules/advertisement/advertisementReducers';
import notification from 'src/modules/notification/notificationReducers';
import invitation from 'src/modules/invitation/invitationReducers';
import liquidAsset from 'src/modules/liquidAsset/liquidAssetReducers';
import healthInfo from 'src/modules/healthInfo/healthInfoReducers';
import emergencyCardOrder from 'src/modules/emergencyCardOrder/emergencyCardOrderReducers';
import assistant from 'src/modules/assistant/assistantReducers';
import documentation from 'src/modules/documentation/documentationReducers';
import insurance from 'src/modules/insurance/insuranceReducers';
import solicitor from 'src/modules/solicitor/solicitorReducers';
import videoAttestation from 'src/modules/videoAttestation/videoAttestationReducers';
import resources from 'src/modules/resources/resourcesReducers';
import vehicle from 'src/modules/vehicle/vehicleReducers';
import pet from 'src/modules/pet/petReducers';
import trustee from 'src/modules/trustee/trusteeReducers';
import planInvitation from 'src/modules/planInvitation/planInvitationReducers';
import financialPlanning from 'src/modules/financialPlanning/financialPlanningReducers';
import heir from 'src/modules/heir/heirReducers'
import specificGift from 'src/modules/specificGift/specificGiftReducers'
import personalMessage from 'src/modules/personalMessage/personalMessageReducers'
import appReleaseNote from 'src/modules/appReleaseNote/appReleaseNoteReducers'
import dependent from 'src/modules/dependent/dependentReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    beneficiary,
    executor,
    account,
    property,
    otherAsset,
    // cash,
    debt,
    digital,
    guardians,
    residuary,
    lastInstructions,
    promotion,
    legalGuardian,
    location,
    assetCategory,
    contactList,
    videoChat,
    videoRoom,
    pledge,
    willSettings,
    accessList,
    userSettings,
    witness,
    advertisement,
    notification,
    invitation,
    liquidAsset,
    healthInfo,
    emergencyCardOrder,
    assistant,
    documentation,
    insurance,
    solicitor,
    videoAttestation,
    resources,
    vehicle,
    pet,
    trustee,
    planInvitation,
    financialPlanning,
    heir,
    specificGift,
    personalMessage,
    appReleaseNote,
    global,
    dependent,
  });
