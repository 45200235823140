import list from 'src/modules/videoRoom/list/videoRoomListReducers';
import form from 'src/modules/videoRoom/form/videoRoomFormReducers';
import view from 'src/modules/videoRoom/view/videoRoomViewReducers';
import destroy from 'src/modules/videoRoom/destroy/videoRoomDestroyReducers';
// import importerReducer from 'src/modules/videoRoom/importer/videoRoomImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  // importer: importerReducer,
});
