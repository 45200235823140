import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.liquidAsset.fields.id'),
  },
  {
    name: 'liqCategory',
    label: i18n('entities.liquidAsset.fields.liqCategory'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'liqDescription',
    label: i18n('entities.liquidAsset.fields.liqDescription'),
  },
  {
    name: 'liqLocation',
    label: i18n('entities.liquidAsset.fields.liqLocation'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'liqRemarks',
    label: i18n('entities.liquidAsset.fields.liqRemarks'),
  },
  {
    name: 'liqValue',
    label: i18n('entities.liquidAsset.fields.liqValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'liqCurrency',
    label: i18n('entities.liquidAsset.fields.liqCurrency'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'liqBen',
    label: i18n('entities.liquidAsset.fields.liqBen'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'liqFile',
    label: i18n('entities.liquidAsset.fields.liqFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'liqPhoto',
    label: i18n('entities.liquidAsset.fields.liqPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.liquidAsset.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.liquidAsset.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
