import ContactListService from 'src/modules/contactList/contactListService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';

const prefix = 'CONTACTLIST_VIEW';

const contactListViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: contactListViewActions.FIND_STARTED,
      });

      // const record = await ContactListService.find(id);
      const record = await TenantService.findContactById(id);

      dispatch({
        type: contactListViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactListViewActions.FIND_ERROR,
      });

      getHistory().push('/contact-list');
    }
  },
};

export default contactListViewActions;
