import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';

import {
  PartitionOutlined,
  ContactsOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import Pages from 'src/security/page';

const permissions = Permissions.values;

export default [
  // {
  //   path: '/asset-category',
  //   permissionRequired: permissions.assetCategoryRead,
  //   icon: <PartitionOutlined />,
  //   label: i18n('entities.assetCategory.menu'),
  //   type: Pages.values.AssetCategory,
  // },
  {
    path: '/contact-list',
    permissionRequired: permissions.contactListRead,
    icon: <ContactsOutlined />,
    label: i18n('entities.contactList.menu'),
    type: Pages.values.ContactList,
  },
  {
    path: '/audit-logs',
    permissionRequired: permissions.auditLogRead,
    icon: <AuditOutlined />,
    label: i18n('auditLog.menu')
  },
  // {
  //   path: '/emergency-card/order',
  //   permissionRequired: permissions.emergencyCardRead,
  //   icon: <ShoppingCartOutlined />,
  //   label: i18n('entities.emergencyCardOrder.menu'),
  //   type: Pages.values.EmergencyCardOrder,
  // },
  // {
  //   path: '/documentation',
  //   permissionRequired: permissions.documentationRead,
  //   icon: <FileProtectOutlined />,
  //   label: i18n('entities.documentation.menu'),
  //   type: Pages.values.Documentation,
  // },
].filter(Boolean);
