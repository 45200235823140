import { Form, Input, Checkbox } from 'antd';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import FormErrors from 'src/view/shared/form/formErrors';

import AuthSelectors from 'src/modules/auth/authSelectors';
import { useSelector } from 'react-redux';

import TestatorAddress from 'src/view/auth/TestatorAddress';
import { isMobile } from 'react-device-detect';
import { trimAddress } from 'src/utils/functions';

const TextAreaFormItem = (props) => {
  const {
    label,
    name,
    hint,
    layout,
    placeholder,
    autoFocus,
    autoComplete,
    prefix,
    required,
    rows,
    values,
    externalErrorMessage,
    isAddress,
    isAddressLabel,
    hidden,
    disabled,
    labelAlign,
    autoSize,
    noMaxWitdh,
  } = props;

  const {
    register,
    errors,
    formState: { touched, isSubmitted },
    setValue,
    watch,
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touched,
    isSubmitted,
    externalErrorMessage,
  );

  const currentUser = useSelector(
    AuthSelectors.selectCurrentUser,
  );

  const onSameAddressChecked = (e) => {
    console.log('currentUser.address', currentUser.address);
    var address = currentUser.address
      ? restructureAddress(currentUser.address)
      : restructureAddress(TestatorAddress.getAddress());

    e.target.checked &&
      setValue(
        name,
        // currentUser.address
        //   ? currentUser.address
        //   : TestatorAddress.getAddress(),
        address,
      );
  };

  const restructureAddress = (fullAddress) => {
    if (typeof fullAddress === 'object') {
      var address =
        trimAddress(fullAddress.address1) +
        trimAddress(fullAddress.address2) +
        trimAddress(fullAddress.address3) +
        trimAddress(fullAddress.address4) +
        trimAddress(fullAddress.postcode) +
        fullAddress.country;

      // Object.values(fullAddress).map((item) => {
      //   address = address + (item ?? '') + ' ';
      // });
      return address;
    } else return fullAddress;
  };

  const orignalValue = watch(name);

  useEffect(() => {
    register({ name });
  }, [register, name]);

  return (
    <Form.Item
      {...layout}
      label={label}
      required={required}
      validateStatus={errorMessage ? 'error' : 'success'}
      help={errorMessage || hint}
      labelAlign={labelAlign}
      hidden={hidden}
    >
      <div
        style={{
          maxWidth: noMaxWitdh
            ? '100%'
            : !isMobile
            ? '450px'
            : '100%',
        }}
      >
        <Input.TextArea
          id={name}
          onChange={(event) => {
            setValue(name, event.target.value, {
              shouldValidate: true,
            });
            props.onChange &&
              props.onChange(event.target.value);
          }}
          onBlur={(event) => {
            props.onBlur && props.onBlur(event);
          }}
          autoSize={autoSize}
          value={values ?? orignalValue}
          placeholder={placeholder || undefined}
          autoFocus={autoFocus || false}
          autoComplete={autoComplete || undefined}
          prefix={prefix || undefined}
          rows={rows}
          disabled={disabled}
        />

        {isAddress && (
          <Checkbox
            name={'sameAddressCheckBox'}
            onChange={onSameAddressChecked}
          >
            {isAddressLabel}
          </Checkbox>
        )}
      </div>
    </Form.Item>
  );
};

TextAreaFormItem.defaultProps = {
  layout: null,
  required: false,
  rows: 4,
  hidden: false,
  disabled: false,
};

TextAreaFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  prefix: PropTypes.string,
  values: PropTypes.string,
  placeholder: PropTypes.string,
  layout: PropTypes.object,
  errorMessage: PropTypes.string,
  isAddress: PropTypes.bool,
  isAddressLabel: PropTypes.string,
  onChange: PropTypes.func,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  labelAlign: PropTypes.string,
  noMaxWitdh: PropTypes.bool,
  autoSize: PropTypes.bool,
};

export default TextAreaFormItem;
