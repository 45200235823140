const pledgeEnumerators = {
  orgCategory: [],
  orgList: [{ name: '', sublist: [] }],
  //orgList: [{name: "", sublist:[{sublist_name: "", link: ""}]}];
};

//console.log("reset humanitarianOrg");

//BC17012022-Remove unused variable const tmp_elements = [{sublist:[]}];
//const tmp_pleadgeelements = [{name: "", org_list: tmp_elements}];
const tmp_pleadgeelements = [{ name: '' }];

//console.log(tmp_pleadgeelements.length);

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

const request = async () => {
  try {
    await fetch(
      'https://koha.digital/wp-json/wp/v2/avada_portfolio',
    )
      .then((res) => res.json())
      .then(
        (data) => {
          //console.log(data)
          Object?.keys(data)?.map(async (ws_data) => {
            //   console.log(data[ws_data]);
            if (
              data[ws_data]?.acf?.category[0] !== undefined
            ) {
              let ws_len = tmp_pleadgeelements.length;
              let ws_found = 0;
              for (var i = 0; i < ws_len; i++) {
                if (
                  tmp_pleadgeelements[i].name ===
                  data[ws_data]?.acf?.category[0]
                ) {
                  ws_found = i;

                  break;
                }
              }
              if (ws_found === 0) {
                var source_link =
                  data[ws_data]?._links[
                    'wp:featuredmedia'
                  ][0]?.href;

                var src = await getImage(source_link).then(
                  (url) => {
                    var article = {
                      title: data[ws_data]?.title?.rendered,
                      content:
                        data[ws_data]?.content?.rendered,
                      imgUrl: url,
                    };

                    return article;
                  },
                );

                tmp_pleadgeelements.push({
                  name: data[ws_data]?.acf?.category[0],
                });
                pledgeEnumerators.orgCategory.push(
                  data[ws_data]?.acf?.category[0],
                );
                pledgeEnumerators.orgList.push({
                  name: data[ws_data]?.acf?.category[0],
                  sublist: [
                    {
                      sublist_name: entities.decode(
                        data[ws_data]?.title?.rendered,
                      ),
                      link: data[ws_data]?.acf?.website,
                      desc: data[ws_data]?.content
                        ?.rendered,
                      image: src?.imgUrl,
                      registration_number:
                        data[ws_data]?.acf
                          ?.registration_number,
                    },
                  ],
                });
              } else {
                pledgeEnumerators.orgList[i].sublist.push({
                  sublist_name: entities.decode(
                    data[ws_data]?.title?.rendered,
                  ),
                  link: data[ws_data]?.acf?.website,
                  desc: data[ws_data]?.content?.rendered,
                });
              }
            }

            pledgeEnumerators.orgList?.sort();
          });
        },
        (error) => {
          console.log('error');
        },
      );
  } catch (exception) {
    console.log('PledgeEnum Error: ', exception);
  }
};

request();

const getImage = async (url) => {
  var imgUrl = '';
  await fetch(url)
    // await fetch('https://cors-anywhere.herokuapp.com/' + url)
    .then((res) => res.json())
    .then((data) => {
      imgUrl = data?.guid?.rendered;
    });

  return imgUrl;
};

//console.log(pledgeEnumerators);

export default pledgeEnumerators;
