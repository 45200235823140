import BeneficiaryService from 'src/modules/beneficiary/beneficiaryService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import { formActionNavigator } from 'src/utils/functions';

const prefix = 'BENEFICIARY_VIEW';

const beneficiaryViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: beneficiaryViewActions.FIND_STARTED,
      });

      const record = await BeneficiaryService.find(id);

      dispatch({
        type: beneficiaryViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: beneficiaryViewActions.FIND_ERROR,
      });

      formActionNavigator('beneficiary', 'home');
    }
  },
};

export default beneficiaryViewActions;
