/**
 * List of Plans
 */
class Plans {
  static get values() {
    return {
      free: 'free',
      starter: 'starter',
      comprehensive: 'comprehensive',
      Corporate_Trustee: 'Corporate_Trustee',
      palladiumExecutor: 'palladiumExecutor',
      palladiumCustodian: 'palladiumCustodian',
      palladiumTestamentary: 'palladiumTestamentary',
      Advisory: 'Advisory',
      personalMessages: 'personalMessages',
      legal_document_bundle: 'legal_document_bundle',
      legal_services: 'legal_services',
      hibah: 'hibah',
      harta_Sepencarian: 'harta_Sepencarian',
    };
  }

  static get lists() {
    var planPackages = Plans.values;
    var data = [] as any;

    Object.keys(planPackages).forEach(function (key) {
      data.push(planPackages[key]);
    });

    return data;
  }
}

export default Plans;
