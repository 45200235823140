import PlanInvitationService from 'src/modules/planInvitation/planInvitationService';
import planInvitationActions from 'src/modules/planInvitation/list/planInvitationListActions';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'PLAN_INVITATION_FORM';

const planInvitationFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  VERIFY_STARTED: `VERIFY_STARTED`,
  VERIFY_SUCCESS: `VERIFY_SUCCESS`,
  VERIFY_ERROR: `VERIFY_ERROR`,
  
  SUBMIT_STARTED: `SUBMIT_STARTED`,
  SUBMIT_SUCCESS: `SUBMIT_SUCCESS`,
  SUBMIT_ERROR: `SUBMIT_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: planInvitationFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await PlanInvitationService.find(id);
      }

      dispatch({
        type: planInvitationFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planInvitationFormActions.INIT_ERROR,
      });

      getHistory().push('/contact-list');
    }
  },

  doCreate: (values, callback?) => async (dispatch) => {
    try {
      dispatch({
        type: planInvitationFormActions.CREATE_STARTED,
      });

      const record = await PlanInvitationService.create(values);

      dispatch({
        type: planInvitationFormActions.CREATE_SUCCESS,
        payload: record,
      });

      Message.success(
        i18n('entities.planInvitation.create.success'),
      );

      if(callback==='refresh'){
        return dispatch(planInvitationActions.doFetch(
          {
              planInvitationTenantId: values.planInvitationTenantId,
          }
        ))
      }

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planInvitationFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values, callback?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: planInvitationFormActions.UPDATE_STARTED,
      });

      const record = await PlanInvitationService.update(id, values);

      dispatch({
        type: planInvitationFormActions.UPDATE_SUCCESS,
        payload: record,
      });

      Message.success(
        i18n('entities.planInvitation.update.success'),
      );

      if(callback==='refresh'){
        return dispatch(planInvitationActions.doFetch(
          {
              planInvitationTenantId: values.planInvitationTenantId,
          }
        ))
      }
      else{
        return record.id
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planInvitationFormActions.UPDATE_ERROR,
      });
    }
  },

  doVerification: (id, token) => async (dispatch) => {
    try {
      dispatch({
        type: planInvitationFormActions.VERIFY_STARTED,
      });

      const record = await PlanInvitationService.verifyInvitation(id, token);
      dispatch({
        type: planInvitationFormActions.VERIFY_SUCCESS,
        payload: record,
      });
      
      return record.status
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planInvitationFormActions.VERIFY_ERROR,
        payload: {status:'error'}
      });
    }
  },

  doVerificationSubmit: (data) => async (dispatch) => {
    try {
      dispatch({
        type: planInvitationFormActions.SUBMIT_STARTED,
      });

      const record = await PlanInvitationService.verifyInvitationSubmit(data);

      dispatch({
        type: planInvitationFormActions.SUBMIT_SUCCESS,
        payload: record,
      });
      
      getHistory().push('../verify-token/view');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planInvitationFormActions.SUBMIT_ERROR,
        payload: {status:'error'}
      });
    }
  },
};

export default planInvitationFormActions;
