import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/documentation/importer/documentationImporterSelectors';
import DocumentationService from 'src/modules/documentation/documentationService';
import fields from 'src/modules/documentation/importer/documentationImporterFields';
import { i18n } from 'src/i18n';

const documentationImporterActions = importerActions(
  'DOCUMENTATION_IMPORTER',
  selectors,
  DocumentationService.import,
  fields,
  i18n('entities.documentation.importer.fileName'),
);

export default documentationImporterActions;