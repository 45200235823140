import { i18n } from 'src/i18n';

const assistantEnumerators = {
  assistantType: [
    "executor",
    "trustee",
    "legalGuardiant",
    "beneficiary",
    "contactList",
    "agent",
    "digital",
    "pledge"
  ],
  invitationOption: [
    "sent",
    "later"
  ],
  invitationStatus: [
    "invited",
    "accept",
    "decline"
  ],
  assistantView: [
    "Never",
    "Now",
    "AfterDeath"
  ],
  waitingPeriod: [
    'day1',
    'day2',
    'day3',
    'day7',
    'day14',
    'day21',
    'day30'
  ],
  sections: [
    "people",
    "own",
    "willDetails",
  ],
  assistantMode: [
    "Read Only",
    "Read & Edit",
  ],
  modules: [
    {
      label: i18n('assistant.fields.assistantBeneficiaries',),
      name: "assistantBeneficiaries",
      part: "people",
    },
    {
      label: i18n('assistant.fields.assistantLegalGuardians',),
      name: "assistantLegalGuardians",
      part: "people",
    },
    {
      label: i18n('assistant.fields.assistantPets',),
      name: "assistantPets",
      part: "people",
    },
    {
      label: i18n('assistant.fields.assistantContactLists',),
      name: "assistantContactLists",
      part: "people",
    },
    {
      label: i18n('assistant.fields.assistantSpecificGifts',),
      name: "assistantSpecificGifts",
      part: "people",
    },
    {
      label: i18n('assistant.fields.assistantAccounts',),
      name: "assistantAccounts",
      part: "own",
    },
    {
      label: i18n('assistant.fields.assistantDebts',),
      name: "assistantDebts",
      part: "own",
    },
    {
      label: i18n('assistant.fields.assistantProperties',),
      name: "assistantProperties",
      part: "own",
    },
    {
      label: i18n('assistant.fields.assistantOtherGifts',),
      name: "assistantOtherGifts",
      part: "own",
    },
    {
      label: i18n('assistant.fields.assistantPledge',),
      name: "assistantPledge",
      part: "own",
    },
    {
      label: i18n('assistant.fields.assistantDigital',),
      name: "assistantDigital",
      part: "own",
    },
    {
      label: i18n('assistant.fields.assistantInsurances',),
      name: "assistantInsurances",
      part: "own",
    },
    // {
    //   label: i18n('assistant.fields.assistantEmergencyCards',),
    //   name: "assistantEmergencyCards",
    //   part: "own",
    // },
    {
      label: i18n('assistant.fields.assistantHealthInfos',),
      name: "assistantHealthInfos",
      part: "own",
    },
    // {
    //   label: i18n('assistant.fields.assistantOtherDocuments',),
    //   name: "assistantOtherDocuments",
    //   part: "own",
    // },
    {
      label: i18n('assistant.fields.assistantVehicles',),
      name: "assistantVehicles",
      part: "own",
    },
    {
      label: i18n('assistant.fields.assistantExecutors',),
      name: "assistantExecutors",
      part: "willDetails",
    },
    {
      label: i18n('assistant.fields.assistantResiduaries',),
      name: "assistantResiduaries",
      part: "willDetails",
    },
    {
      label: i18n('assistant.fields.assistantWitnesses',),
      name: "assistantWitnesses",
      part: "willDetails",
    },
    {
      label: i18n('assistant.fields.assistantLastInstructions',),
      name: "assistantLastInstructions",
      part: "willDetails",
    },



  ]
};

export default assistantEnumerators;
