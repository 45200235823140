import AssistantService from 'src/modules/assistant/assistantService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'ASSISTANT_FORM';

const assistantFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: assistantFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      record = await AssistantService.find(id);

      dispatch({
        type: assistantFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: assistantFormActions.INIT_ERROR,
      });

      getHistory().push('/assistant');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: assistantFormActions.CREATE_STARTED,
      });

      await AssistantService.create(values);

      dispatch({
        type: assistantFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('assistant.create.success'));

      getHistory().push('/my-vault'); // assistant
    } catch (error) {
      console.log('assistantFA/dCreate Error: ', error);
      Errors.handle(error);

      dispatch({
        type: assistantFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: assistantFormActions.UPDATE_STARTED,
      });

      await AssistantService.update(id, values);

      dispatch({
        type: assistantFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('assistant.update.success'));

      getHistory().push('/my-vault'); // assistant
      //getHistory().push(`/my-vault/${id}/edit`);
    } catch (error) {
      console.log('assistantFA/dUpdate Error: ', error);
      Errors.handle(error);

      dispatch({
        type: assistantFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default assistantFormActions;
