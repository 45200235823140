import { i18n } from 'src/i18n';
import { getConfig } from 'src/config';
import Message from 'src/view/shared/message';
import Errors from 'src/modules/shared/error/errors';
import PlanService from './planService';
import service from 'src/modules/auth/authService';
import { getHistory } from 'src/modules/store';

const prefix = 'PLAN';

const planActions = {
  CHECKOUT_STARTED: `${prefix}_CHECKOUT_STARTED`,
  CHECKOUT_SUCCESS: `${prefix}_CHECKOUT_SUCCESS`,
  CHECKOUT_ERROR: `${prefix}_CHECKOUT_ERROR`,

  PORTAL_STARTED: `${prefix}_PORTAL_STARTED`,
  PORTAL_SUCCESS: `${prefix}_PORTAL_SUCCESS`,
  PORTAL_ERROR: `${prefix}_PORTAL_ERROR`,

  UPGRADE_STARTED: `${prefix}_UPGRADE_STARTED`,
  UPGRADE_SUCCESS: `${prefix}_UPGRADE_SUCCESS`,
  UPGRADE_ERROR: `${prefix}_UPGRADE_ERROR`,

  exclusive_gift:
    (email, plan) => async (dispatch, getState) => {
      try {
        dispatch({
          type: planActions.CHECKOUT_STARTED,
        });

        // const sessionId = await PlanService.exclusive_gift(
        //   email, null, plan
        // );

        await PlanService.exclusive_gift(email, null, plan);

        dispatch({
          type: planActions.CHECKOUT_SUCCESS,
        });
      } catch (error) {
        Errors.showMessage(error);

        dispatch({
          type: planActions.CHECKOUT_ERROR,
        });
      }
    },

  doCheckout:
    (plan, type, firstComplete = true) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: planActions.CHECKOUT_STARTED,
        });

        const sessionId =
          await PlanService.fetchCheckoutSessionId(
            plan,
            type,
            firstComplete,
          );

        const stripe = (window as any).Stripe(
          getConfig().REACT_APP_PUBLISHABLE_KEY,
        );
        const result = await stripe.redirectToCheckout({
          sessionId,
        });

        if (result.error.message) {
          throw new Error(result.error.message);
        }

        dispatch({
          type: planActions.CHECKOUT_SUCCESS,
        });
      } catch (error) {
        Errors.showMessage(error);

        dispatch({
          type: planActions.CHECKOUT_ERROR,
        });
      }
    },

  doCheckout_Plan:
    (
      plan,
      extra_plan = [],
      page_from,
      source,
      renew = false,
    ) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: planActions.CHECKOUT_STARTED,
        });

        const sessionId =
          await PlanService.fetchCheckoutPlanSessionId(
            plan,
            extra_plan,
            page_from,
            source,
            renew,
          );
        // console.log(
        //   'source: ',
        //   source,
        //   source === 'Wasiat'
        //     ? getConfig().REACT_APP_ISM_PUBLISHABLE_KEY
        //     : getConfig().REACT_APP_PUBLISHABLE_KEY,
        // );
        const stripe = (window as any).Stripe(
          source === 'Wasiat'
            ? getConfig().REACT_APP_ISM_PUBLISHABLE_KEY
            : getConfig().REACT_APP_PUBLISHABLE_KEY,
        );

        const result = await stripe.redirectToCheckout({
          sessionId,
        });

        if (result.error.message) {
          throw new Error(result.error.message);
        }

        dispatch({
          type: planActions.CHECKOUT_SUCCESS,
        });
      } catch (error) {
        Errors.showMessage(error);

        dispatch({
          type: planActions.CHECKOUT_ERROR,
        });
      }
    },

  doPortal: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: planActions.PORTAL_STARTED,
      });

      const url = await PlanService.fetchPortalUrl();
      window.location.href = url;

      dispatch({
        type: planActions.PORTAL_SUCCESS,
      });
    } catch (error) {
      Errors.showMessage(error);

      dispatch({
        type: planActions.PORTAL_ERROR,
      });
    }
  },

  doUpgradePlan:
    (session_id, record) => async (dispatch) => {
      try {
        dispatch({
          type: planActions.UPGRADE_STARTED,
        });

        await PlanService.upgradePlan(session_id, record);

        Message.success(i18n('plan.upgradesuccess'));

        getHistory().push('/');

        dispatch({
          type: planActions.UPGRADE_SUCCESS,
        });
      } catch (error) {
        await service.signout();
        console.log('Invitation error', error);

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: planActions.UPGRADE_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },
};

export default planActions;
