const lastInstructionsEnumerators = {
  instructionType: [
    'Funeral',
    'Messages',
    // 'VideoMessages',
    // 'AudioMessages',
    // 'Others',
  ],
  instructionReligion: [
    'Buddhist',
    'Christian',
    'Catholic',
    'Hindu',
    'Protestant',
    'Toaist',
    'Others',
  ],
  instructions: [
    'Buried',
    'CrematedAndTheAshesScattered',
    'CrematedAndTheAshesStored',
    'Others',
  ],
  memories: [
    "Our memorable road trip along the coast, with the wind in our hair and the sound of waves in the background.",
    "The time we stayed up all night talking about our dreams and aspirations, under a sky full of stars.",
    "That hilarious camping trip where we struggled to set up the tent, but we couldn't stop laughing about it.",
    "Our spontaneous hiking adventure, where we discovered a hidden waterfall and felt like explorers.",
    "Dancing in the rain during a summer storm and feeling like carefree kids again.",
    "The day we cooked a gourmet meal together, complete with a fancy dessert we created from scratch.",
    "Attending that unforgettable concert, where the music was so loud it made our hearts race.",
    "Watching a breathtaking sunset together on a serene beach, sharing our deepest thoughts.",
    "Our late-night movie marathons, with popcorn and blankets, turning the living room into a cozy cinema.",
    "That time you surprised me with a homemade birthday cake, and it tasted better than any bakery's.",
    "Our stargazing nights, when we tried to identify constellations and got lost in the vastness of the universe.",
    "The laughter-filled picnics in the park, complete with frisbee games and shared stories.",
    "Exploring a new city and getting lost in its charming streets, making unexpected discoveries.",
    "Our joint volunteering efforts, where we made a positive impact on our community.",
    "The day you taught me a new skill or hobby, and we bonded over the learning process.",
    "Attending a thrilling sports event, cheering for our favorite team, and celebrating a victory.",
    "Our weekend getaways to the countryside, where we enjoyed the tranquility and fresh air.",
    "That time we faced our fears together, whether it was bungee jumping or trying a new cuisine.",
    "Building a treehouse in the backyard, where we spent countless hours sharing secrets and dreams.",
    "The day we planted a garden and watched it flourish over time, just like our friendship."
  ],
  emotional_traits: [
    "Empathetic",
    "Compassionate",
    "Optimistic",
    "Reliable",
    "Caring",
    "Supportive",
    "Thoughtful",
    "Grateful",
    "Playful"
  ],
  work_ethic_traits: [
    "Hardworking",
    "Responsible",
    "Self-disciplined",
    "Punctual",
    "Energetic",
    "Adventurous",
    "Organized",
    "Tenacious",
    "Respectful"
  ],
  intellectual_traits: [
    "Intelligent",
    "Analytical",
    "Innovative",
    "Curious",
    "Rational",
    "Decisive",
    "Inquisitive"
  ],
  adaptive_traits: [
    "Adaptable",
    "Resilient",
    "Tolerant",
    "Calm"
  ],
  social_traits: [
    "Generous",
    "Friendly",
    "Sociable",
    "Trustworthy",
    "Enthusiastic",
    "Helpful",
    "Charitable",
    "Diplomatic"
  ],
  creative_traits: [
    "Creative",
    "Artistic",
    "Visionary",
    "Playful"
  ],
  problem_solving_traits: [
    "Problem-Solving",
    "Pragmatic",
    "Resourceful"
  ],
  life_advice: [
    "Cherish your health; it's your most valuable asset.",
    "Never stop learning; knowledge is a lifelong journey.",
    "Cultivate resilience, for adversity is a part of life.",
    "Practice gratitude daily; it can transform your perspective.",
    "Prioritize relationships; they bring joy and support.",
    "Follow your passions; they lead to a fulfilling life.",
    "Save and invest wisely for financial security.",
    "Don't be afraid to take calculated risks.",
    "Embrace change; it's the only constant in life.",
    "Be kind to others; it costs nothing but means everything.",
    "Seek work-life balance; it's essential for well-being.",
    "Live in the present moment; the past is gone, and the future is uncertain.",
    "Find a mentor and be a mentor to others.",
    "Don't be afraid to ask for help when needed.",
    "Explore the world and experience different cultures.",
    "Take care of the environment; it's our responsibility.",
    "Be honest and maintain your integrity.",
    "Trust your intuition; it's a powerful guide.",
    "Set clear goals and work diligently towards them.",
    "Accept failures as opportunities for growth.",
    "Practice self-compassion; be kind to yourself.",
    "Make time for self-care and relaxation.",
    "Don't compare your journey to others; it's unique.",
    "Give back to your community or a cause you believe in.",
    "Always strive for personal improvement.",
    "Surround yourself with positive and supportive people.",
    "Listen actively; it fosters understanding and empathy.",
    "Don't hold grudges; forgiveness can bring peace.",
    "Live authentically and stay true to your values.",
    "Keep a sense of humor; it lightens life's challenges."
  ],
  challenges: [
    "I still remember the day you overcame your fear of the water and swam across the pool for the first time.",
    "Watching you excel in your studies and earn that well-deserved award made my heart swell with pride.",
    "Your dedication to soccer, even when the going got tough, showed me your unwavering commitment.",
    "Witnessing your journey from a novice to an accomplished musician was truly inspiring.",
    "Your perseverance in starting your own business and seeing it succeed is a remarkable achievement.",
    "Overcoming a serious illness and emerging stronger than ever is a testament to your resilience.",
    "When you climbed that mountain, it wasn't just an ascent; it was a victory over self-doubt.",
    "Your commitment to volunteer work and making a difference in the community is truly commendable.",
    "Navigating a difficult personal loss and finding strength in the process is a significant triumph.",
    "Completing a marathon after months of training showcases your dedication to personal goals.",
    "Learning a new language and becoming fluent is a testament to your determination.",
    "Your ability to balance work, family, and personal growth is an achievement in itself.",
    "Successfully publishing a book you've been passionate about for years is a remarkable accomplishment.",
    "Overcoming public speaking anxiety and delivering a compelling presentation is a notable triumph.",
    "Graduating with honors and securing your dream job is a result of your hard work and ambition.",
    "Achieving a significant milestone in your creative project is a testament to your artistic talents.",
    "Winning a competition or a sports championship demonstrates your commitment to excellence.",
    "Your journey to financial stability and investing success is a noteworthy accomplishment.",
    "Overcoming a challenging personal obstacle, such as addiction or anxiety, is a major triumph.",
    "Mastering a new skill or hobby that brings you joy is a wonderful personal achievement."
  ],
  dreams: [
    "Becoming a veterinarian to care for animals and make the world a better place.",
    "Envisioning a future as a successful author, using words to inspire and touch hearts.",
    "Having a passion for science that hints at a future of discovering new ways to improve lives.",
    "Aspiring to become a renowned chef, creating culinary delights that delight people's taste buds.",
    "Dreaming of being a teacher, shaping young minds and making a lasting impact on education.",
    "Envisioning a career in medicine, healing and saving lives as a dedicated doctor.",
    "Hoping to lead a tech startup, bringing innovative solutions to everyday challenges.",
    "Dreaming of a life filled with travel, experiencing different cultures and broadening horizons.",
    "Aspiring to be an environmental activist, advocating for a sustainable and green future.",
    "Dreaming of becoming an astronaut, exploring the cosmos and contributing to space exploration.",
    "Hoping to be a professional athlete, competing at the highest level in a chosen sport.",
    "Dreaming of a career in music, sharing melodies and connecting with people through songs.",
    "Aspiring to be a renowned fashion designer, shaping the style of the future.",
    "Hoping to be a successful entrepreneur, creating businesses that drive innovation.",
    "Dreaming of becoming a dedicated teacher, nurturing young minds and fostering a love for learning.",
    "Dreaming of a future in politics, making a positive impact on society through public service.",
    "Envisioning a career in film and cinema, using storytelling to inspire and entertain.",
    "Aspiring to be a groundbreaking scientist, making discoveries that change the world.",
    "Dreaming of a peaceful world, working towards conflict resolution and diplomacy.",
    "Hoping to be a best-selling novelist, crafting stories that captivate readers worldwide."
  ],
  expressions_of_love: [
    "Your smile brightens my darkest days and fills my heart with warmth.",
    "You are my greatest blessing, and each day with you is a gift beyond measure.",
    "Your presence in my life is a constant reminder of the boundless love that exists between us.",
    "The love I have for you knows no bounds and only grows stronger with each passing day.",
    "Your laughter is the sweetest music to my ears, and it brings joy to my soul.",
    "I cherish every moment we spend together, for you make my life complete.",
    "You are the light of my life, and I am grateful for the love you bring into my world.",
    "My love for you is like a never-ending story, filled with endless chapters of affection and care.",
    "You are the most precious gift I've ever received, and I love you with all my heart.",
    "Your kindness and compassion inspire me, and I'm proud to have you as a part of my life.",
    "You are the embodiment of everything beautiful in this world, and I am truly fortunate to know you.",
    "Loving you is the easiest and most natural thing I've ever done, and it brings me immense joy.",
    "Your presence is a source of strength, and I am grateful for the love and support you provide.",
    "The love between us is a bond that can never be broken; it's a treasure I hold dear.",
    "Your hugs are like a warm embrace of love, and they make me feel safe and cherished.",
    "I love you more than words can express, and my actions will always show the depth of my affection.",
    "Your kindness and thoughtfulness constantly remind me of the love we share.",
    "You are the heart and soul of our family, and I am endlessly grateful for your love.",
    "You make every day better just by being in it, and I love you more with each passing moment.",
    "Your love is the anchor in the storms of life, and I am blessed to have you by my side."
  ],
  closing_words: [
    "May your journey be filled with endless love, boundless opportunities, and a world of happiness.",
    "As you step into the future, know that you carry my love and support with you, always.",
    "Here's to a future filled with joy, success, and the fulfillment of all your dreams.",
    "May each day be a new chapter of happiness and a testament to your achievements.",
    "Your path is bright, and I can't wait to see the amazing places it takes you.",
    "Wishing you a lifetime of laughter, love, and unforgettable adventures.",
    "May the winds of fortune always be at your back as you pursue your dreams.",
    "Your potential is limitless, and I'm excited to see where it leads you.",
    "Keep your heart open, and the world will fill it with beauty and wonder.",
    "Every sunrise brings new opportunities; may you seize them with enthusiasm.",
    "May your days be filled with moments that make you smile and your heart sing.",
    "The world is a canvas, and you are the artist. Paint a life that's vibrant and meaningful.",
    "As you move forward, remember that the best is yet to come.",
    "May your days be as bright as your spirit and as limitless as your aspirations.",
    "In every challenge, find an opportunity; in every disappointment, find resilience.",
    "Here's to a life filled with purpose, love, and the pursuit of your dreams.",
    "You have the power to make a difference in the world; may your journey be impactful.",
    "The world is a better place with you in it, and your presence brings joy to everyone around you.",
    "May your days be full of reasons to celebrate and moments to cherish.",
    "Remember that you are loved and supported, no matter where life takes you."
  ],
  tones: [
    "FriendlyAndInformal",
    "CasualAndConversational",
    "WarmAndAffectionate",
    "FormalAndRespectful",
    "SupportiveAndEncouraging",
    "GratefulAndAppreciative",
    "InspirationalAndMotivational",
    "HumbleAndReflective",
    "LightHeartedAndHumorous"
  ]
};

export default lastInstructionsEnumerators;
