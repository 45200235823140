import Pages from 'src/security/page';
import { i18n } from 'src/i18n';

import { 
  LockOutlined,
  TeamOutlined,
 } from '@ant-design/icons';

export default [
  {
    path: '/my-vault',
    icon: <LockOutlined />,
    label: i18n('dashboard.digitalVault.myVault'),
    type: Pages.values.Digital,
    placeholder:false,
  },

  {
    path: '/share-with-me',
    icon: <TeamOutlined />,
    label: i18n('dashboard.digitalVault.sharedWithMeVault'),
    placeholder:false,
  },

].filter(Boolean);
