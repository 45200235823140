import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.assetCategory.fields.id'),
  },
  {
    name: 'astCategoryName',
    label: i18n(
      'entities.assetCategory.fields.astCategoryName',
    ),
  },
  {
    name: 'astCategoryDescription',
    label: i18n(
      'entities.assetCategory.fields.astCategoryDescription',
    ),
  },
  {
    name: 'astCategoryMode',
    label: i18n(
      'entities.assetCategory.fields.astCategoryMode',
    ),
  },
];
