import authAxios from 'src/modules/shared/axios/authAxios';
import { AuthToken } from 'src/modules/auth/authToken';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import AuthInvitationToken from 'src/modules/auth/authInvitationToken';
import { tenantSubdomain } from '../tenant/tenantSubdomain';

export default class AuthService {
  static async sendEmailVerification() {
    const response = await authAxios.post(
      '/auth/send-email-address-verification-email',
      {
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    return response.data;
  }

  static async sendPasswordResetEmail(email) {
    var target_type = 'testator';

    const response = await authAxios.post(
      '/auth/send-password-reset-email',
      {
        email,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
        target_type: target_type,
      },
    );

    return response.data;
  }

  static async registerWithEmailAndPassword(
    email,
    password,
    newsletter,
    referralCode,
    fullName,
    phoneNumber,
    phoneNumberCountryCode,
    signup_mode,
  ) {
    const invitationToken = AuthInvitationToken.get();
    const response = await authAxios.post('/auth/sign-up', {
      email,
      password,
      invitationToken,
      newsletter,
      referralCode,
      fullName,
      phoneNumber,
      phoneNumberCountryCode,
      signup_mode,
      tenantId: tenantSubdomain.isSubdomain
        ? AuthCurrentTenant.get()
        : undefined,
    });

    AuthInvitationToken.clear();

    return response.data;
  }

  static async registerWithSocial(data) {
    const invitationToken = AuthInvitationToken.get();
    const response = await authAxios.post(
      '/auth/sign-up-social',
      {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: data.fullName,
        facebook_account: data.facebook_account,
        google_account: data.google_account,
        picture: data.picture,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    AuthInvitationToken.clear();

    return response.data;
  }

  static async linkAccountWithSocial(data) {
    const response = await authAxios.post(
      '/auth/link-social',
      {
        email: data.email,
        facebook_account: data.facebook_account,
        google_account: data.google_account
      },
    );

    AuthInvitationToken.clear();

    return response.data;
  }

  static async signinWithEmailAndPassword(email, password) {
    const invitationToken = AuthInvitationToken.get();

    const response = await authAxios.post('/auth/sign-in', {
      email,
      password,
      invitationToken,
      tenantId: tenantSubdomain.isSubdomain
        ? AuthCurrentTenant.get()
        : undefined,
    });

    // console.log(response);

    AuthInvitationToken.clear();

    return response.data;
  }

  static async signinWithEmailAndVerifyToken(token) {
    const invitationToken = AuthInvitationToken.get();

    const response = await authAxios.post(
      '/auth/sign-in-token',
      {
        token,
        invitationToken,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    // console.log(response);

    AuthInvitationToken.clear();

    return response.data;
  }

  static async sendEmailAuthToken(email) {
    const response = await authAxios.post(
      '/auth/sendEmailToken',
      {
        email,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    return response.data;
  }

  static async doSigninWithSocial(data) {
    const invitationToken = AuthInvitationToken.get();

    const response = await authAxios.post(
      '/auth/sign-in-social',
      {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: data.fullName,
        facebook_account: data.facebook_account,
        google_account: data.google_account,
        picture: data.picture,
        invitationToken,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    AuthInvitationToken.clear();

    return response.data;
  }

  static async verifyEmailAndPassword(email, password) {
    const response = await authAxios.post(
      '/auth/verifyEmailAndPassword',
      {
        email,
        password,
      },
    );

    return response.data;
  }

  // static async fetchMe() {
  //   const response = await authAxios.get('/auth/me');
  //   console.log(response.data);
  //   return response.data;
  // }

  static async fetchMe() {
    var response = {} as any;
    var current_user = {} as any;

    response = await authAxios.get('/auth/me/user');
    current_user = response.data;

    // console.log({
    //   tenant_id: current_user.tenants[0].tenant,
    //   user_id: current_user.id,
    // });

    for (
      var i = 0;
      i == 0 && i < current_user.tenants.length;
      i++
    ) {
      var tenant = await authAxios.post('/auth/me/tenant', {
        tenant_id: current_user.tenants[i].tenant,
        user_id: current_user.id,
      });
      current_user.tenants[i].tenant = tenant.data;
    }
    // await current_user.tenants.map(async (o) => {
    //   var tenant = await authAxios.post('/auth/me/tenant', {
    //     tenant_id: o.tenant,
    //     user_id: current_user.id,
    //   });

    //   o.tenant = tenant.data;
    // });

    // console.log(current_user);

    return current_user;
  }

  static signout() {
    AuthToken.set(null, true);
  }

  static async updateProfile(data) {
    const body = {
      data,
    };

    const response = await authAxios.put(
      '/auth/profile',
      body,
    );

    return response.data;
  }

  static async changePassword(oldPassword, newPassword) {
    const body = {
      oldPassword,
      newPassword,
    };

    const response = await authAxios.put(
      '/auth/change-password',
      body,
    );

    return response.data;
  }

  static async create_social_account(newPassword) {
    const body = {
      newPassword,
    };

    const response = await authAxios.put(
      '/auth/create-local-account',
      body,
    );

    return response.data;
  }

  static async passwordReset(token, password) {
    const response = await authAxios.put(
      '/auth/password-reset',
      {
        token,
        password,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    return response.data;
  }

  static async verifyEmail(
    token,
    fullAuthorize,
    assist,
    signup_mode,
  ) {
    const response = await authAxios.put(
      '/auth/verify-email',
      {
        token,
        fullAuthorize,
        assist,
        signup_mode,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    return response.data;
  }

  static async verifyFullAccessEmail(user, assist) {
    const response = await authAxios.post(
      '/assistant_fullaccess',
      {
        user,
        assist,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    return response.data;
  }

  static async socialOnboard() {
    const invitationToken = AuthInvitationToken.get();

    const response = await authAxios.post(
      '/auth/social/onboard',
      {
        invitationToken,
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    );

    AuthInvitationToken.clear();

    return response.data;
  }

  static isSocialOnboardRequested() {
    const urlParams = new URLSearchParams(
      window.location.search,
    );

    return Boolean(urlParams.get('social'));
  }

  static async getQrCodeUrl(email) {
    const response = await authAxios.post(
      '/auth/authQrCode',
      {
        email,
      },
    );

    return response.data;
  }

  static async resetQrCodeUrl(email) {
    const response = await authAxios.post(
      '/auth/authResetQrCode',
      {
        email,
      },
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/auth/list`, {
      params,
    });

    return response.data;
  }

  static async verifyMfaToken(verificationCode, email) {
    const response = await authAxios.post(
      '/auth/verify2factorotp',
      {
        verificationCode,
        email,
      },
    );

    return response.data;
  }

  static async verifyEmailToken(verificationCode, email) {
    const response = await authAxios.post(
      '/auth/verifyemailtoken',
      {
        verificationCode,
        email,
      },
    );

    return response.data;
  }

  static async changeMfaSetting(id, enableMfa, emailAuth) {
    const body = {
      id,
      enableMfa,
      emailAuth,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user/enableMfa`,
      body,
    );

    return response.data;
  }

  static async changeLanding(data) {
    const body = {
      data,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user/userLanding`,
      body,
    );

    return response.data;
  }

  static async sendUnlockRequestEmail(email) {
    const response = await authAxios.post(
      '/auth/send-unlock-request-email',
      {
        email,
      },
    );

    return response.data;
  }

  static async findByTag(myTag) {
    const response = await authAxios.get(
      `/auth/myTag/${myTag}`,
    );
    return response.data;
  }

  static async vault_send_unlock_request(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/vault/sendUnlockRequest`,
      body,
    );

    return response.data;
  }

  static async Get_Assistant_Access(data) {
    const response = await authAxios.post(
      '/assistant_getAccessRight',
      data,
    );
    return response.data;
  }

  static async updateModifyForm(data) {
    const body = {
      data
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user/updateModify`,
      body,
    );

    return response.data;
  }
}
