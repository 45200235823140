import PermissionChecker from 'src/modules/auth/permissionChecker';
import React from 'react';
import {
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import routes from 'src/view/routes';
import Layout from 'src/view/layout/Layout';
// import config from 'src/config';
import { getConfig } from 'src/config';
import { tenantSubdomain } from 'src/modules/tenant/tenantSubdomain';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

function PrivateRoute({
  component: Component,
  currentTenant,
  currentUser,
  permissionRequired,
  ...rest
}) {
  const location = useLocation();

  const isWasiatWillMode =
    AuthCurrentTenant.getWillMode() === 'wasiat' ?? false;
  const resetStep = () => {
    var stepper = rest.stepper;
    if (stepper !== null && stepper !== undefined) {
      if (stepper > 1) {
        var invalidStep = 0;
        for (var i = stepper; i >= 1; i--) {
          var value = routes.privateRoutes
            .filter((x) => {
              if (isWasiatWillMode) {
                return x.isWasiatRequired === null ||
                  x.isWasiatRequired === undefined
                  ? true
                  : x.isWasiatRequired;
              }
              return true;
            })
            .find((x) => x.step === i);

          if (value === null || value === undefined) {
            invalidStep += 1;
          }
        }

        if (invalidStep > 0)
          stepper = stepper - invalidStep;
      }
    }

    return stepper;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentTenant,
          currentUser,
        );

        if (!permissionChecker.isAuthenticated) {
          const miniappcode =
            localStorage.getItem('miniappcode');

          localStorage.setItem('miniappcode', '');

          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: location },
                search: miniappcode
                  ? `?miniappcode=${miniappcode}`
                  : '',
              }}
            />
          );
        }

        if (!permissionChecker.isEmailVerified) {
          return <Redirect to="/auth/email-unverified" />;
        }

        if (
          ['multi', 'multi-with-subdomain'].includes(
            // config.tenantMode,
            getConfig().REACT_APP_TENANT_MODE,
          ) &&
          !tenantSubdomain.isSubdomain
        ) {
          if (permissionChecker.isEmptyTenant) {
            return <Redirect to="/auth/tenant" />;
          }
        } else {
          if (permissionChecker.isEmptyPermissions) {
            return (
              <Redirect to="/auth/empty-permissions" />
            );
          }
        }

        if (!permissionChecker.match(permissionRequired)) {
          return <Redirect to="/403" />;
        }

        return (
          <Layout
            {...props}
            params={{
              stepper: resetStep(),
              section: rest.section,
            }}
          >
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

export default PrivateRoute;
