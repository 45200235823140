import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.witness.fields.id'),
  },
  {
    name: 'Witness_1',
    label: i18n('entities.witness.fields.Witness_1'),
  },
  {
    name: 'Witness_2',
    label: i18n('entities.witness.fields.Witness_2'),
  },
  {
    name: 'Witness_3',
    label: i18n('entities.witness.fields.Witness_3'),
  },
  {
    name: 'Witness_4',
    label: i18n('entities.witness.fields.Witness_4'),
  },
  {
    name: 'Remarks',
    label: i18n('entities.witness.fields.Remarks'),
  },
  {
    name: 'updatedDate',
    label: i18n('entities.witness.fields.updatedDate'),
    render: exporterRenders.datetime(),
  },
];
