import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class TenantCurrent {

    //#region Additional information 
    static setPage(value) {
        localStorage.setItem('currentpage', value);
    }

    static getPage() {
        return localStorage.getItem('currentpage') || '';
    }

    static getShowAdditionalInfo() {
        const showMore = ["residuary", "legal-guardian", "witnesss", "beneficiary", "executors", "last-instructions", "pledge", "pet"]
        const specialCondition = ["residuary", "legal-guardian", "executors", "witnesss"]

        var show = false;
        var tenant = AuthCurrentTenant.getTenant();

        var currentLocation = window.location.pathname.split('/');
        var page = currentLocation[1];

        if (showMore.indexOf(page) >= 0) {
            show = true;
            if (tenant?.helpInfo)
                show = Boolean(tenant.helpInfo[page] ?? true);

            if (this.getPage() === '' || this.getPage() !== page) {
                this.setPage(page);
            }
            else {
                var isFirstView = currentLocation.length <= 2;

                if (!isFirstView || (specialCondition.indexOf(page) <= 0))
                    show = false;
            }
        }
        else
            this.setPage('');

        return show;
    }

    static clearShowAdditionalInfo() {
        localStorage.setItem('currentpage', null);
    }

    static async updateAdditionalInfo(value) {
        var oldtenant = AuthCurrentTenant.getTenant();
        await TenantService.find(oldtenant.id)
            .then(async (tenant) => {
                var currentpage = this.getPage();
                var helpInfo = tenant.helpInfo ?? {};
                helpInfo[currentpage] = !value;

                await TenantService.update(tenant.id, { helpInfo: helpInfo })
                    .then(async () => {
                        await TenantService.find(tenant.id).then((newTenant) => {
                            AuthCurrentTenant.set(newTenant);
                        });

                    });

            });

    }
    //#endregion
}