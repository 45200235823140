import { tenantSubdomain } from '../tenant/tenantSubdomain';
import TenantCurrent from 'src/modules/tenant/tenantCurrent';
import EncryptionTool from 'src/modules/encryption/dataEncryption';

export default class AuthCurrentTenant {
  static selectAndSaveOnStorageFor(currentUser) {
    if (!currentUser) {
      return null;
    }

    if (!currentUser.tenants) {
      return null;
    }

    const activeTenants = currentUser.tenants.filter(
      (tenantUser) => tenantUser.status === 'active',
    );

    if (!activeTenants || !activeTenants.length) {
      return null;
    }

    const tenantId = this.get();

    let tenant;

    if (tenantId) {
      tenant = activeTenants
        .map((tenantUser) => tenantUser.tenant)
        .find((tenant) => tenant.id === tenantId);
    }

    tenant = tenant || activeTenants[0].tenant;

    if (
      tenant &&
      tenantSubdomain.isEnabled &&
      !tenantSubdomain.isSubdomainOf(tenant.url)
    ) {
      return tenantSubdomain.redirectAuthenticatedTo(
        tenant.url,
      );
    }

    this.set(tenant);
    return tenant;
  }

  //#region Tenant
  static get() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenant'),
    );

    if (tenantASString) {
      return JSON.parse(tenantASString)._id?.toString();
    }

    return null;
  }

  static getTenant() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenant'),
    );
    if (tenantASString) {
      return JSON.parse(tenantASString);
    }

    return null;
  }

  static getSettings() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenant'),
    );
    if (tenantASString) {
      const tenant = JSON.parse(tenantASString);

      if (tenant) {
        if (Array.isArray(tenant.settings)) {
          if (tenant.settings.length) {
            return tenant.settings[0];
          }
        } else {
          return tenant.settings;
        }
      }
    }

    return null;
  }

  static set(tenant) {
    if (!tenant) {
      return this.clear();
    }
    localStorage.setItem(
      'tenant',
      EncryptionTool.encrypt(JSON.stringify(tenant)),
    );
  }

  static clear() {
    localStorage.removeItem('tenant');
  }

  static clearAllLocal() {
    localStorage.removeItem('tenant');
    localStorage.removeItem('isEditForm');
    localStorage.removeItem('continueShowLanding');
    this.cleaRemoteSetting();
    this.clearAccessList();
    this.clearNotification();
    TenantCurrent.clearShowAdditionalInfo();
  }
  //#endregion

  //#region For Assistant
  static setAssistant(assistant) {
    if (!assistant) {
      return this.clearAssistant();
    }

    localStorage.setItem(
      'tenantAssistant',
      JSON.stringify(assistant),
    );
  }

  static getAssistant(target) {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenantAssistant'),
    );

    if (tenantASString) {
      var result = JSON.parse(tenantASString);
      console.log("result", result)
      var tenant = result['tenant'];
      var currentUser = result['currentUser'];

      if (tenant.createdBy === currentUser.id) {
        return {
          view: true,
          edit: true,
        };
      }

      const deathStatus = result['deathStatus'];
      const viewField = 'assistant' + target + 'View';
      const modeField = 'assistant' + target + 'Mode';

      return {
        view:
          result[viewField] === 'Now' ||
          (result[viewField] === 'AfterDeath' &&
            deathStatus),
        edit: result[modeField] === 'Read & Edit',
      };
    }

    return {
      view: true,
      edit: true,
    };
  }

  static isAssistant() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenantAssistant'),
    );

    return tenantASString ? true : false;
  }

  static clearAssistant() {
    localStorage.removeItem('tenantAssistant');
  }
  //#endregion

  //#region For wasiat/Conventional setting
  static setRemoteSetting(setting) {
    if (!setting) {
      return this.cleaRemoteSetting();
    }

    localStorage.setItem(
      'tenantRemoteSetting',
      JSON.stringify(setting),
    );
  }

  static getWillMode() {
    const tenantASString =
      localStorage.getItem('tenantRemoteSetting') || null;

    if (tenantASString) {
      const setting = JSON.parse(tenantASString);
      return setting.userSettingsWillMode ?? null;
    }

    return null;
  }

  static cleaRemoteSetting() {
    localStorage.removeItem('tenantRemoteSetting');
  }
  //#endregion

  //#region For Access List
  static setAccessList(access) {
    if (!access) {
      return this.clearAccessList();
    }

    var newAccess = {
      data: access,
      retrieve_time: new Date(),
    };

    localStorage.setItem(
      'access_lists',
      JSON.stringify(newAccess),
    );
  }

  static clearAccessList() {
    localStorage.removeItem('access_lists');
  }

  static getAccessList() {
    const accessASString =
      localStorage.getItem('access_lists') || null;

    if (accessASString) {
      const access = JSON.parse(accessASString);
      return access ?? null;
    }

    return null;
  }
  //#endregion

  //#region For Notification
  static setNotification(notice) {
    if (!notice) {
      return this.clearNotification();
    }
    // console.log("notice", notice)

    var newNotice = {
      data: notice,
      retrieve_time: new Date(),
    };

    localStorage.setItem(
      'notification_lists',
      JSON.stringify(newNotice),
    );
  }

  static clearNotification() {
    localStorage.removeItem('notification_lists');
  }

  static getNotificationList() {
    const noticeASString =
      localStorage.getItem('notification_lists') || null;

    if (noticeASString) {
      const notice = JSON.parse(noticeASString);
      return notice ?? null;
    }

    return null;
  }
  //#endregion
}
