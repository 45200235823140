import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.location.fields.id'),
  },
  {
    name: 'locationAddress',
    label: i18n('entities.location.fields.locationAddress'),
  },
  {
    name: 'locationDescription',
    label: i18n(
      'entities.location.fields.locationDescription',
    ),
  },
];
