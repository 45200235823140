import listActions from 'src/modules/invitation/list/invitationListActions';
import contactListListActions from 'src/modules/contactList/list/contactListListActions';
import invitationService from 'src/modules/invitation/invitationService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'INVITATION_DESTROY';

const invitationDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, type?) => async (dispatch) => {
    try {
      dispatch({
        type: invitationDestroyActions.DESTROY_STARTED,
      });

      await invitationService.destroyAll([id]);

      dispatch({
        type: invitationDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.invitation.destroy.success'),
      );

      switch(type){
        case "Contact-List":
          dispatch(contactListListActions.doFetchCurrentFilter());
          getHistory().push('/contact-list');
          break;
          default:
            dispatch(listActions.doFetchCurrentFilter());
            getHistory().push('/legal-guardian');
            break;
      }

    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: invitationDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: invitationDestroyActions.DESTROY_ALL_STARTED,
      });

      await invitationService.destroyAll(ids);

      dispatch({
        type: invitationDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.invitation.destroyAll.success'),
      );

      getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: invitationDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default invitationDestroyActions;
