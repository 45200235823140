import { i18n } from 'src/i18n';
import { 
  TeamOutlined,
  CompassOutlined, 
} from '@ant-design/icons';

export default [
  {
    path: '/profile-assistant',
    icon: <TeamOutlined />,
    label: i18n('auth.profile.myProfile'),
  },
  {
    path: '/location',
    icon: <CompassOutlined />,
    label: i18n('app.addressBook'),
  },
].filter(Boolean);
