import {
  Avatar,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import defaultUser from 'src/images/defaultUser.jpg';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import NotificationService from 'src/modules/notification/notificationService';
import { timeSince } from 'src/utils/functions';

type TNotification = {
  createdAt: string;
  createdBy: string;
  id: string;
  image: string;
  isRead: boolean;
  linkDesc: string;
  linkLocation: string;
  tenant: string;
  title: string;
  updatedAt: string;
  updatedBy: string;
  __v: number;
  _id: string;
};

type TNotificationObj = {
  rows: TNotification[];
  count: number;
};

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: '#f2f2f2',
  },
  '& .MuiListItem-root': {
    padding: 0,
  },
}));

const NotificationList = (props) => {
  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: '0 6px',
  };
  const chipErrorSX = {
    ...chipSX,
    color: 'red',
    backgroundColor: '#ffcccc',
    marginRight: '5px',
  };

  const chipWarningSX = {
    ...chipSX,
    color: 'orange',
    backgroundColor: '#ffedcc',
  };

  const [displayList, setDisplayList] = useState([]);
  const [isStart, setIsStart] = useState(true);

  useEffect(() => {
    // AuthCurrentTenant.clearNotification();
    let notices = AuthCurrentTenant.getNotificationList();

    if (notices === null) {
      refreshNotification();
    } else {
      let duration = moment.duration(
        moment().diff(moment(notices.retrieve_time)),
      );

      if (duration.asMinutes() > 10) refreshNotification();
      else {
        setDisplayList(notices.data);
        setIsStart(false);
      }
    }
  }, []);

  useEffect(() => {
    if (displayList.length > 0) {
      let unreadList = displayList.filter(
        (x) => x.isRead === false,
      );
      props.setUnread(unreadList.length);
    }
  }, [displayList, props]);

  const refreshNotification = async () => {
    const rowNotifications: TNotificationObj =
      await NotificationService.list(
        { title: null },
        { createdAt: -1 }, // DESC order
        10,
        0,
      );

    const tmp = rowNotifications.rows.map((item) => {
      item.image = defaultUser;
      return item;
    });
    AuthCurrentTenant.setNotification(tmp);
    setDisplayList(rowNotifications.rows);
    setIsStart(false);
  };

  const isLatestNotice = (inputDate) => {
    let status = false;

    if (inputDate) {
      let given = moment(inputDate, 'YYYY-MM-DD');
      let current = moment().startOf('day');
      let diff = moment
        .duration(current.diff(given))
        .asDays();

      if (diff <= 3) status = true;
    }

    return status;
  };

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 330,
          py: 0,
          borderRadius: '10px',
          [theme.breakpoints.down('md')]: {
            maxWidth: 300,
          },
          '& .MuiListItemSecondaryAction-root': {
            top: 22,
          },
          '& .MuiDivider-root': {
            my: 0,
          },
          '& .list-container': {
            pl: 7,
          },
        }}
      >
        {isStart && (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              minHeight: '100px',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {displayList.length > 0 &&
          !isStart &&
          displayList
            .filter((x) => {
              if (
                props.status === '' ||
                props.status === 'all'
              )
                return true;

              if (props.status === 'new')
                return isLatestNotice(x.createdAt) === true;

              if (props.status === 'unread')
                return x.isRead === false;

              return false;
            })
            .slice(0, 10)
            .map((item: TNotification, index) => {
              return (
                <div key={`${item._id}-${index}`}>
                  <ListItemWrapper>
                    <ListItem alignItems="center">
                      <ListItemAvatar>
                        <Avatar src={item.image} />
                      </ListItemAvatar>
                      <ListItemText primary="System Info." />
                      <ListItemSecondaryAction>
                        <Grid
                          container
                          justifyContent="flex-end"
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              {timeSince(
                                item.updatedAt ?? '',
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <Grid
                      container
                      direction="column"
                      className="list-container"
                    >
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          {item.isRead === false && (
                            <Grid item>
                              <Chip
                                label="Unread"
                                sx={chipErrorSX}
                              />
                            </Grid>
                          )}
                          {isLatestNotice(
                            item.createdAt,
                          ) === true && (
                            <Grid item>
                              <Chip
                                label="New"
                                sx={chipWarningSX}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItemWrapper>
                  <Divider />
                </div>
              );
            })}
      </List>
    </>
  );
};

export default NotificationList;
