import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';

const onNavigate = (path, cUserPlan = 'free', isValidFromSubscription = true) => {
  if (
    localStorage.getItem('isDirty') === 'true' &&
    !window.confirm(i18n('common.isDirty'))
  ) {
    // User cancelled navigation, do nothing
  } else {
    localStorage.removeItem('isDirty');

    if (!(isValidFromSubscription && path.replace('/', '') === "assetListPage")) {
      if (cUserPlan !== 'comprehensive')
        switch (path.replace('/', '')) {
          case 'health-info':
          case 'funeral-directives':
          case 'last-instructions':
          case 'assetListPage':
            path = 'will-plan';
            break;
        }
    }



    getHistory().push(path);
  }
};

export default onNavigate;
