import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.pet.fields.id'),
  },
  {
    name: 'photo',
    label: i18n('entities.pet.fields.photo'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'name',
    label: i18n('entities.pet.fields.name'),
  },
  {
    name: 'icno',
    label: i18n('entities.pet.fields.icno'),
  },
  {
    name: 'race',
    label: i18n('entities.pet.fields.race'),
  },
  {
    name: 'remarks',
    label: i18n('entities.pet.fields.remarks'),
  },
  {
    name: 'LegalGuardian_1',
    label: i18n('entities.pet.fields.LegalGuardian_1'),
  },
  {
    name: 'LegalGuardian_2',
    label: i18n('entities.pet.fields.LegalGuardian_2'),
  },
  {
    name: 'LegalGuardian_3',
    label: i18n('entities.pet.fields.LegalGuardian_3'),
  },
  {
    name: 'Currency',
    label: i18n('entities.pet.fields.Currency'),
    render: exporterRenders.currency(),
  },
  {
    name: 'fee',
    label: i18n('entities.pet.fields.fee'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'createdDate',
    label: i18n('entities.pet.fields.createdDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedDate',
    label: i18n('entities.pet.fields.updatedDate'),
    render: exporterRenders.datetime(),
  },
];
