import service from 'src/modules/auth/authService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import { AuthToken } from 'src/modules/auth/authToken';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import { tenantSubdomain } from '../tenant/tenantSubdomain';
import SettingsService from '../settings/settingsService';
import GiftService from '../gift/GiftService';
import PlanService from '../plan/planService';
import AssistantService from 'src/modules/assistant/assistantService';
import userSettingsActions from 'src/modules/userSettings/userSettingsActions';
import UserSettingsService from 'src/modules/userSettings/userSettingsService';
import UserService from 'src/modules/user/userService';
import TenantService from '../tenant/tenantService';
import globalActions from 'src/modules/global/globalActions';

const prefix = 'AUTH';

const authActions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

  PASSWORD_CHANGE_START: `${prefix}_PASSWORD_CHANGE_START`,
  PASSWORD_CHANGE_SUCCESS: `${prefix}_PASSWORD_CHANGE_SUCCESS`,
  PASSWORD_CHANGE_ERROR: `${prefix}_PASSWORD_CHANGE_ERROR`,

  CREATE_ACCOUNT_START: `${prefix}_CREATE_ACCOUNT_START`,
  CREATE_ACCOUNT_SUCCESS: `${prefix}_CREATE_ACCOUNT_SUCCESS`,
  CREATE_ACCOUNT_ERROR: `${prefix}_CREATE_ACCOUNT_ERROR`,

  CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
  CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
  CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

  PASSWORD_RESET_EMAIL_START: `${prefix}_PASSWORD_RESET_EMAIL_START`,
  PASSWORD_RESET_EMAIL_SUCCESS: `${prefix}_PASSWORD_RESET_EMAIL_SUCCESS`,
  PASSWORD_RESET_EMAIL_ERROR: `${prefix}_PASSWORD_RESET_EMAIL_ERROR`,

  PASSWORD_RESET_START: `${prefix}_PASSWORD_RESET_START`,
  PASSWORD_RESET_SUCCESS: `${prefix}_PASSWORD_RESET_SUCCESS`,
  PASSWORD_RESET_ERROR: `${prefix}_PASSWORD_RESET_ERROR`,

  EMAIL_VERIFY_START: `${prefix}_EMAIL_VERIFY_START`,
  EMAIL_VERIFY_SUCCESS: `${prefix}_EMAIL_VERIFY_SUCCESS`,
  EMAIL_VERIFY_ERROR: `${prefix}_EMAIL_VERIFY_ERROR`,

  EMAIL_CONFIRMATION_START: `${prefix}_EMAIL_CONFIRMATION_START`,
  EMAIL_CONFIRMATION_SUCCESS: `${prefix}_EMAIL_CONFIRMATION_SUCCESS`,
  EMAIL_CONFIRMATION_ERROR: `${prefix}_EMAIL_CONFIRMATION_ERROR`,

  MFA_CHANGE_START: `${prefix}_MFA_CHANGE_START`,
  MFA_CHANGE_SUCCESS: `${prefix}_MFA_CHANGE_SUCCESS`,
  MFA_CHANGE_ERROR: `${prefix}_MFA_CHANGE_ERROR`,

  MFA_REQUEST_START: `${prefix}_MFA_REQUEST_START`,
  MFA_REQUEST_SUCCESS: `${prefix}_MFA_REQUEST_SUCCESS`,
  MFA_REQUEST_ERROR: `${prefix}_MFA_REQUEST_ERROR`,

  UNLOCK_REQUEST_EMAIL_START: `${prefix}_UNLOCK_REQUEST_EMAIL_START`,
  UNLOCK_REQUEST_EMAIL_SUCCESS: `${prefix}_UNLOCK_REQUEST_EMAIL_SUCCESS`,
  UNLOCK_REQUEST_EMAIL_ERROR: `${prefix}_UNLOCK_REQUEST_EMAIL_ERROR`,

  REFRESH_TENANT_START: `${prefix}_REFRESH_TENANT_START`,
  REFRESH_TENANT_SUCCESS: `${prefix}_REFRESH_TENANT_SUCCESS`,
  REFRESH_TENANT_ERROR: `${prefix}_REFRESH_TENANT_ERROR`,

  doClearErrorMessage() {
    return {
      type: authActions.ERROR_MESSAGE_CLEARED,
    };
  },

  doSendEmailConfirmation:
    () => async (dispatch, getState) => {
      try {
        dispatch({
          type: authActions.EMAIL_CONFIRMATION_START,
        });
        await service.sendEmailVerification();
        Message.success(
          i18n('auth.verificationEmailSuccess'),
        );
        dispatch({
          type: authActions.EMAIL_CONFIRMATION_SUCCESS,
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: authActions.EMAIL_CONFIRMATION_ERROR,
        });
      }
    },

  doSendPasswordResetEmail: (email) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_START,
      });
      await service.sendPasswordResetEmail(email);
      Message.success(
        i18n('auth.passwordResetEmailSuccess'),
      );
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_ERROR,
      });
    }
  },

  doRegisterEmailAndPassword:
    (
      email,
      password,
      newsletter,
      referralCode,
      fullName,
      phoneNumber,
      phoneNumberCountryCode,
      signup_mode,
    ) =>
    async (dispatch) => {
      try {
        dispatch({ type: authActions.AUTH_START });

        const token =
          await service.registerWithEmailAndPassword(
            email,
            password,
            newsletter,
            referralCode,
            fullName,
            phoneNumber,
            phoneNumberCountryCode,
            signup_mode,
          );

        // AuthToken.set(token, true);
        getHistory().push(
          '/auth/email-verification/' + email,
        );

        // const currentUser = await service.fetchMe();
        // dispatch({
        //   type: authActions.AUTH_SUCCESS,
        //   payload: {
        //     currentUser,
        //   },
        // });
      } catch (error) {
        await service.signout();

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: authActions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doRegisterSocial: (data) => async (dispatch) => {
    try {
      dispatch({ type: authActions.AUTH_START });

      let currentUser = null;
      let result = true;
      let queries = {};
      let user_queries = {};
      if (data.facebook_account !== null) {
        queries = {
          facebook_account: data.facebook_account,
        };
        user_queries = { email: data.facebook_account };
      }

      if (data.google_account !== null) {
        queries = { google_account: data.google_account };
        user_queries = { email: data.google_account };
      }
      let is_social_user = await Promise.all([
        service.list(queries, null, null, null),
      ]).then((response) => {
        return response[0].count > 0;
      });

      if (!is_social_user) {
        let is_existing_user = await Promise.all([
          service.list(user_queries, null, null, null),
        ]).then((response) => {
          return response[0].count > 0;
        });

        if (is_existing_user) {
          await service.linkAccountWithSocial(data);
        } else {
          await service.registerWithSocial(data);
        }
      }

      is_social_user = await Promise.all([
        service.list(queries, null, null, null),
      ]).then((response) => {
        return response[0].count > 0;
      });

      const token = await service.doSigninWithSocial(data);

      AuthToken.set(token, true);

      currentUser = await service.fetchMe();

      let response = await Promise.all([
        GiftService.list(
          { email: `${data.email}`, plan_activated: false },
          null,
          null,
          null,
        ),
      ]).then((data) => {
        var Is_Binding = false;

        data.forEach((item) => {
          if (item.count > 0) {
            Is_Binding = true;
          }
        });

        return {
          Is_Exclusive_Customer: Is_Binding,
          plan: data[0]?.rows[0]?.plan,
        };
      });

      if (response.Is_Exclusive_Customer) {
        await PlanService.exclusive_gift(
          data.email,
          null,
          response.plan,
        );
      }

      if (result) {
        dispatch({
          type: authActions.AUTH_SUCCESS,
          payload: {
            currentUser,
          },
        });

        getHistory().push('/auth/tenant');
      } else {
        dispatch({
          type: authActions.AUTH_ERROR,
          payload: null,
        });

        Message.error('Existing User');
      }
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: authActions.AUTH_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doVerifyEmailSignin:
    (verifyToken) => async (dispatch) => {
      try {
        AuthCurrentTenant.clearAllLocal();
        await service.signout();

        dispatch({ type: authActions.AUTH_START });
        AuthToken.setLandingToken('true');
        let currentUser = null;

        const token =
          await service.signinWithEmailAndVerifyToken(
            verifyToken,
          );

        AuthToken.set(token, false);
        currentUser = await service.fetchMe();

        if (currentUser.isLock === true) {
          await service.signout();
          dispatch({
            type: authActions.AUTH_ERROR,
            payload: Message.error(
              i18n('auth.signIn.isLockAccount'),
            ),
          });
        } else if (currentUser.userRole === 'agent') {
          await service.signout();
          dispatch({
            type: authActions.AUTH_ERROR,
            payload: Message.error(
              i18n('auth.signIn.notAgentRole'),
            ),
          });
        } else {
          let response = await Promise.all([
            GiftService.list(
              {
                email: `${currentUser.email}`,
                plan_activated: false,
              },
              null,
              null,
              null,
            ),
          ]).then((data) => {
            var Is_Binding = false;

            data.forEach((item) => {
              if (item.count > 0) {
                Is_Binding = true;
              }
            });

            return {
              Is_Exclusive_Customer: Is_Binding,
              plan: data[0]?.rows[0]?.plan,
            };
          });

          if (response.Is_Exclusive_Customer) {
            await PlanService.exclusive_gift(
              currentUser.email,
              null,
              response.plan,
            );
          }

          dispatch(userSettingsActions.doInitOnly());
          dispatch({
            type: authActions.AUTH_SUCCESS,
            payload: {
              currentUser,
            },
          });
          getHistory().push('/auth/signin');
        }
      } catch (error: any) {
        await service.signout();

        if (error?.response?.status === 400) {
          getHistory().push('/auth/signin');
        }

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: authActions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doSigninWithEmailAndPassword:
    (email, password, rememberMe, miniappcode) =>
    async (dispatch) => {
      try {
        dispatch({ type: authActions.AUTH_START });
        AuthToken.setLandingToken('true');
        let currentUser = null;

        const token =
          await service.signinWithEmailAndPassword(
            email,
            password,
          );

        if (miniappcode) {
          localStorage.setItem('miniappcode', miniappcode);
        }

        AuthToken.set(token, rememberMe);

        currentUser = await service.fetchMe();

        if (currentUser?.isModifyBefore) {
          dispatch(globalActions.doEditForm());
        }

        if (currentUser.isLock === true) {
          await service.signout();
          dispatch({
            type: authActions.AUTH_ERROR,
            payload: Message.error(
              i18n('auth.signIn.isLockAccount'),
            ),
          });
        } else if (currentUser.userRole === 'agent') {
          await service.signout();
          dispatch({
            type: authActions.AUTH_ERROR,
            payload: Message.error(
              i18n('auth.signIn.notAgentRole'),
            ),
          });
        } else {
          let response = await Promise.all([
            GiftService.list(
              { email: `${email}`, plan_activated: false },
              null,
              null,
              null,
            ),
          ]).then((data) => {
            var Is_Binding = false;

            data.forEach((item) => {
              if (item.count > 0) {
                Is_Binding = true;
              }
            });

            return {
              Is_Exclusive_Customer: Is_Binding,
              plan: data[0]?.rows[0]?.plan,
            };
          });

          if (response.Is_Exclusive_Customer) {
            await PlanService.exclusive_gift(
              email,
              null,
              response.plan,
            );
          }

          dispatch(userSettingsActions.doInitOnly());
          dispatch({
            type: authActions.AUTH_SUCCESS,
            payload: {
              currentUser,
            },
          });
        }
      } catch (error) {
        await service.signout();

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: authActions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doSendEmailAuthToken: (email) => async (dispatch) => {
    try {
      dispatch({ type: authActions.MFA_REQUEST_START });

      const token = await service.sendEmailAuthToken(email);

      dispatch({
        type: authActions.MFA_REQUEST_SUCCESS,
      });
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: authActions.MFA_REQUEST_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSigninWithSocial: (data) => async (dispatch) => {
    try {
      dispatch({ type: authActions.AUTH_START });

      let currentUser = null;
      let result = true;
      let queries = {};
      let user_queries = {};

      if (data.facebook_account !== null) {
        queries = {
          facebook_account: data.facebook_account,
        };
        user_queries = { email: data.facebook_account };
      }

      if (data.google_account !== null) {
        queries = { google_account: data.google_account };
        user_queries = { email: data.google_account };
      }

      let is_social_user = await Promise.all([
        service.list(queries, null, null, null),
      ]).then((response) => {
        return response[0].count > 0;
      });

      if (is_social_user) {
        const token = await service.doSigninWithSocial(
          data,
        );

        AuthToken.set(token, true);

        await dispatch(authActions.doRefreshCurrentUser());
        currentUser = await service.fetchMe();

        let response = await Promise.all([
          GiftService.list(
            {
              email: `${data.email}`,
              plan_activated: false,
            },
            null,
            null,
            null,
          ),
        ]).then((data) => {
          var Is_Binding = false;

          data.forEach((item) => {
            if (item.count > 0) {
              Is_Binding = true;
            }
          });

          return {
            Is_Exclusive_Customer: Is_Binding,
            plan: data[0]?.rows[0]?.plan,
          };
        });

        if (response.Is_Exclusive_Customer) {
          await PlanService.exclusive_gift(
            data.email,
            null,
            response.plan,
          );
        }
      } else {
        result = false;
      }

      if (result) {
        dispatch({
          type: authActions.AUTH_SUCCESS,
          payload: {
            currentUser,
          },
        });
      } else {
        dispatch({
          type: authActions.AUTH_ERROR,
          payload: null,
        });

        Message.error(
          'Account not created, please proceed to register.',
        );

        getHistory().push('/auth/signup');
      }
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: authActions.AUTH_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSignout: () => async (dispatch) => {
    try {
      dispatch({ type: authActions.AUTH_START });

      AuthCurrentTenant.clearAllLocal();
      await service.signout();

      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_ERROR,
      });
    }
  },

  doInit: () => async (dispatch) => {
    try {
      const token = AuthToken.get();
      let currentUser = null;

      if (token) {
        currentUser = await service.fetchMe();
      }

      if (currentUser?.isModifyBefore) {
        dispatch(globalActions.doEditForm());
      }

      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  doRefreshCurrentUser: () => async (dispatch) => {
    try {
      dispatch({
        type: authActions.CURRENT_USER_REFRESH_START,
      });

      let currentUser = null;
      const token = AuthToken.get();

      if (token) {
        currentUser = await service.fetchMe();
      }

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_ERROR,
        payload: error,
      });
    }
  },

  doUpdateProfile: (data) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UPDATE_PROFILE_START,
      });

      await service.updateProfile(data);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: authActions.UPDATE_PROFILE_SUCCESS,
      });
      await dispatch(authActions.doRefreshCurrentUser());
      if (data.redirectUrl) {
        Message.success(i18n('auth.profile.success'));
        getHistory().push(data.redirectUrl);
      } else if (data.noRedirect) {
        return;
      } else {
        Message.success(i18n('auth.profile.success'));
        getHistory().push('/');
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.UPDATE_PROFILE_ERROR,
      });
    }
  },

  doUpdateLanding: (data) => async (dispatch) => {
    try {
      // await service.updateProfile(data);

      var passData = {
        id: data.id,
        isLanding: data.isLanding,
        landingStartDetail: data.landingStartDetail,
        landingStartSituation: data.landingStartSituation,
        landingShowChecked: data.landingShowChecked,
        isCompleteStarterWill: data.isCompleteStarterWill,
      };

      await service.changeLanding(passData);

      // await dispatch(authActions.doRefreshCurrentUser());
    } catch (error) {
      Errors.handle(error);
    }
  },

  doUpdateFirstTimeComplete: (data) => async (dispatch) => {
    try {
      await service.updateProfile(data);
      // await dispatch(authActions.doRefreshCurrentUser());

      let currentUser = null;
      const token = AuthToken.get();

      if (token) {
        currentUser = await service.fetchMe();
      }

      dispatch({
        type: authActions.UPDATE_PROFILE_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  doChangePassword:
    (oldPassword, newPassword) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.PASSWORD_CHANGE_START,
        });

        await service.changePassword(
          oldPassword,
          newPassword,
        );

        dispatch({
          type: authActions.PASSWORD_CHANGE_SUCCESS,
        });
        await dispatch(authActions.doRefreshCurrentUser());
        Message.success(
          i18n('auth.passwordChange.success'),
        );
        getHistory().push('/');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.PASSWORD_CHANGE_ERROR,
        });
      }
    },

  doSocialAccount: (newPassword) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.CREATE_ACCOUNT_START,
      });

      await service.create_social_account(newPassword);

      dispatch({
        type: authActions.CREATE_ACCOUNT_SUCCESS,
      });
      await dispatch(authActions.doRefreshCurrentUser());
      Message.success(
        i18n('auth.Create_Local_Account.success'),
      );
      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.CREATE_ACCOUNT_ERROR,
      });
    }
  },

  doVerifyEmail:
    (token, fullAuthorize, assist, signup_mode) =>
    async (dispatch, getState) => {
      try {
        const isLoading =
          authSelectors.selectLoadingVerifyEmail(
            getState(),
          );

        if (isLoading) {
          return;
        }

        dispatch({
          type: authActions.EMAIL_VERIFY_START,
        });

        await service.verifyEmail(
          token,
          fullAuthorize,
          assist,
          signup_mode,
        );

        await dispatch(authActions.doRefreshCurrentUser());

        dispatch({
          type: authActions.EMAIL_VERIFY_SUCCESS,
        });

        //getHistory().push('/');
        await service.signout();
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: authActions.EMAIL_VERIFY_ERROR,
        });
        getHistory().push('/');
      }
    },

  doVerifyFullAccessEmail:
    (user, assist) => async (dispatch, getState) => {
      try {
        dispatch({
          type: authActions.EMAIL_VERIFY_START,
        });

        await service.verifyFullAccessEmail(user, assist);

        await dispatch(authActions.doRefreshCurrentUser());

        dispatch({
          type: authActions.EMAIL_VERIFY_SUCCESS,
        });

        //getHistory().push('/');
        await service.signout();
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: authActions.EMAIL_VERIFY_ERROR,
        });
        getHistory().push('/');
      }
    },

  doResetPassword:
    (token, password) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.PASSWORD_RESET_START,
        });

        await service.passwordReset(token, password);

        Message.success(i18n('auth.passwordResetSuccess'));
        dispatch({
          type: authActions.PASSWORD_RESET_SUCCESS,
        });
        // getHistory().push('/');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.PASSWORD_RESET_ERROR,
        });

        dispatch(authActions.doSignout());
        getHistory().push('/');
      }
    },

  doSelectTenant: (tenant) => async (dispatch) => {
    if (tenantSubdomain.isEnabled) {
      tenantSubdomain.redirectAuthenticatedTo(tenant.url);
      return;
    }

    const currentUser = await service.fetchMe();

    if (currentUser.id !== tenant.createdBy) {
      var assistant =
        await AssistantService.findByUserIDAndTestatorID(
          currentUser.id,
          tenant.createdBy,
        );
      assistant['currentUser'] = currentUser;
      assistant['tenant'] = tenant;
      AuthCurrentTenant.setAssistant(assistant);
      const userSetting =
        await UserSettingsService.findById(
          tenant.createdBy,
        );

      AuthCurrentTenant.setRemoteSetting(userSetting);
    }

    AuthCurrentTenant.set(tenant);
    await dispatch(authActions.doRefreshCurrentUser());
    SettingsService.applyThemeFromTenant();
    getHistory().push('/');
  },

  doResetTenant: (currentUser) => async (dispatch) => {
    const tenant = await TenantService.find(
      currentUser.tenants[0].tenant?.id,
    );

    AuthCurrentTenant.clearAssistant();
    AuthCurrentTenant.cleaRemoteSetting();
    AuthCurrentTenant.set(tenant);
    await dispatch(
      authActions.doRefreshTenant(
        AuthCurrentTenant.getTenant(),
      ),
    );

    await dispatch(authActions.doRefreshCurrentUser());
    SettingsService.applyThemeFromTenant();
    getHistory().push(
      currentUser.id !== tenant.createdBy
        ? '/willListPage'
        : '/',
    );
  },

  doRefreshTenant: (tenant) => async (dispatch) => {
    dispatch({
      type: authActions.REFRESH_TENANT_START,
    });

    if (tenantSubdomain.isEnabled) {
      tenantSubdomain.redirectAuthenticatedTo(tenant.url);
      return;
    }

    const currentUser = await service.fetchMe();

    if (currentUser.id !== tenant.createdBy) {
      var assistant =
        await AssistantService.findByUserIDAndTestatorID(
          currentUser.id,
          tenant.createdBy,
        );
      assistant['currentUser'] = currentUser;
      assistant['tenant'] = tenant;
      AuthCurrentTenant.setAssistant(assistant);
    }

    // AuthCurrentTenant.set(tenant);
    // await dispatch(authActions.doRefreshCurrentUser());
    // SettingsService.applyThemeFromTenant();

    dispatch({
      type: authActions.REFRESH_TENANT_SUCCESS,
    });
  },

  doChangeMfaSetting:
    (id, value, email) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.MFA_CHANGE_START,
        });

        await service.changeMfaSetting(id, value, email);

        dispatch({
          type: authActions.MFA_CHANGE_SUCCESS,
        });
        await dispatch(authActions.doRefreshCurrentUser());
        Message.success(i18n('auth.security.success'));
        getHistory().push('/');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.MFA_CHANGE_ERROR,
        });
      }
    },

  doSendUnlockRequest: (data) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UNLOCK_REQUEST_EMAIL_START,
      });

      var result = await service.vault_send_unlock_request(
        data,
      );

      if (result.new_request === true) {
        Message.success(
          i18n('auth.unlockRequestEmailSuccess'),
        );
      } else if (result.can_open_vault === true) {
        Message.success(
          i18n('auth.vault.unlock_successful'),
        );
        // getHistory().go(0);
      } else {
        Message.success(
          i18n(
            'auth.vault.unlock_inprogress',
            result.remaining_days,
          ),
        );
      }

      dispatch({
        type: authActions.UNLOCK_REQUEST_EMAIL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: authActions.UNLOCK_REQUEST_EMAIL_ERROR,
      });
    }
  },
};

export default authActions;
