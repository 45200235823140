import { i18n } from 'src/i18n';

export default [
  {
    path: '/',
    exact: true,
  },
  {
    path: '/dashboard',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/contact-list',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/my-heir',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/family-tree',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/location',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/financial-planning',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/funeral-directives',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/account',
    // openKeys: i18n('app.importantDocument'),
    openKeys: [
      i18n('app.importantDocument'),
      i18n('app.myEstate'),
    ],
    exact: false,
  },
  {
    path: '/debt',
    // openKeys: i18n('app.importantDocument'),
    openKeys: [
      i18n('app.importantDocument'),
      i18n('app.myEstate'),
    ],
    exact: false,
  },
  {
    path: '/property',
    // openKeys: i18n('app.importantDocument'),
    openKeys: [
      i18n('app.importantDocument'),
      i18n('app.myEstate'),
    ],
    exact: false,
  },
  {
    path: '/other-asset',
    // openKeys: i18n('app.importantDocument'),
    openKeys: [
      i18n('app.importantDocument'),
      i18n('app.myEstate'),
    ],
    exact: false,
  },
  {
    path: '/pledge',
    openKeys: i18n('app.own'),
    exact: false,
  },
  {
    path: '/insurance',
    // openKeys: i18n('app.importantDocument'),
    openKeys: [
      i18n('app.importantDocument'),
      i18n('app.myEstate'),
    ],
    exact: false,
  },
  {
    path: '/liquid-asset',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/digital',
    // openKeys: i18n('app.importantDocument'),
    openKeys: [
      i18n('app.importantDocument'),
      i18n('app.myEstate'),
    ],
    exact: false,
  },
  {
    path: '/business',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/vehicle',
    // openKeys: i18n('app.importantDocument'),
    openKeys: [
      i18n('app.importantDocument'),
      i18n('app.myEstate'),
    ],
    exact: false,
  },
  {
    path: '/legal-guardian',
    openKeys: i18n('app.own'),
    exact: false,
  },
  {
    path: '/assetListPage',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/last-instructions',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/personal-message',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/health-info',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/willListPage',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/hibahListPage',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/hartasepencarianListPage',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/powerOfAttorney',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/documentation',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/message',
    openKeys: i18n('app.importantDocument'),
    exact: false,
  },
  {
    path: '/profile-assistant',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/profile',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/beneficiary',
    openKeys: i18n('app.people'),
    exact: false,
  },
  {
    path: '/guardians',
    openKeys: i18n('app.people'),
    exact: false,
  },
  {
    path: '/contact-list',
    openKeys: i18n('app.meAndMyCircle'),
    exact: false,
  },
  {
    path: '/executor',
    openKeys: i18n('app.willDetails'),
    exact: false,
  },
  {
    path: '/residuary',
    openKeys: i18n('app.willDetails'),
    exact: false,
  },
  {
    path: '/witness',
    openKeys: i18n('app.willDetails'),
    exact: false,
  },
  {
    path: '/last-instructions',
    openKeys: i18n('app.willDetails'),
    exact: false,
  },
  {
    path: '/personal-message',
    openKeys: i18n('app.willDetails'),
    exact: false,
  },
  {
    path: '/funeral-directives',
    openKeys: i18n('app.funeralDirectives'),
    exact: false,
  },
  {
    path: '/emergency-card',
    openKeys: i18n('app.sharing'),
    exact: false,
  },
  {
    path: '/share-with-me',
    openKeys: i18n('app.sharing'),
    exact: false,
  },
  {
    path: '/my-vault',
    openKeys: i18n('app.sharing'),
    exact: false,
  },
  {
    path: '/user/new-invitation',
    openKeys: i18n('app.sharing'),
    exact: false,
  },
  {
    path: '/assistant',
    openKeys: i18n('app.sharing'),
    exact: false,
  },
  {
    path: '/share-with-me',
    openKeys: i18n('app.digitalVault'),
    exact: false,
  },
  {
    path: '/signup-report',
    openKeys: i18n('reports.title'),
    exact: false,
  },
  {
    path: '/subscription-report',
    openKeys: i18n('reports.title'),
    exact: false,
  },
  {
    path: '/sales-report',
    openKeys: i18n('reports.title'),
    exact: false,
  },
  {
    path: '/videoChat',
    exact: true,
  },
  {
    path: '/learnMore',
    exact: true,
  },
  {
    path: '/resources',
    exact: true,
  },
].filter(Boolean);
