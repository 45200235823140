import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import promotionEnumerators from 'src/modules/promotion/promotionEnumerators';

export default [
  {
    name: 'promTitle',
    label: i18n('entities.promotion.fields.promTitle'),
    schema: schemas.string(
      i18n('entities.promotion.fields.promTitle'),
      {
        "required": true,
      },
    ),
  },
  {
    name: 'promDescription',
    label: i18n('entities.promotion.fields.promDescription'),
    schema: schemas.string(
      i18n('entities.promotion.fields.promDescription'),
      {
      },
    ),
  },
  {
    name: 'promType',
    label: i18n('entities.promotion.fields.promType'),
    schema: schemas.enumerator(
      i18n('entities.promotion.fields.promType'),
      {
        "required": true,
        "options": promotionEnumerators.promType
      },
    ),
  },
  {
    name: 'promValue',
    label: i18n('entities.promotion.fields.promValue'),
    schema: schemas.decimal(
      i18n('entities.promotion.fields.promValue'),
      {
        "required": true,
      },
    ),
  },
  {
    name: 'promTargetPlan',
    label: i18n('entities.promotion.fields.promTargetPlan'),
    schema: schemas.enumerator(
      i18n('entities.promotion.fields.promTargetPlan'),
      {
        "required": true,
        "options": promotionEnumerators.promTargetPlan
      },
    ),
  },
  {
    name: 'promStartDate',
    label: i18n('entities.promotion.fields.promStartDate'),
    schema: schemas.date(
      i18n('entities.promotion.fields.promStartDate'),
      {
        "required": true,
      },
    ),
  },
  {
    name: 'promEndDate',
    label: i18n('entities.promotion.fields.promEndDate'),
    schema: schemas.date(
      i18n('entities.promotion.fields.promEndDate'),
      {
        "required": true,
      },
    ),
  },
  {
    name: 'promAvailability',
    label: i18n('entities.promotion.fields.promAvailability'),
    schema: schemas.integer(
      i18n('entities.promotion.fields.promAvailability'),
      {},
    ),
  },
  {
    name: 'promCode',
    label: i18n('entities.promotion.fields.promCode'),
    schema: schemas.string(
      i18n('entities.promotion.fields.promCode'),
      {},
    ),
  },
  {
    name: 'promPicture',
    label: i18n('entities.promotion.fields.promPicture'),
    schema: schemas.files(
      i18n('entities.promotion.fields.promPicture'),
      {},
    ),
  },
  {
    name: 'promRemarks',
    label: i18n('entities.promotion.fields.promRemarks'),
    schema: schemas.string(
      i18n('entities.promotion.fields.promRemarks'),
      {},
    ),
  },
];