import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/solicitor/importer/solicitorImporterSelectors';
import SolicitorService from 'src/modules/solicitor/solicitorService';
import fields from 'src/modules/solicitor/importer/solicitorImporterFields';
import { i18n } from 'src/i18n';

const solicitorImporterActions = importerActions(
  'SOLICITOR_IMPORTER',
  selectors,
  SolicitorService.import,
  fields,
  i18n('entities.solicitor.importer.fileName'),
);

export default solicitorImporterActions;