import { Layout as AntLayout, Modal, Button } from 'antd';
import React, { useEffect } from 'react';
import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';
import NavFooter from 'src/view/layout/NavigationFooter';
import LayoutWrapper from 'src/view/layout/styles/LayoutWrapper';
import HeaderWithImages from 'src/view/layout/HeaderWithImages';
import { useRouteMatch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { i18n } from 'src/i18n';

import { useState } from 'react';
import useScrollListener from 'src/view/layout/useScroll';
import './styles.css';
import IdleTimer from 'react-idle-timer';
import TimerModal from 'src/view/layout/TimerModal';
import Ticket from 'src/view/layout/Ticket';
import { getHistory } from 'src/modules/store';
// import { truncate } from 'fs';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';
// import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import WelcomeScreen from '../dashboard/WelcomeScreen';
import AppReleaseNoteModal from './AppReleaseNoteModal';

const { Content, Sider } = AntLayout;
const Layout = (props) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [zoomScale, SetZoomScale] = useState(100);
  const [navClassList, setNavClassList] = useState([]);
  const [timerModalVisible, setTimerModalVisible] =
    useState(false);
  const scroll = useScrollListener();
  var idleTimer = null;

  const currentUser = useSelector(
    selectors.selectCurrentUser,
  );

  const [ModalVisible, setModalVisible] = useState(
    !currentUser.fullName ||
      !currentUser.idNo ||
      !currentUser.dob,
  );

  const [welcomeScreenSkip, setWelcomeScreenSkip] =
    useState(currentUser.welcomeScreenSkip);
  const [welcomeScreen, setWelcomeScreen] = useState(
    currentUser.welcomeScreenTrigger !== null &&
      currentUser.welcomeScreenTrigger !== undefined
      ? currentUser.welcomeScreenTrigger
      : true,
  );
  const [welcomeScreenTitle, setWelcomeScreenTitle] =
    useState(i18n('dashboard.welcome'));

  useEffect(() => {
    // window.FreshworksWidget('identify', 'ticketForm', {
    //   name: currentUser?.fullName,
    //   email: currentUser?.email,
    // });
    if (
      !currentUser.fullName ||
      !currentUser.idNo ||
      !currentUser.dob
    ) {
      getHistory().push('/profile');
    }

    var zoomValue =
      window.sessionStorage.getItem('zoomValue');
    if (zoomValue != null || zoomValue != undefined) {
      SetZoomScale(parseInt(zoomValue));
    }

    const _classList = [];
    if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
      _classList.push('nav-bar--hidden');

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  function onChange(newScale) {
    window.sessionStorage.setItem('zoomValue', newScale);
    SetZoomScale(newScale);
  }

  const welcomeModalOnClose = () => {
    dispatch(
      authActions.doUpdateProfile({
        ...currentUser,
        welcomeScreenTrigger: false,
        noRedirect: true,
      }),
    );
    setWelcomeScreen(false);
  };

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const handleOnIdle = (e) => {
    handleTimerModalVisible(true);
  };

  const handleTimerModalVisible = (e) => {
    setTimerModalVisible(e);
  };

  const isShowStepper = (value) => {
    let result = false;
    try {
      if (value.indexOf('profile') >= 1) result = true;
      else if (value.indexOf('beneficiary') >= 1)
        result = true;
      else if (value.indexOf('executor') >= 1)
        result = true;
      else if (value.indexOf('legal-guardian') >= 1)
        result = true;
      else if (value.indexOf('residuary') >= 1)
        result = true;
      else if (value.indexOf('last-instructions') >= 1)
        result = true;
      else if (value.indexOf('witness') >= 1) result = true;
      else if (value.indexOf('pledge') >= 1) result = true;
      else result = false;
    } catch (err) {
      console.log('LAYOUT ERR: ', err);
    }
    return result;
  };

  return (
    <>
      {!isMobile && (
        <LayoutWrapper>
          <AntLayout>
            <Header
              isMobile={isMobile}
              zoomScale={zoomScale}
              onZoomChange={onChange}
              style={{
                position: 'fixed',
                zIndex: 1,
                width: '100%',
              }}
              match={match}
            />
            <AntLayout>
              <Sider
                theme="light"
                width={280}
                style={{ marginTop: '64px' }}
              >
                <Menu url={match.url} />
              </Sider>

              <Content
                style={{
                  zoom: zoomScale / 100,
                  margin: '36px 16px 0',
                  overflow: 'initial',
                  paddingTop: '3%',
                  // maxWidth: '80%',
                  paddingLeft: '5%',
                  paddingRight: '5%',
                }}
              >
                <HeaderWithImages
                  section={props.params.section}
                />
                {props.children}
                {!window.location.pathname.includes(
                  'hibah',
                ) &&
                  !window.location.pathname.includes(
                    'harta',
                  ) &&
                  props.params.stepper && (
                    <NavFooter
                      stepper={props.params.stepper}
                    />
                  )}
              </Content>

              <Ticket url={match.url} />
            </AntLayout>
          </AntLayout>
        </LayoutWrapper>
      )}
      {isMobile && (
        <LayoutWrapper>
          <Menu url={match.url} />
          <AntLayout>
            <Header
              isMobile={isMobile}
              zoomScale={zoomScale}
              onZoomChange={onChange}
              match={match}
            />
            {isMobile &&
              isShowStepper(
                getHistory().location.pathname,
              ) && (
                <nav className={navClassList.join(' ')}>
                  {props.params.stepper && (
                    <NavFooter
                      stepper={props.params.stepper}
                    />
                  )}
                </nav>
              )}
            <Content style={{ zoom: zoomScale / 100 }}>
              <HeaderWithImages
                section={props.params.section}
              />
              {props.children}
            </Content>
            {!isMobile && props.params.stepper && (
              <NavFooter stepper={props.params.stepper} />
            )}

            <Ticket url={match.url} />
          </AntLayout>
        </LayoutWrapper>
      )}
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        timeout={1000 * 60 * 5}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <TimerModal
        visible={timerModalVisible}
        handleTimerModalVisible={handleTimerModalVisible}
      />

      <Modal
        title={i18n('common.Profile_popup_title')}
        visible={ModalVisible}
        onCancel={() => doCloseModal()}
        centered
        footer={[
          <Button key="back" onClick={doCloseModal}>
            Continue
          </Button>,
        ]}
        width={isMobile ? '50%' : '30%'}
      >
        {i18n('common.Profile_popup_message')}
      </Modal>

      <AppReleaseNoteModal />
      <Modal
        title={welcomeScreenTitle}
        visible={welcomeScreen && !welcomeScreenSkip}
        closable={false}
        keyboard={false}
        // onCancel={() => {
        //   welcomeModalOnClose()
        // }}
        centered
        footer={null}
        width={isMobile ? '100%' : '50%'}
        style={{ height: isMobile ? '90vh' : null }}
        // bodyStyle={{ height: isMobile ? '100vh' : null }}
      >
        <WelcomeScreen
          setWelcomeScreen={setWelcomeScreen}
          setWelcomeScreenSkip={setWelcomeScreenSkip}
          setWelcomeScreenTitle={setWelcomeScreenTitle}
        />
      </Modal>
    </>
  );
};

export default Layout;
