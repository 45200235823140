import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.digital.fields.id'),
  },
  {
    name: 'Digital_Service_Category',
    label: i18n(
      'entities.digital.fields.Digital_Service_Category',
    ),
  },
  {
    name: 'Digital_Service_Name',
    label: i18n(
      'entities.digital.fields.Digital_Service_Name',
    ),
  },
  {
    name: 'Digital_Login_ID',
    label: i18n('entities.digital.fields.Digital_Login_ID'),
  },
  {
    name: 'Digital_Account_Number',
    label: i18n(
      'entities.digital.fields.Digital_Account_Number',
    ),
  },
  {
    name: 'Digital_Is_Paid',
    label: i18n('entities.digital.fields.Digital_Is_Paid'),
  },
  {
    name: 'Digital_File',
    label: i18n('entities.digital.fields.Digital_File'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'Digital_Note',
    label: i18n('entities.digital.fields.Digital_Note'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.digital.fields.createdAt'),
  },
];
