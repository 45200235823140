import { ConnectedRouter } from 'connected-react-router';
import {
  configureStore,
  getHistory,
} from 'src/modules/store';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import { ConfigProvider } from 'antd';
import { getAntdLanguage } from 'src/i18n';
import {
  MuiThemeProvider,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import ReactGA from 'react-ga4';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactGA.initialize('G-76GHE9DLT7');
ReactGA.send('pageview');

const store = configureStore();

const THEME = createMuiTheme({
  typography: {
    fontFamily: `"Noto Sans", sans-serif`,
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const App = (props) => {
  return (
    <GoogleOAuthProvider clientId="69022404588-sc5ci8pnbrhcota627v0agre4em2chsn.apps.googleusercontent.com">
      <MuiThemeProvider theme={THEME}>
        <ConfigProvider locale={getAntdLanguage()}>
          <Provider store={store}>
            <ConnectedRouter history={getHistory()}>
              <RoutesComponent />
            </ConnectedRouter>
          </Provider>
        </ConfigProvider>
      </MuiThemeProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
