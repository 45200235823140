import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.userProfileFile],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.userProfileFile],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.userProfileFile],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      beneficiaryImport: {
        id: 'beneficiaryImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      beneficiaryCreate: {
        id: 'beneficiaryCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.beneficiaryBenPhoto],
      },
      beneficiaryEdit: {
        id: 'beneficiaryEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.beneficiaryBenPhoto],
      },
      beneficiaryDestroy: {
        id: 'beneficiaryDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.beneficiaryBenPhoto],
      },
      beneficiaryRead: {
        id: 'beneficiaryRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      beneficiaryPhoto: {
        id: 'beneficiaryPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      beneficiaryPhotoNo: {
        id: 'beneficiaryPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      beneficiaryCreateNo: {
        id: 'beneficiaryCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      beneficiaryAutocomplete: {
        id: 'beneficiaryAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      executorImport: {
        id: 'executorImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      executorCreate: {
        id: 'executorCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.executorExecutorPhoto],
      },
      executorEdit: {
        id: 'executorEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.executorExecutorPhoto],
      },
      executorDestroy: {
        id: 'executorDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.executorExecutorPhoto],
      },
      executorRead: {
        id: 'executorRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      executorAutocomplete: {
        id: 'executorAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      executorPhoto: {
        id: 'executorPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      executorCreateNo: {
        id: 'executorCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      executorPhotoNo: {
        id: 'executorPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      witnessImport: {
        id: 'witnessImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      witnessCreate: {
        id: 'witnessCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.witnessPhoto],
      },
      witnessEdit: {
        id: 'witnessEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.witnessPhoto],
      },
      witnessDestroy: {
        id: 'witnessDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.witnessPhoto],
      },
      witnessRead: {
        id: 'witnessRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      witnessPhoto: {
        id: 'witnessPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      witnessCreateNo: {
        id: 'witnessCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      witnessPhotoNo: {
        id: 'witnessPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      witnessAutocomplete: {
        id: 'witnessAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      accountImport: {
        id: 'accountImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accountCreate: {
        id: 'accountCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.accountAccFile],
      },
      accountEdit: {
        id: 'accountEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.accountAccFile],
      },
      accountDestroy: {
        id: 'accountDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.accountAccFile],
      },
      accountRead: {
        id: 'accountRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accountAutocomplete: {
        id: 'accountAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accountFile: {
        id: 'accountFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accountCreateNo: {
        id: 'accountCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accountFileNo: {
        id: 'accountFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      propertyImport: {
        id: 'propertyImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      propertyCreate: {
        id: 'propertyCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.propertyPropPhoto,
          storage.propertyPropFiles,
        ],
      },
      propertyEdit: {
        id: 'propertyEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.propertyPropPhoto,
          storage.propertyPropFiles,
        ],
      },
      propertyDestroy: {
        id: 'propertyDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.propertyPropPhoto,
          storage.propertyPropFiles,
        ],
      },
      propertyRead: {
        id: 'propertyRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      propertyAutocomplete: {
        id: 'propertyAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      propertyCreateNo: {
        id: 'propertyCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      propertyFile: {
        id: 'propertyFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      propertyFileNo: {
        id: 'propertyFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      propertyPhoto: {
        id: 'propertyPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      propertyPhotoNo: {
        id: 'propertyPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      otherAssetImport: {
        id: 'otherAssetImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      otherAssetCreate: {
        id: 'otherAssetCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.otherAssetOthFile,
          storage.otherAssetOthPhoto,
        ],
      },
      otherAssetEdit: {
        id: 'otherAssetEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.otherAssetOthFile,
          storage.otherAssetOthPhoto,
        ],
      },
      otherAssetDestroy: {
        id: 'otherAssetDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.otherAssetOthFile,
          storage.otherAssetOthPhoto,
        ],
      },
      otherAssetRead: {
        id: 'otherAssetRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      otherAssetAutocomplete: {
        id: 'otherAssetAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      otherAssetCreateNo: {
        id: 'otherAssetCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      otherAssetFile: {
        id: 'otherAssetFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      otherAssetFileNo: {
        id: 'otherAssetFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      otherAssetPhoto: {
        id: 'otherAssetPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      otherAssetPhotoNo: {
        id: 'otherAssetPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      cashImport: {
        id: 'cashImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      cashCreate: {
        id: 'cashCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      cashEdit: {
        id: 'cashEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      cashDestroy: {
        id: 'cashDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      cashRead: {
        id: 'cashRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      cashAutocomplete: {
        id: 'cashAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      debtImport: {
        id: 'debtImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      debtCreate: {
        id: 'debtCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.debtDebtFile],
      },
      debtEdit: {
        id: 'debtEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.debtDebtFile],
      },
      debtDestroy: {
        id: 'debtDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.debtDebtFile],
      },
      debtRead: {
        id: 'debtRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      debtAutocomplete: {
        id: 'debtAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      debtFile: {
        id: 'debtFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      debtCreateNo: {
        id: 'debtCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      debtFileNo: {
        id: 'debtFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      digitalImport: {
        id: 'digitalImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      digitalCreate: {
        id: 'digitalCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.Digital_File],
      },
      digitalEdit: {
        id: 'digitalEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.Digital_File],
      },
      digitalDestroy: {
        id: 'digitalDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.Digital_File],
      },
      digitalRead: {
        id: 'digitalRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      digitalAutocomplete: {
        id: 'digitalAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      digitalFile: {
        id: 'digitalFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      digitalCreateNo: {
        id: 'digitalCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      digitalFileNo: {
        id: 'digitalFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      residuaryImport: {
        id: 'residuaryImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      residuaryCreate: {
        id: 'residuaryCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      residuaryEdit: {
        id: 'residuaryEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      residuaryDestroy: {
        id: 'residuaryDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      residuaryRead: {
        id: 'residuaryRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      residuaryAutocomplete: {
        id: 'residuaryAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      residuaryCreateNo: {
        id: 'residuaryCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      personalMessageCreate: {
        id: 'personalMessageCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      personalMessageEdit: {
        id: 'personalMessageEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      personalMessageDestroy: {
        id: 'personalMessageDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      personalMessageRead: {
        id: 'personalMessageRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      lastInstructionsImport: {
        id: 'lastInstructionsImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      lastInstructionsCreate: {
        id: 'lastInstructionsCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.instructionFile,
          storage.screenshotFile,
        ],
      },
      lastInstructionsEdit: {
        id: 'lastInstructionsEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.instructionFile],
      },
      lastInstructionsDestroy: {
        id: 'lastInstructionsDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.instructionFile],
      },
      lastInstructionsRead: {
        id: 'lastInstructionsRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      lastInstructionsAutocomplete: {
        id: 'lastInstructionsAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      lastInstructionsCreateNo: {
        id: 'lastInstructionsCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      lastInstructionsFile: {
        id: 'lastInstructionsFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      lastInstructionsFileNo: {
        id: 'lastInstructionsFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      exportHistoryCreate: {
        id: 'exportHistoryCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.Signed_Copy],
      },
      exportHistoryDestroy: {
        id: 'exportHistoryDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.Signed_Copy],
      },
      exportHistoryRead: {
        id: 'exportHistoryRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.Signed_Copy],
      },
      exportHistoryEdit: {
        id: 'exportHistoryEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.Signed_Copy],
      },

      Export: {
        id: 'Export',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      promotionImport: {
        id: 'promotionImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      promotionCreate: {
        id: 'promotionCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.promPicture],
      },
      promotionEdit: {
        id: 'promotionEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.promPicture],
      },
      promotionDestroy: {
        id: 'promotionDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.promPicture],
      },
      promotionRead: {
        id: 'promotionRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      promotionAutocomplete: {
        id: 'promotionAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      legalGuardianImport: {
        id: 'legalGuardianImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      legalGuardianCreate: {
        id: 'legalGuardianCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      legalGuardianEdit: {
        id: 'legalGuardianEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      legalGuardianDestroy: {
        id: 'legalGuardianDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      legalGuardianRead: {
        id: 'legalGuardianRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      legalGuardianAutocomplete: {
        id: 'legalGuardianAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      legalGuardianCreateNo: {
        id: 'legalGuardianCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      locationImport: {
        id: 'locationImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      locationCreate: {
        id: 'locationCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      locationEdit: {
        id: 'locationEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      locationDestroy: {
        id: 'locationDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      locationRead: {
        id: 'locationRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      locationAutocomplete: {
        id: 'locationAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      assetCategoryImport: {
        id: 'assetCategoryImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      assetCategoryCreate: {
        id: 'assetCategoryCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      assetCategoryEdit: {
        id: 'assetCategoryEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      assetCategoryDestroy: {
        id: 'assetCategoryDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      assetCategoryRead: {
        id: 'assetCategoryRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      assetCategoryAutocomplete: {
        id: 'assetCategoryAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      specificGiftCreate: {
        id: 'specificGiftCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      specificGiftEdit: {
        id: 'specificGiftEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      specificGiftDestroy: {
        id: 'specificGiftDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      specificGiftRead: {
        id: 'specificGiftRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      specificGiftCreateNo: {
        id: 'specificGiftCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      contactListImport: {
        id: 'contactListImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      contactListCreate: {
        id: 'contactListCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.contactListPropPhoto],
      },
      contactListEdit: {
        id: 'contactListEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.contactListPropPhoto],
      },
      contactListDestroy: {
        id: 'contactListDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.contactListPropPhoto],
      },
      contactListRead: {
        id: 'contactListRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      contactListAutocomplete: {
        id: 'contactListAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoChatRead: {
        id: 'videoChatRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoChatEdit: {
        id: 'videoChatEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoChatCreate: {
        id: 'videoChatCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoChatImport: {
        id: 'videoChatImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoChatDestroy: {
        id: 'videoChatDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoRoomRead: {
        id: 'videoRoomRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoRoomEdit: {
        id: 'videoRoomEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoRoomCreate: {
        id: 'videoRoomCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoRoomImport: {
        id: 'videoRoomImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoRoomDestroy: {
        id: 'videoRoomDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      pledgeCreate: {
        id: 'pledgeCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },

      pledgeImport: {
        id: 'pledgeImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      pledgeCreateNo: {
        id: 'pledgeCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      pledgeEdit: {
        id: 'pledgeEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      pledgeDestroy: {
        id: 'pledgeDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      pledgeRead: {
        id: 'pledgeRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      pledgeAutocomplete: {
        id: 'pledgeAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      accessListRead: {
        id: 'accessListRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accessListEdit: {
        id: 'accessListEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accessListCreate: {
        id: 'accessListCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accessListImport: {
        id: 'accessListImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      accessListDestroy: {
        id: 'accessListDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      advertisementRead: {
        id: 'advertisementRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      advertisementEdit: {
        id: 'advertisementEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      advertisementCreate: {
        id: 'advertisementCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      advertisementImport: {
        id: 'advertisementImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      advertisementDestroy: {
        id: 'advertisementDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      notificationRead: {
        id: 'notificationRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      notificationEdit: {
        id: 'notificationEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      notificationCreate: {
        id: 'notificationCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      notificationImport: {
        id: 'notificationImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      notificationDestroy: {
        id: 'notificationDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      assistantImport: {
        id: 'assistantImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      assistantCreate: {
        id: 'assistantCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      assistantEdit: {
        id: 'assistantEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      assistantDestroy: {
        id: 'assistantDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      assistantRead: {
        id: 'assistantRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      liquidAssetImport: {
        id: 'liquidAssetImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      liquidAssetCreate: {
        id: 'liquidAssetCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.liquidAssetOthFile,
          storage.liquidAssetOthPhoto,
        ],
      },
      liquidAssetEdit: {
        id: 'liquidAssetEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.liquidAssetOthFile,
          storage.liquidAssetOthPhoto,
        ],
      },
      liquidAssetDestroy: {
        id: 'liquidAssetDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.liquidAssetOthFile,
          storage.liquidAssetOthPhoto,
        ],
      },
      liquidAssetRead: {
        id: 'liquidAssetRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      liquidAssetAutocomplete: {
        id: 'liquidAssetAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      liquidAssetCreateNo: {
        id: 'liquidAssetCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      liquidAssetFile: {
        id: 'liquidAssetFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      liquidAssetFileNo: {
        id: 'liquidAssetFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      liquidAssetPhoto: {
        id: 'liquidAssetPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      liquidAssetPhotoNo: {
        id: 'liquidAssetPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      documentationImport: {
        id: 'documentationImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      documentationCreate: {
        id: 'documentationCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.documentationOthFile],
      },
      documentationEdit: {
        id: 'documentationEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.documentationOthFile],
      },
      documentationDestroy: {
        id: 'documentationDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.documentationOthFile],
      },
      documentationRead: {
        id: 'documentationRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      documentationAutocomplete: {
        id: 'documentationAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      documentationFile: {
        id: 'documentationFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      documentationCreateNo: {
        id: 'documentationCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      documentationFileNo: {
        id: 'documentationFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      healthInfoRead: {
        id: 'healthInfoRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      healthInfoImport: {
        id: 'healthInfoImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      healthInfoCreate: {
        id: 'healthInfoCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.healthInfoProfilePhoto,
          storage.healthInfoDonorDocument,
          storage.healthInfoMedicalInsuranceFile,
          storage.healthInfoContactPhoto,
        ],
      },
      healthInfoEdit: {
        id: 'healthInfoEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.healthInfoProfilePhoto,
          storage.healthInfoDonorDocument,
          storage.healthInfoMedicalInsuranceFile,
          storage.healthInfoContactPhoto,
        ],
      },
      healthInfoDestroy: {
        id: 'healthInfoDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.healthInfoProfilePhoto,
          storage.healthInfoDonorDocument,
          storage.healthInfoMedicalInsuranceFile,
          storage.healthInfoContactPhoto,
        ],
      },
      healthInfoAutocomplete: {
        id: 'healthInfoAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      healthInfoPhoto: {
        id: 'healthInfoPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      healthInfoPhotoNo: {
        id: 'healthInfoPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      healthInfoCreateNo: {
        id: 'healthInfoCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      healthInfoFile: {
        id: 'healthInfoFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      healthInfoFileNo: {
        id: 'healthInfoFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      emergencyCardRead: {
        id: 'emergencyCardRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardImport: {
        id: 'emergencyCardImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardCreate: {
        id: 'emergencyCardCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      emergencyCardEdit: {
        id: 'emergencyCardEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      emergencyCardDestroy: {
        id: 'emergencyCardDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      emergencyCardAutocomplete: {
        id: 'emergencyCardAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardPhoto: {
        id: 'emergencyCardPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardPhotoNo: {
        id: 'emergencyCardPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardCreateNo: {
        id: 'emergencyCardCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardFile: {
        id: 'emergencyCardFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardFileNo: {
        id: 'emergencyCardFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardOrderRead: {
        id: 'emergencyCardOrderRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardOrderCreate: {
        id: 'emergencyCardOrderCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardOrderEdit: {
        id: 'emergencyCardOrderEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      emergencyCardOrderDestroy: {
        id: 'emergencyCardOrderDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      insuranceImport: {
        id: 'insuranceImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      insuranceCreate: {
        id: 'insuranceCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.insuranceInsFile],
      },
      insuranceEdit: {
        id: 'insuranceEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.insuranceInsFile],
      },
      insuranceDestroy: {
        id: 'insuranceDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.insuranceInsFile],
      },
      insuranceRead: {
        id: 'insuranceRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      insuranceAutocomplete: {
        id: 'insuranceAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      insuranceFile: {
        id: 'insuranceFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      insuranceCreateNo: {
        id: 'insuranceCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      insuranceFileNo: {
        id: 'insuranceFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      solicitorImport: {
        id: 'solicitorImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      solicitorCreate: {
        id: 'solicitorCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.solicitorPropPhoto],
      },
      solicitorEdit: {
        id: 'solicitorEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.solicitorPropPhoto],
      },
      solicitorDestroy: {
        id: 'solicitorDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.solicitorPropPhoto],
      },
      solicitorRead: {
        id: 'solicitorRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      solicitorAutocomplete: {
        id: 'solicitorAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      videoAttestationImport: {
        id: 'videoAttestationImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoAttestationCreate: {
        id: 'videoAttestationCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      videoAttestationEdit: {
        id: 'videoAttestationEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      videoAttestationDestroy: {
        id: 'videoAttestationDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [],
      },
      videoAttestationRead: {
        id: 'videoAttestationRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      videoAttestationAutocomplete: {
        id: 'videoAttestationAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      resourcesImport: {
        id: 'resourcesImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      resourcesCreate: {
        id: 'resourcesCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.resourcesPropPhoto,
          storage.resourcesFile,
        ],
      },
      resourcesEdit: {
        id: 'resourcesEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.resourcesPropPhoto,
          storage.resourcesFile,
        ],
      },
      resourcesDestroy: {
        id: 'resourcesDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.resourcesPropPhoto,
          storage.resourcesFile,
        ],
      },
      resourcesRead: {
        id: 'resourcesRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      resourcesAutocomplete: {
        id: 'resourcesAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      vehicleImport: {
        id: 'vehicleImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      vehicleCreate: {
        id: 'vehicleCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.vehiclePropPhoto,
          storage.vehiclePropFiles,
        ],
      },
      vehicleEdit: {
        id: 'vehicleEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.vehiclePropPhoto,
          storage.vehiclePropFiles,
        ],
      },
      vehicleDestroy: {
        id: 'vehicleDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [
          storage.vehiclePropPhoto,
          storage.vehiclePropFiles,
        ],
      },
      vehicleRead: {
        id: 'vehicleRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      vehicleAutocomplete: {
        id: 'vehicleAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      vehicleCreateNo: {
        id: 'vehicleCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      vehicleFile: {
        id: 'vehicleFile',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      vehicleFileNo: {
        id: 'vehicleFileNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      vehiclePhoto: {
        id: 'vehiclePhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      vehiclePhotoNo: {
        id: 'vehiclePhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      planInvitationRead: {
        id: 'planInvitationRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      planInvitationCreate: {
        id: 'planInvitationCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      planInvitationEdit: {
        id: 'planInvitationEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      planInvitationDestroy: {
        id: 'planInvitationDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },

      guardiansRead: {
        id: 'guardiansRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      guardiansCreate: {
        id: 'guardiansCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      guardiansCreateNo: {
        id: 'guardiansCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      guardiansDestroy: {
        id: 'guardiansDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      guardiansEdit: {
        id: 'guardiansEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      financialPlanningRead: {
        id: 'financialPlanningRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      financialPlanningCreate: {
        id: 'financialPlanningCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      financialPlanningCreateNo: {
        id: 'financialPlanningCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      financialPlanningDestroy: {
        id: 'financialPlanningDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      financialPlanningEdit: {
        id: 'financialPlanningEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      paymentHistoryCreate: {
        id: 'paymentHistoryCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      paymentHistoryEdit: {
        id: 'paymentHistoryEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      paymentHistoryDestroy: {
        id: 'paymentHistoryDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      paymentHistoryRead: {
        id: 'paymentHistoryRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      petImport: {
        id: 'petImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      petCreate: {
        id: 'petCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.petPhoto],
      },
      petEdit: {
        id: 'petEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.petPhoto],
      },
      petDestroy: {
        id: 'petDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.petPhoto],
      },
      petRead: {
        id: 'petRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      petAutocomplete: {
        id: 'petAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      petPhoto: {
        id: 'petPhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      petPhotoNo: {
        id: 'petPhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      petCreateNo: {
        id: 'petCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      trusteeImport: {
        id: 'trusteeImport',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      trusteeCreate: {
        id: 'trusteeCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.trusteePhoto],
      },
      trusteeEdit: {
        id: 'trusteeEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.trusteePhoto],
      },
      trusteeDestroy: {
        id: 'trusteeDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
        allowedStorage: [storage.trusteePhoto],
      },
      trusteeRead: {
        id: 'trusteeRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      trusteeAutocomplete: {
        id: 'trusteeAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      trusteeCreateNo: {
        id: 'trusteeCreateNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      trusteePhotoNo: {
        id: 'trusteePhotoNo',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      trusteePhoto: {
        id: 'trusteePhoto',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      funeralDirectivesRead: {
        id: 'funeralDirectivesRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      dependentCreate: {
        id: 'dependentCreate',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      dependentEdit: {
        id: 'dependentEdit',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      dependentDestroy: {
        id: 'dependentDestroy',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      dependentRead: {
        id: 'dependentRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
      dependentAutocomplete: {
        id: 'dependentAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.starter,
          plans.comprehensive,
        ],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
