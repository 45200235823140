import {
  Button,
  //   Checkbox,
  Col,
  //   Divider,
  Form,
  Row,
  Select,
  Steps,
  Typography,
} from 'antd';
import Multitasking from '../../images/welcome/Multitasking.png';
import TeamingUp from '../../images/welcome/Teaming-Up.png';
import Parenting from '../../images/welcome/Parenting.png';
import Puzzle from '../../images/welcome/Puzzle.png';
import { useEffect, useRef, useState } from 'react';
import { i18n } from 'src/i18n';
// import UserSettingsGeneral from '../userSettings/form/UserSettingsGeneral';
import userSettingActions from 'src/modules/userSettings/userSettingsActions';
// import { getHistory } from 'src/modules/store';
import { useDispatch, useSelector } from 'react-redux';
// import SelectFormItem from '../shared/form/items/SelectFormItem';
import { formItemLayout } from '../shared/styles/FormWrapper';
import { isMobile } from 'react-device-detect';
import userSettingSelectors from 'src/modules/userSettings/userSettingsSelectors';
import authActions from 'src/modules/auth/authActions';
import authSelectors from 'src/modules/auth/authSelectors';

const { Step } = Steps;

const optionsEnumerators = {
  religion: ['muslim', 'nonMuslim'],
  language: ['english', 'bahasaMelayu'],
  willMode: ['conventional', 'wasiat'],
};

//disable close function
//skip to back button
const WelcomeScreen = (props) => {
  const dispatch = useDispatch();
  const { setWelcomeScreen, setWelcomeScreenTitle } = props;
  const record =
    useSelector(userSettingSelectors.selectUserSettings) ||
    {};
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [step, setStep] = useState(0);
  const [religion, setReligion] = useState('nonMuslim');
  const [language, setLanguage] = useState('english');
  const [mode, setMode] = useState('conventional');

  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  useEffect(() => {
    setReligion(record?.userSettingsReligion);
    setLanguage(record?.userSettingsLanguage);
    setMode(record?.userSettingsWillMode);
  }, [record]);

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const nextStep = (value?) => {
    if (value || value === 0) {
      setStep(value);
      setWelcomeScreenTitle(
        i18n(
          `dashboard.welcomeModal.screen${value}.screen`,
        ),
      );
    } else if (step === 3) {
      onSubmit();
      setWelcomeScreen(false);
    } else {
      setStep((prev) => prev + 1);
      setWelcomeScreenTitle(
        i18n(`dashboard.welcomeModal.screen${step}.screen`),
      );
    }
  };
  const onDontShowAgainSubmit = () => {
    dispatch(
      authActions.doUpdateProfile({
        ...currentUser,
        welcomeScreenSkip: true,
        noRedirect: true,
      }),
    );
    setDontShowAgain(!dontShowAgain);
  };

  const onSubmit = () => {
    const values = {
      userSettingsReligion: religion,
      userSettingsLanguage: language,
      userSettingsWillMode: mode,
    };
    if (
      record?.userSettingsReligion !== religion ||
      record?.userSettingsLanguage !== language ||
      record?.userSettingsWillMode !== mode
    ) {
      onDontShowAgainSubmit();
      dispatch(userSettingActions.doSave(values));
    } else {
      onDontShowAgainSubmit();
    }
  };

  return (
    <div
      style={{
        height: '100%',
        paddingTop: isMobile ? '5%' : null,
      }}
    >
      <Row>
        <div style={{ textAlign: 'center', flex: 'auto' }}>
          {step === 0 && (
            <img
              src={Parenting}
              style={{ height: '250px' }}
            />
          )}
          {step === 1 && (
            <img
              src={TeamingUp}
              style={{ height: '250px' }}
            />
          )}
          {step === 2 && (
            <img
              src={Multitasking}
              style={{ height: '250px' }}
            />
          )}
          {step === 3 && (
            <img src={Puzzle} style={{ height: '250px' }} />
          )}
        </div>
        <div style={{ textAlign: 'center', flex: 'auto' }}>
          <p
            style={{
              fontWeight: 'bold',
              marginBottom: '0px',
              fontSize: '18px',
            }}
          >
            {i18n(
              `dashboard.welcomeModal.screen${step}.title`,
            )}
          </p>
          <p>
            {i18n(
              `dashboard.welcomeModal.screen${step}.subtitle`,
            )}
          </p>
          <p
            style={{
              paddingLeft: '15%',
              paddingRight: '15%',
              marginTop: '18px',
            }}
          >
            {i18n(
              `dashboard.welcomeModal.screen${step}.description`,
            )}
          </p>
        </div>
        {step === 3 && (
          <div
            style={{
              marginTop: '24px',
              flex: '1 1 auto',
              paddingLeft: '24px',
              paddingRight: '24px',
            }}
          >
            <Row>
              <Col span={8}>
                <Typography.Text strong={true}>
                  {i18n('settings.fields.religion')}
                </Typography.Text>
              </Col>
              <Col span={16}>
                <Select
                  onChange={(value) => {
                    setReligion(value);
                  }}
                  value={religion || ''}
                  style={{ width: '100%' }}
                  options={optionsEnumerators.religion.map(
                    (value) => ({
                      value,
                      label: i18n(
                        `settings.religion.${value}`,
                      ),
                    }),
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <Typography.Text strong={true}>
                  {i18n('settings.fields.language')}
                </Typography.Text>
              </Col>
              <Col span={16}>
                <Select
                  onChange={(value) => {
                    setLanguage(value);
                  }}
                  value={language || ''}
                  style={{ width: '100%' }}
                  options={optionsEnumerators.language.map(
                    (value) => ({
                      value,
                      label: i18n(
                        `settings.language.${value}`,
                      ),
                    }),
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <Typography.Text strong={true}>
                  {i18n('settings.fields.willMode')}
                </Typography.Text>
              </Col>
              <Col span={16}>
                <Select
                  onChange={(value) => {
                    setMode(value);
                  }}
                  value={mode || ''}
                  style={{ width: '100%' }}
                  options={optionsEnumerators.willMode.map(
                    (value) => ({
                      value,
                      label: i18n(
                        `settings.willMode.${value}`,
                      ),
                    }),
                  )}
                />
              </Col>
            </Row>
          </div>
        )}
      </Row>
      <Row style={{ marginTop: '24px' }}>
        {step === 0 && !isMobile && (
          <Col span={isMobile ? 2 : 3} />
        )}
        {step > 0 && step <= 3 && !isMobile && (
          <Col
            span={isMobile ? 1 : 3}
            style={{ textAlign: 'right' }}
          >
            <Button onClick={() => prevStep()}>
              {i18n(`dashboard.back`)}
            </Button>
          </Col>
        )}
        <Col
          span={isMobile ? 20 : 14}
          style={{ marginBottom: isMobile ? '16px' : 0 }}
        >
          <Steps
            progressDot
            current={step}
            onChange={nextStep}
            style={{ marginTop: '11px' }}
          >
            <Step style={{ width: '60px' }} />
            <Step style={{ width: '60px' }} />
            <Step style={{ width: '60px' }} />
            <Step style={{ width: '60px' }} />
          </Steps>
        </Col>
        {!isMobile && (
          <Col span={4} offset={3}>
            <Button
              type="primary"
              onClick={() => nextStep()}
            >
              {step < 3 && i18n(`dashboard.next`)}
              {step === 3 && i18n(`dashboard.complete`)}
            </Button>
          </Col>
        )}
        {isMobile && (
          <>
            <Col span={11} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => prevStep()}
                disabled={step === 0}
              >
                {i18n(`dashboard.back`)}
              </Button>
            </Col>
            <Col span={11} offset={1}>
              <Button
                type="primary"
                onClick={() => nextStep()}
              >
                {step < 3 && i18n(`dashboard.next`)}
                {step === 3 && i18n(`dashboard.complete`)}
              </Button>
            </Col>
          </>
        )}
      </Row>
      {/* <div style={{ marginTop: "24px", marginBottom: "36px" }}>
                <Checkbox style={{ float: "right" }} value={dontShowAgain} onChange={() => onDontShowAgainSubmit()}>{i18n("dashboard.welcomeModal.dontShowAgain")}</Checkbox>
            </div> */}
    </div>
  );
};

export default WelcomeScreen;
