import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.insurance.fields.id'),
  },
  {
    name: 'insType',
    label: i18n('entities.insurance.fields.insType'),
  },
  {
    name: 'insInstitution',
    label: i18n('entities.insurance.fields.insInstitution'),
  },
  {
    name: 'insNumber',
    label: i18n('entities.insurance.fields.insNumber'),
  },
  {
    name: 'insValue',
    label: i18n('entities.insurance.fields.insValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'insCurrency',
    label: i18n('entities.insurance.fields.insCurrency'),
    render: exporterRenders.currency(),
  },
  {
    name: 'insFile',
    label: i18n('entities.insurance.fields.insFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'insFileLocation',
    label: i18n(
      'entities.insurance.fields.insFileLocation',
    ),
  },
  {
    name: 'insRemarks',
    label: i18n('entities.insurance.fields.insRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.insurance.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
];
