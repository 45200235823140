const promotionEnumerators = {
  promType: [
    'Percentage Discount',
    'Fixed Price Discount',
    'Price Cut Discount',
  ],
  promTargetPlan:[
    'free',
    'growth',
    'enterprise',
  ],
  };
  
  export default promotionEnumerators;
  