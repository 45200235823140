// // const backendUrl = `https://kohaappbackend.azurewebsites.net/api`;
// // const backendUrl = `https://devkohabackend.azurewebsites.net/api`;
// // const backendUrl = `http://192.168.50.139:49160/api`;
// // const backendUrl = `http://localhost:49160/api`;
// const backendUrl = `http://192.168.0.8:49160/api`;

// // const backendUrl = `https://devkohabackend.privatelink.azurewebsites.net/api`;

// // SwaggerUI Documentation URL
// // Leave black if documentation should be hidden
// // const apiDocumentationUrl = `https://kohaappbackend.azurewebsites.net/documentation`;
// const apiDocumentationUrl = `https://devkohabackend.azurewebsites.net/documentation`;
// // const apiDocumentationUrl = `https://192.168.50.139:49160/documentation`;
// // const apiDocumentationUrl = `https://localhost:49160/documentation`;
// //  const apiDocumentationUrl = `https://192.168.1.198:49160/documentation`;

// /**
//  * Frontend Url.
//  */
// const frontendUrl = {
//    host: 'localhost:3000',
//    protocol: 'https',
//   // host: 'app.koha.digital',
//   // host: 'devkohafrontend.azurewebsites.net',
//   // protocol: 'https',
// };

// /**
//  * Tenant Mode
//  * multi: Allow new users to create new tenants.
//  * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
//  * single: One tenant, the first user to register will be the admin.
//  */
// const tenantMode = 'multi';

// /**
//  * Plan payments configuration.
//  */
// const isPlanEnabled = true;

// //koha-staging
// const stripePublishableKey =
//   'pk_test_51JcUtKCyTfEg6voiwcVMCxnLvsZu2DIFx3DS3u4IW96QC5iHUDXaQ64OkCUpLRR90sJnMw6fKq5NTymMSCP21NGl00jpZRhIGy';

// //koha-live
// // const stripePublishableKey =
// //   'pk_live_51JcUtKCyTfEg6voi9os6Vb7jFVv9zwVSnerchd7AOu2wQ2hSxF7I0EupBXlM7S7UJiSmhQsRWcb32x5luEyA4RiI00v2ZMXrUW';

// // const stripePublishableKey =
// //   'pk_live_51JcUtKCyTfEg6voi9os6Vb7jFVv9zwVSnerchd7AOu2wQ2hSxF7I0EupBXlM7S7UJiSmhQsRWcb32x5luEyA4RiI00v2ZMXrUW';

// const stripeEmergencyCardOrderPublishableKey =
//   'pk_test_51KgNiXKzxDlPjCeyQ7CGMxnALngDFjFGNIfH70wsuCuYbHMhig93J0uAfcTvY1Q5lsyVpSHBZRj9I4WqzA3IXeRi006dzvTIvE';

// export default {
//   frontendUrl,
//   backendUrl,
//   apiDocumentationUrl,
//   tenantMode,
//   isPlanEnabled,
//   stripePublishableKey,
//   stripeEmergencyCardOrderPublishableKey,
// };

export default { };
