import list from 'src/modules/documentation/list/documentationListReducers';
import form from 'src/modules/documentation/form/documentationFormReducers';
import view from 'src/modules/documentation/view/documentationViewReducers';
import destroy from 'src/modules/documentation/destroy/documentationDestroyReducers';
import importerReducer from 'src/modules/documentation/importer/documentationImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
