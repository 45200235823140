import Pages from 'src/security/page';
import { i18n } from 'src/i18n';

import {
  LockOutlined,
  TeamOutlined,
  IdcardOutlined,
} from '@ant-design/icons';

export default [
  {
    path: '/my-vault',
    icon: <LockOutlined />,
    label: i18n('dashboard.digitalVault.myVault'),
    type: Pages.values.Digital,
    placeholder:false,
  },

  {
    path: '/share-with-me',
    icon: <TeamOutlined />,
    label: i18n('dashboard.digitalVault.sharedWithMeVault'),
    placeholder:false,
  },

  // {
  //   path: '/emergency-card',
  //   icon: <IdcardOutlined />,
  //   label: i18n('dashboard.emergencyCard.title'),
  //   type: Pages.values.EmergencyCard,
  //   placeholder: true,
  // },
].filter(Boolean);
