import Pages from 'src/security/page';
import { i18n } from 'src/i18n';

import {
  FileWordOutlined,
  // FileTextOutlined,
  // FileProtectOutlined,
  // UserOutlined,
} from '@ant-design/icons';
import { getConfig } from 'src/config';

export default [
  {
    // path: '/lastwill',
    path: '/willListPage',
    icon: <FileWordOutlined />,
    label: i18n('dashboard.lastwill.will'),
    type: Pages.values.Property,
    placeholder: false,
  },
  {
    // path: '/lastwill',
    path: '/hibahListPage',
    icon: <FileWordOutlined />,
    label: i18n('app.hibah'),
    type: Pages.values.Property,
    placeholder: false,
    show: 'hibah',
  },
  {
    // path: '/lastwill',
    path: '/hartasepencarianListPage',
    icon: <FileWordOutlined />,
    label: i18n('app.hartaSepencarian'),
    type: Pages.values.Property,
    placeholder: false,
    show: 'harta',
  },
  {
    path: '/assetListPage',
    icon: <FileWordOutlined />,
    label: i18n('app.myEstate'),
  },
  // {
  //   path: '/last-instructions',
  //   icon: <FileWordOutlined />,
  //   label: i18n('app.personalMessages'),
  // },
  getConfig().REACT_APP_BETA_MODE === 'true' && {
    path: '/personal-message',
    icon: <FileWordOutlined />,
    label: i18n('app.personalLetters'),
  },
  getConfig().REACT_APP_BETA_MODE === 'true' && {
    path: '/funeral-directives',
    icon: <FileWordOutlined />,
    label: i18n('app.funeralDirective'),
    show: 'conventional',
  },
  {
    path: '/health-info',
    icon: <FileWordOutlined />,
    label: i18n('app.emergencyCard'),
  },

  // {
  //   path: '/message',
  //   icon: <FileWordOutlined />,
  //   label: i18n('chatGPT.message'),
  // },

  // {
  //   path: '/powerOfAttorney',
  //   icon: <UserOutlined />,
  //   label: i18n('dashboard.powerOfAttorney.title'),
  //   type: Pages.values.Property,
  //   placeholder: false,
  // },

  // {
  //   path: '/trust-deed',
  //   icon: <FileProtectOutlined />,
  //   label: i18n('dashboard.trustDeed.title'),
  //   type: Pages.values.TrustDeed,
  //   placeholder: false,
  // },

  // {
  //   path: '/documentation',
  //   icon: <FileTextOutlined />,
  //   label: i18n('dashboard.othDocument.title'),
  //   placeholder: true,
  // },
].filter(Boolean);
