/**
 * List of Page
 */
class Pages {
  static get values() {
    return {
      Beneficiaries: 'Beneficiaries',
      Executor: 'Executor',
      LegalGuardian: 'LegalGuardian',
      Account: 'Account',
      Cash: 'Cash',
      Debts: 'Debts',
      FinancialPlanning: 'FinancialPlanning',
      Property: 'Property',
      Gifts: 'Gifts',
      Guardians: 'Guardians',
      Residuaries: 'Residuaries',
      OtherAsset: 'OtherAsset',
      Digital: 'Digital',
      LastInstruction: 'LastInstruction',
      PersonalMessage: 'PersonalMessage',
      funeralDirectives: 'funeralDirectives',
      HealthInfo: 'HealthInfo',
      EmergencyCard: 'EmergencyCard',
      EmergencyCardOrder: 'EmergencyCardOrder',
      Documentation: 'Documentation',
      Insurance: 'Insurance',
      ContactList: 'ContactList',
      Pledge: 'Pledge',
      LiquidityAssets: 'LiquidityAssets',
      MyFamily: 'MyFamily',
      AssetCategory: 'AssetCategory',
      Location: 'Location',
      TrustDeed: 'TrustDeed',
      Vehicle: 'Vehicle',
      Pet: 'Pet',
      Trustee: 'Trustee',
      PlanInvitation: 'PlanInvitation',
      Witness: 'Witness',
      SpecificGift: 'SpecificGift',
      Dependent: 'Dependents',
    };
  }
}

export default Pages;
