import { useState, useEffect } from 'react';
import {
  Drawer,
  Button as Button2,
  Form,
  Checkbox,
} from 'antd';
import FormWrapper, {
  formItemLayout,
  tailFormItemLayout,
} from 'src/view/shared/styles/FormWrapper';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import html2canvas from 'html2canvas';
import {
  Container,
  Button,
  Link,
  lightColors,
  darkColors,
} from 'react-floating-action-button';
import TextAreaFormItem from '../shared/form/items/TextAreaFormItem';
import { i18n } from 'src/i18n';
import { isMobile } from 'react-device-detect';
import SaveOutlined from '@ant-design/icons/lib/icons/SaveOutlined';
import selectors from 'src/modules/auth/authSelectors';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import * as yup from 'yup';
// import { FormOutlined } from '@ant-design/icons';
import InputFormItem from '../shared/form/items/InputFormItem';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

// import { QuestionCircleOutlined } from '@ant-design/icons';

const AppTicket = (props) => {
  const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    backgroundColor: darkColors.blue,
    color: lightColors.white,
  };

  const schema = yup.object().shape({
    request_subject: yupFormSchemas.string(
      i18n('ticket.subject'),
      {
        max: 255,
        required: true,
      },
    ),
    request_description: yupFormSchemas.string(
      i18n('ticket.description'),
      {
        //max: 255,
        required: true,
      },
    ),
    screenshotFile: yupFormSchemas.images(
      i18n('ticket.attachment'),
      {
        max: 1,
      },
    ),
  });

  const currentUser = useSelector(
    selectors.selectCurrentUser,
  );

  let [attachFile, setAttachFile] = useState<File>();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.toggle) {
      showDrawer();
    }
  }, [props.toggle]);

  const showDrawer = () => {
    html2canvas(document.body).then(function (canvas) {
      canvas.toBlob((blob) => {
        var file = new File([blob], 'image.png', {
          type: 'application/octet-stream',
        });
        setAttachFile(file);
      });
    });

    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    props.isToggle();
  };

  const form = useForm({});

  const [isCheckedAttach, setIsCheckedAttach] =
    useState(false);
  const onChange = (e) => {
    setIsCheckedAttach(e.target.checked);
  };

  const onSubmit = async (values) => {
    html2canvas(document.body).then(function (canvas) {
      canvas.toBlob((blob) => {
        var file = new File([blob], 'image.png', {
          type: 'application/octet-stream',
        });
        //document.location.href = URL.createObjectURL(file);
        if (
          (attachFile != undefined && isCheckedAttach) ||
          !isCheckedAttach
        ) {
          if (
            values.request_description != null &&
            values.request_description != '' &&
            values.request_subject != null &&
            values.request_subject != ''
          ) {
            const formData = new FormData();

            formData.append(
              'action',
              'wsdesk_api_create_ticket',
            );
            formData.append(
              'api_key',
              '0d579a40c122937db6122200063e23d6',
              // 'ec1feeab63fc360ae6ffa725f22d6335',
            );
            formData.append(
              'request_email',
              currentUser.email,
            );
            formData.append(
              'request_title',
              values.request_subject,
            ); //{window.location.href};
            formData.append(
              'request_description',
              values.request_description,
            );
            formData.append(
              'field_DC01',
              i18n('app.willTestament') === 'Wasiat'
                ? 'Testator_Islamic'
                : 'Testator_Conventional',
            );
            if (
              attachFile != undefined &&
              isCheckedAttach
            ) {
              formData.append('file[1]', attachFile);
            }

            fetch(
              'https://koha.digital/wp-json/wsdesk/v1/wsdesk_api_create_ticket',
              // 'https://koha.digital/wp-admin/admin-ajax.php',
              // 'https://koha.digital/staging/wp-admin/admin-ajax.php',
              {
                method: 'post',
                body: formData,
              },
            )
              .then((res) => res.json())
              .then((result) => {
                // console.log(result);
              })
              .catch((err) => {
                console.log(err);
              });
            handleClickOpenDialog2();
            setVisible(false);
            props.isToggle();
            form.reset();
          } else {
            handleClickOpenDialog3();
          }
        }
      });
    });
  };

  // Dialogs
  // screenshot is cuptured
  const [openDialog1, setOpenDialog1] = useState(false);
  const handleClickOpenDialog1 = () => {
    setOpenDialog1(true);
  };
  const handleCloseDialog1 = () => {
    setOpenDialog1(false);
  };
  // The ticket is submitted successfully
  const [openDialog2, setOpenDialog2] = useState(false);
  const handleClickOpenDialog2 = () => {
    setOpenDialog2(true);
  };
  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };
  // The subject and description are required
  const [openDialog3, setOpenDialog3] = useState(false);
  const handleClickOpenDialog3 = () => {
    setOpenDialog3(true);
  };
  const handleCloseDialog3 = () => {
    setOpenDialog3(false);
  };
  //

  return (
    <Container>
      {/* <Button
          styles={style}
          onClick={showDrawer} 
      > 
      {<FormOutlined />}
      </Button> */}
      <Drawer
        title={i18n('ticket.title')}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={isMobile ? '100%' : '42%'}
      >
        <FormWrapper>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Form.Item
                label={i18n('user.fields.email')}
                {...formItemLayout}
              >
                <strong>{currentUser.email}</strong>
              </Form.Item>

              <InputFormItem
                name="request_subject"
                label={i18n('ticket.subject')}
                hint={i18n('ticket.hint.subject')}
                required={true}
                layout={formItemLayout}
                autoFocus
              />

              <TextAreaFormItem
                name="request_description"
                label={i18n('ticket.description')}
                hint={i18n('ticket.hint.description')}
                required={true}
                layout={formItemLayout}
                autoFocus
              />

              <Form.Item {...tailFormItemLayout}>
                <div>
                  <Checkbox
                    onChange={onChange}
                    checked={isCheckedAttach}
                    //label="Attach"
                  >
                    {i18n('ticket.attacheScreenshot')}
                  </Checkbox>
                </div>
              </Form.Item>

              <Form.Item
                className="form-buttons"
                {...tailFormItemLayout}
              >
                {/* <Button2
                        type="primary"
                        onClick={captureToFile} 
                    > 
                    {i18n('ticket.captureScreenshot')}
                    </Button2> */}
                <Button2
                  type="primary"
                  onClick={form.handleSubmit(onSubmit)}
                  icon={<SaveOutlined />}
                >
                  {i18n('ticket.submit')}
                </Button2>
              </Form.Item>
            </form>
          </FormProvider>
        </FormWrapper>
        {/* Dialogs */}
        <Dialog
          open={openDialog1}
          onClose={handleCloseDialog1}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n('ticket.success')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18n('ticket.captured')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button2
              onClick={handleCloseDialog1}
              type="primary"
            >
              {i18n('common.ok')}
            </Button2>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialog2}
          onClose={handleCloseDialog2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n('ticket.success')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18n('ticket.submittedSuccessfully')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button2
              onClick={handleCloseDialog2}
              type="primary"
            >
              {i18n('common.ok')}
            </Button2>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialog3}
          onClose={handleCloseDialog3}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n('ticket.warning')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18n('ticket.required')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button2
              onClick={handleCloseDialog3}
              type="primary"
            >
              {i18n('common.ok')}
            </Button2>
          </DialogActions>
        </Dialog>
        {/* End dialogs */}
      </Drawer>
    </Container>
  );
};
export default AppTicket;
