import { i18n } from 'src/i18n';
// import {getConfig} from 'src/config';
import {
  // MedicineBoxOutlined,
  TeamOutlined,
  CompassOutlined,
  ApartmentOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { getConfig } from 'src/config';

export default [
  {
    path: '/profile',
    icon: <TeamOutlined />,
    label: i18n('auth.profile.myProfile'),
  },
  {
    path: '/contact-list',
    icon: <CompassOutlined />,
    label: i18n('app.addressBook'),
  },
  {
    path: '/family-tree',
    icon: <ApartmentOutlined />,
    label: i18n('app.familyTree'),
  },
  // {
  //   path: '/my-heir',
  //   icon: <TeamOutlined />,
  //   label: i18n('app.myHeir'),
  //   show: 'wasiat',
  // },
  getConfig().REACT_APP_FARAID_ENABLED === 'true' &&
    localStorage.getItem('willMode') === 'wasiat' && {
      path: '/faraid-calculator',
      icon: <SolutionOutlined />,
      label: i18n('app.faraidCalculator'),
      show: 'wasiat',
    },
  getConfig().REACT_APP_BETA_MODE === 'true' && {
    path: '/financial-planning',
    icon: <SolutionOutlined />,
    label: i18n('app.financialPlanning'),
    // show: 'advisor',
  },
  // {
  //   path: '/health-info',
  //   icon: <MedicineBoxOutlined />,
  //   label: i18n('app.health'),
  // },
].filter(Boolean);
