import listActions from 'src/modules/legalGuardian/list/legalGuardianListActions';
import contactListListActions from 'src/modules/contactList/list/contactListListActions';
import LegalGuardianService from 'src/modules/legalGuardian/legalGuardianService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'LEGALGUARDIAN_DESTROY';

const legalGuardianDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, type?) => async (dispatch) => {
    try {
      dispatch({
        type: legalGuardianDestroyActions.DESTROY_STARTED,
      });

      await LegalGuardianService.destroyAll([id]);

      dispatch({
        type: legalGuardianDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.legalGuardian.destroy.success'),
      );

      switch (type) {
        case 'Contact-List':
          dispatch(
            contactListListActions.doFetchCurrentFilter(),
          );
          getHistory().push('/contact-list');
          break;
        default:
          dispatch(listActions.doFetchCurrentFilter());
          getHistory().push('/contact-list');
          break;
      }
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: legalGuardianDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: legalGuardianDestroyActions.DESTROY_ALL_STARTED,
      });

      await LegalGuardianService.destroyAll(ids);

      dispatch({
        type: legalGuardianDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.legalGuardian.destroyAll.success'),
      );

      getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: legalGuardianDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default legalGuardianDestroyActions;
