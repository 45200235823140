import list from 'src/modules/videoAttestation/list/videoAttestationListReducers';
import form from 'src/modules/videoAttestation/form/videoAttestationFormReducers';
import view from 'src/modules/videoAttestation/view/videoAttestationViewReducers';
import destroy from 'src/modules/videoAttestation/destroy/videoAttestationDestroyReducers';
// import importerReducer from 'src/modules/videoChat/importer/videoChatImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  // importer: importerReducer,
});
