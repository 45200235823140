import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.executor.fields.id'),
  },
  {
    name: 'Executor_1',
    label: i18n('entities.executor.fields.Executor_1'),
  },
  {
    name: 'Executor_2',
    label: i18n('entities.executor.fields.Executor_2'),
  },
  {
    name: 'Executor_3',
    label: i18n('entities.executor.fields.Executor_3'),
  },
  {
    name: 'Executor_4',
    label: i18n('entities.executor.fields.Executor_4'),
  },
  {
    name: 'Currency',
    label: i18n('entities.executor.fields.Currency'),
    render: exporterRenders.currency(),
  },
  {
    name: 'Fee',
    label: i18n('entities.executor.fields.Fee'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'Remarks',
    label: i18n('entities.executor.fields.Remarks'),
  },
  {
    name: 'updatedDate',
    label: i18n('entities.executor.fields.updatedDate'),
    render: exporterRenders.datetime(),
  },
];
