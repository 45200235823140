import invitationService from 'src/modules/invitation/invitationService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'INVITATION_FORM';

const invitationFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: invitationFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await invitationService.find(id);
      }

      dispatch({
        type: invitationFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: invitationFormActions.INIT_ERROR,
      });

      getHistory().push('/legal-guardian');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: invitationFormActions.CREATE_STARTED,
      });

      await invitationService.create(values);

      dispatch({
        type: invitationFormActions.CREATE_SUCCESS,
      });

      // Message.success(
      //   i18n('entities.invitation.create.success'),
      // );

      // getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: invitationFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: invitationFormActions.UPDATE_STARTED,
      });

      await invitationService.update(id, values);

      dispatch({
        type: invitationFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.invitation.update.success'),
      );

      getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: invitationFormActions.UPDATE_ERROR,
      });
    }
  },
  doUpdateMultiple:
    (values, silent) => async (dispatch) => {
      try {
        dispatch({
          type: invitationFormActions.UPDATE_STARTED,
        });
        await values.map((item, index) => {
          let tempData = {
            index: index,
            invitationName: item.invitationName,
            invitationIdentificationType: item.invitationIdentificationType,
            invitationIdentificationNo:
              item.invitationIdentificationNo,
            invitationAddress: item.invitationAddress,
            invitationContactNo1: item.invitationContactNo1,
            invitationContactNo2: item.invitationContactNo2,
            invitationEmail: item.invitationEmail,
            invitationSequence: index,
          };

          invitationService.update(item.id, tempData);
        });

        dispatch({
          type: invitationFormActions.UPDATE_SUCCESS,
        });

        Message.success(
          i18n(
            `entities.invitation.update.${
              silent ? 'silent' : 'success'
            }`,
          ),
        );

        getHistory().push('/legal-guardian');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: invitationFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default invitationFormActions;
