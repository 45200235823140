import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class PlanService {
  static async exclusive_gift(email, tenant_id, plan) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/exclusive_gift`,
      {
        email,
        tenant_id,
        plan,
      },
    );

    return response.data.id;
  }

  static async fetchCheckoutSessionId(
    plan,
    type,
    firstComplete,
  ) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/checkout`,
      {
        plan,
        type,
        firstComplete,
      },
    );

    return response.data.id;
  }

  static async fetchCheckoutPlanSessionId(
    plan,
    extra_plan = [],
    page_from,
    source,
    renew,
  ) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/checkout_plan`,
      {
        plan,
        extra_plan,
        page_from,
        source,
        renew,
      },
    );

    return response.data.id;
  }

  static async update_plan(
    plan,
    session_id,
    actionType,
    mainPackage,
  ) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/update_plan`,
      {
        plan,
        session_id,
        actionType,
        mainPackage,
      },
    );

    return response.data;
  }

  static async update_checkout_plan(plan, source) {
    const tenantId = AuthCurrentTenant.get();
    var tmpTenant = JSON.parse(
      localStorage.getItem('tmpTenant'),
    );
    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/update_checkout_plan`,
      {
        plan,
        tmpid: (tmpTenant ?? ({} as any)).id,
        original_plan: tmpTenant?.plan,
        original_planexpires: tmpTenant?.planExpiresDate,
        source,
      },
    );
    localStorage.setItem('tmpTenant', null);
    return response.data;
  }

  static async fetchPortalUrl() {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/portal`,
    );

    return response.data.url;
  }

  static async upgradePlan(session_id, record) {
    const response = await authAxios.get(
      `/stripe/payment_checkout?session_id=${session_id}&record=${record}`,
    );

    return response.data;
  }
}
