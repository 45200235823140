import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.otherAsset.fields.id'),
  },
  {
    name: 'othCategory',
    label: i18n('entities.otherAsset.fields.othCategory'),
  },
  {
    name: 'othDescription',
    label: i18n(
      'entities.otherAsset.fields.othDescription',
    ),
  },
  {
    name: 'othValue',
    label: i18n('entities.otherAsset.fields.othValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'othCurrency',
    label: i18n('entities.otherAsset.fields.othCurrency'),
    render: exporterRenders.currency(),
  },
  {
    name: 'othLocation',
    label: i18n('entities.otherAsset.fields.othLocation'),
  },
  {
    name: 'othFile',
    label: i18n('entities.otherAsset.fields.othFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'othPhoto',
    label: i18n('entities.otherAsset.fields.othPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'othRemarks',
    label: i18n('entities.otherAsset.fields.othRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.otherAsset.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
];
