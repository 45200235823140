import list from 'src/modules/videoChat/list/videoChatListReducers';
import form from 'src/modules/videoChat/form/videoChatFormReducers';
import view from 'src/modules/videoChat/view/videoChatViewReducers';
import destroy from 'src/modules/videoChat/destroy/videoChatDestroyReducers';
// import importerReducer from 'src/modules/videoChat/importer/videoChatImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  // importer: importerReducer,
});
