import list from 'src/modules/invitation/list/invitationListReducers';
import form from 'src/modules/invitation/form/invitationFormReducers';
import destroy from 'src/modules/invitation/destroy/invitationDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  destroy,
});
