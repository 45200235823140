import list from 'src/modules/liquidAsset/list/liquidAssetListReducers';
import form from 'src/modules/liquidAsset/form/liquidAssetFormReducers';
import view from 'src/modules/liquidAsset/view/liquidAssetViewReducers';
import destroy from 'src/modules/liquidAsset/destroy/liquidAssetDestroyReducers';
import importerReducer from 'src/modules/liquidAsset/importer/liquidAssetImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
