import TenantService from 'src/modules/tenant/tenantService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import authActions from 'src/modules/auth/authActions';

import assetCategoryEnumerators from 'src/modules/assetCategory/assetCategoryEnumerators';
import AssetCategoryService from 'src/modules/assetCategory/assetCategoryService';
import GiftService from 'src/modules/gift/GiftService';
import PlanService from 'src/modules/plan/planService';
import AuthService from 'src/modules/auth/authService';
import guardiansFormActions from 'src/modules/guardians/form/guardiansFormActions';
import petFormActions from 'src/modules/pet/form/petFormActions';
import executorMainActions from 'src/modules/executor/main/executorMainActions';
import residuaryFormActions from 'src/modules/residuary/form/residuaryFormActions';
import witnessMainActions from 'src/modules/witness/main/witnessMainActions';
import globalActions from 'src/modules/global/globalActions';
import { formActionNavigator } from 'src/utils/functions';
// import Plans from 'src/security/plans';

const prefix = 'TENANT_FORM';

const doCreateAssetCategory = async (name, mode, desc) => {
  await AssetCategoryService.create({
    astCategoryName: name,
    astCategoryMode: mode,
    astCategoryDescription: desc,
  });
};

const doCreateAllAssetCategory = async (assetObject) => {
  await AssetCategoryService.createAll(assetObject);
};

const tenantFormActions = {
  RESET: `${prefix}_RESET`,

  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  UPDATE_TENANT_STARTED: `${prefix}_UPDATE_TENANT_STARTED`,
  UPDATE_TENANT_SUCCESS: `${prefix}_UPDATE_TENANT_SUCCESS`,
  UPDATE_TENANT_ERROR: `${prefix}_UPDATE_ETENANT_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: tenantFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);

      let record = {};

      if (isEdit) {
        record = await TenantService.find(id);
      }

      dispatch({
        type: tenantFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tenantFormActions.INIT_ERROR,
      });

      getHistory().push('/tenant');
    }
  },

  doCreate: (values, email) => async (dispatch) => {
    try {
      dispatch({
        type: tenantFormActions.CREATE_STARTED,
      });

      const tenant = await TenantService.create(values);

      let response = await Promise.all([
        GiftService.list(
          { email: `${email}`, plan_activated: false },
          null,
          null,
          null,
        ),
      ]).then((data) => {
        var Is_Binding = false;

        data.forEach((item) => {
          if (item.count > 0) {
            Is_Binding = true;
          }
        });

        return {
          Is_Exclusive_Customer: Is_Binding,
          plan: data[0]?.rows[0]?.plan,
        };
      });

      if (response.Is_Exclusive_Customer) {
        await PlanService.exclusive_gift(
          email,
          tenant.id,
          response.plan,
        );

        tenant.plan = response.plan;
      }

      await dispatch(authActions.doSelectTenant(tenant));

      let assetCategory: any = [];
      // for (const category of assetCategoryEnumerators.astCategory) {
      //   assetCategory.push({
      //     astCategoryName: i18n(
      //       `assetCategory.enumerators.astCategory.${category}`,
      //     ),
      //     astCategoryMode: 'Whole Item',
      //     astCategoryDescription: category,
      //   })
      // }

      // for (const category of assetCategoryEnumerators.astPercentageCategory) {
      //   assetCategory.push({
      //     astCategoryName: i18n(
      //       `assetCategory.enumerators.astCategory.${category}`,
      //     ),
      //     astCategoryMode: 'Percentage',
      //     astCategoryDescription: category,
      //   })
      // }
      // await doCreateAllAssetCategory(assetCategory)
      // assetCategoryEnumerators.astCategory.map(
      //   (category, index) =>
      //     doCreateAssetCategory(
      //       i18n(
      //         `assetCategory.enumerators.astCategory.${category}`,
      //       ),
      //       'Whole Item',
      //       category,
      //     ),
      // );

      // assetCategoryEnumerators.astPercentageCategory.map(
      //   (category, index) =>
      //     doCreateAssetCategory(
      //       i18n(
      //         `assetCategory.enumerators.astCategory.${category}`,
      //       ),
      //       'Percentage',
      //       category,
      //     ),
      // );

      dispatch({
        type: tenantFormActions.CREATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tenantFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: tenantFormActions.UPDATE_STARTED,
      });

      const tenant = await TenantService.update(id, values);

      dispatch({
        type: tenantFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('tenant.update.success'));
      await dispatch(authActions.doSelectTenant(tenant));
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tenantFormActions.UPDATE_ERROR,
      });
    }
  },

  do_Update_Tenant_Data:
    (values) => async (dispatch, getState) => {
      try {
        var startActionType =
          tenantFormActions.UPDATE_STARTED;
        var endActionType =
          tenantFormActions.UPDATE_SUCCESS;
        var errActionType = tenantFormActions.UPDATE_ERROR;

        switch (values.record_type) {
          case 'Guardian': {
            startActionType =
              guardiansFormActions.UPDATE_STARTED;
            endActionType =
              guardiansFormActions.UPDATE_SUCCESS;
            errActionType =
              guardiansFormActions.UPDATE_ERROR;
            break;
          }
          case 'Pets': {
            startActionType = petFormActions.UPDATE_STARTED;
            endActionType = petFormActions.UPDATE_SUCCESS;
            errActionType = petFormActions.UPDATE_ERROR;
            break;
          }
          case 'Executor': {
            startActionType =
              executorMainActions.UPDATE_STARTED;
            endActionType =
              executorMainActions.UPDATE_SUCCESS;
            errActionType =
              executorMainActions.UPDATE_ERROR;
            break;
          }
          case 'Witness': {
            startActionType =
              witnessMainActions.UPDATE_STARTED;
            endActionType =
              witnessMainActions.UPDATE_SUCCESS;
            errActionType = witnessMainActions.UPDATE_ERROR;
            break;
          }
          case 'Residuary': {
            startActionType =
              residuaryFormActions.UPDATE_STARTED;
            endActionType =
              residuaryFormActions.UPDATE_SUCCESS;
            errActionType =
              residuaryFormActions.UPDATE_ERROR;
            break;
          }
          case 'useWasiatForHibah': {
            startActionType =
              tenantFormActions.UPDATE_TENANT_STARTED;
            endActionType =
              tenantFormActions.UPDATE_TENANT_SUCCESS;
            errActionType =
              tenantFormActions.UPDATE_TENANT_ERROR;
            break;
          }
        }

        dispatch({
          type: startActionType,
          // type: tenantFormActions.UPDATE_STARTED,
        });

        const record =
          await TenantService.Update_Tenant_Data(values);

        if (values.record_type === 'Contact_List') {
          if (values.record_from) {
            formActionNavigator('beneficiary', 'home');
          } else {
            getHistory().push('/contact-list');
          }
        } else if (values.record_type === 'Beneficiary') {
          formActionNavigator('beneficiary', 'home');
        }
        // else if(values.record_type === 'Executor'){
        //   getHistory().push('/executors/new');
        // }
        // else if(values.record_type === 'Guardians'){
        //   getHistory().push('/legal-guardian/new');
        // }
        // else if(values.record_type === 'Residuary'){
        //   getHistory().push('/residuary/new');
        // }
        else if (values.record_type === 'Pets') {
          getHistory().push('/pet');
        }
        // else if(values.record_type === 'Witness'){
        //   getHistory().push('/witnesss/new');
        // }
        else if (values.record_type === 'Pledges') {
          getHistory().push('/pledge');
        } else if (
          values.record_type === 'Last_Instruction'
        ) {
          getHistory().push('/last-instructions');
        } else if (
          values.record_type === 'Funeral_Directives'
        ) {
          getHistory().push('/funeral-directives');
        } else if (
          values.record_type === 'Personal_Message'
        ) {
          getHistory().push('/personal-message');
        }

        const currentUser = await AuthService.fetchMe();
        dispatch(globalActions.doEditForm());
        dispatch({
          type: authActions.CURRENT_USER_REFRESH_SUCCESS,
          payload: {
            currentUser,
          },
        });

        dispatch({
          type: endActionType,
          // type: tenantFormActions.UPDATE_SUCCESS,
          payload: {
            tenant: record,
          },
        });

        Message.success(i18n('tenant.update.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: errActionType,
          // type: tenantFormActions.UPDATE_ERROR,
        });
      }
    },

  do_Delete_Tenant_Data:
    (values) => async (dispatch, getState) => {
      try {
        dispatch({
          type: tenantFormActions.UPDATE_STARTED,
        });

        await TenantService.Delete_Tenant_Data(values);

        const currentUser = await AuthService.fetchMe();

        dispatch({
          type: authActions.CURRENT_USER_REFRESH_SUCCESS,
          payload: {
            currentUser,
          },
        });

        dispatch({
          type: tenantFormActions.UPDATE_SUCCESS,
        });

        Message.success(i18n('tenant.destroy.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: tenantFormActions.UPDATE_ERROR,
        });
      }
    },

  do_Tenant_Update_Specific_Data:
    (values) => async (dispatch, getState) => {
      try {
        dispatch({
          type: tenantFormActions.UPDATE_STARTED,
        });

        await TenantService.Update_Tenant_Specific_Data(
          values,
        );

        dispatch({
          type: tenantFormActions.UPDATE_SUCCESS,
        });

        Message.success(i18n('willSettings.save.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: tenantFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default tenantFormActions;
