import ResiduaryService from 'src/modules/residuary/residuaryService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import TenantService from 'src/modules/tenant/tenantService';
import globalActions from 'src/modules/global/globalActions';

const prefix = 'RESIDUARY_FORM';

const residuaryFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: residuaryFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        // record = await ResiduaryService.find(id);
        // record = undefined;
        record = await TenantService.tenant_List(
          'Residuary',
          null,
          null,
          null,
          null,
        );
        record = record['rows'][0];
      }

      // let haveRecord = await ResiduaryService.list(
      //   null,
      //   null,
      //   null,
      //   null,
      // );

      let haveRecord = await TenantService.tenant_List(
        'Residuary',
        null,
        null,
        null,
        null,
      );

      dispatch({
        type: residuaryFormActions.INIT_SUCCESS,
        payload: record,
      });

      //block user to create 2nd row
      haveRecord &&
        haveRecord.count >= 1 &&
        getHistory().push(
          `/residuary/${haveRecord.rows[0].id}/edit`,
        );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: residuaryFormActions.INIT_ERROR,
      });

      getHistory().push('/willListPage');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: residuaryFormActions.CREATE_STARTED,
      });

      let rec = await ResiduaryService.create(values);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: residuaryFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.residuary.create.success'),
      );

      getHistory().push(`/residuary/${rec.id}/edit`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: residuaryFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: residuaryFormActions.UPDATE_STARTED,
      });

      await ResiduaryService.update(id, values);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: residuaryFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.residuary.update.success'),
      );

      // getHistory().push(`/residuary/${id}/edit`);
      getHistory().push('/willListPage');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: residuaryFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default residuaryFormActions;
