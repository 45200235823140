import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.residuary.fields.id'),
  },
  {
    name: 'resBen',
    label: i18n('entities.residuary.fields.resBen'),
    render: exporterRenders.json(),
  },
  {
    name: 'resBen2DistributeMode',
    label: i18n(
      'entities.residuary.fields.resBen2DistributeMode',
    ),
  },
  {
    name: 'resBen2',
    label: i18n('entities.residuary.fields.resBen2'),
    render: exporterRenders.json(),
  },
  {
    name: 'resBen3CharityCheck',
    label: i18n(
      'entities.residuary.fields.resBen3CharityCheck',
    ),
  },
  {
    name: 'resBen3Charity',
    label: i18n('entities.residuary.fields.resBen3Charity'),
  },
  {
    name: 'resBen3',
    label: i18n('entities.residuary.fields.resBen3'),
    render: exporterRenders.json(),
  },
  // {
  //   name: 'Inherit_Age',
  //   label: i18n('entities.residuary.fields.Inherit_Age'),
  //   render: exporterRenders.integer(),
  // },
  {
    name: 'resRemarks',
    label: i18n('entities.residuary.fields.resRemarks'),
  },
  {
    name: 'updatedDate',
    label: i18n('entities.residuary.fields.updatedDate'),
    render: exporterRenders.datetime(),
  },
];
