import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from '../auth/authCurrentTenant';

export default class AppReleaseNoteService {
  static async list(filter) {
    const params = {
      filter
    };
    const response = await authAxios.get(
      `appReleaseNote`,
      {
        params,
      },
    );

    return response.data;
  }
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/appReleaseNote/updateStatus`,
      body,
    );

    return response.data;
  }
}
