import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.pledge.fields.id'),
  },
  {
    name: 'orgCategory',
    label: i18n('entities.pledge.fields.orgCategory'),
  },
  {
    name: 'orgName',
    label: i18n('entities.pledge.fields.orgName'),
  },
  {
    name: 'orgAccountNo',
    label: i18n('entities.pledge.fields.orgAccountNo'),
  },
  {
    name: 'anonymousMode',
    label: i18n('entities.pledge.fields.anonymousMode'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'currency',
    label: i18n('entities.pledge.fields.currency'),
    render: exporterRenders.currency(),
  },
  {
    name: 'amount',
    label: i18n('entities.pledge.fields.amount'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'remarks',
    label: i18n('entities.pledge.fields.remarks'),
  },
  {
    name: 'createdDate',
    label: i18n('entities.pledge.fields.createdDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedDate',
    label: i18n('entities.pledge.fields.updatedDate'),
    render: exporterRenders.datetime(),
  },
];
