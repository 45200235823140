import { convertSettingsToLanguageCode, getLanguageCode } from 'src/i18n';
import actions from 'src/modules/global/globalActions';
import languageActions from 'src/modules/layout/layoutActions';
import EncryptionTool from 'src/modules/encryption/dataEncryption';

const initialData = {
  initLoading: false,
  saveLoading: false,
  global: null,
  isEditForm: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      initLoading: true,
    };
  }

  if (type === actions.EDITFORM_SUCCESS) {
    localStorage.setItem(
      'isEditForm',
      EncryptionTool.encrypt(JSON.stringify(payload.isEdit)),
    );
    return {
      ...state,
      isEditForm: payload.isEdit,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    let reload = false
    if (convertSettingsToLanguageCode(payload.globalLanguage) !== getLanguageCode()) {
      languageActions.doChangeLanguage(convertSettingsToLanguageCode(payload.globalLanguage))
      reload = true
    }
    if (payload.globalWillMode && localStorage.getItem("willMode") !== payload.globalWillMode) {
      localStorage.setItem('willMode', payload.globalWillMode);
      reload = true
    }
    if (reload) {
      window.location.reload();
    }
    return {
      ...state,
      global: payload,
      initLoading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      global: null,
      initLoading: false,
    };
  }

  if (type === actions.SAVE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.SAVE_SUCCESS) {
    let languageID = convertSettingsToLanguageCode(payload.globalLanguage)
    if (languageID !== getLanguageCode()) languageActions.doChangeLanguage(languageID)
    if (payload.globalWillMode) localStorage.setItem('willMode', payload.globalWillMode)
    window.location.reload();
    return {
      ...state,
      global: payload,
      saveLoading: false,
    };
  }

  if (type === actions.SAVE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};
