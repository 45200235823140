import Permissions from 'src/security/permissions';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import accessListService from 'src/modules/accessList/accessListService';

const doAccessListRequest = async () => {
    await accessListService.listAll(null, null, null, null)
        .then((data) => {
            AuthCurrentTenant.setAccessList(data.rows);
        });
}

const matchAccessList = (permission, type, currentTenantPlan) => {
    var accesslists = AuthCurrentTenant.getAccessList();
    accesslists = accesslists?.data ?? null;

    var result = null;
    if (accesslists) {
        accesslists.forEach((accessList) => {
            if (accessList.planPackage.toString().trim().toLowerCase() === currentTenantPlan.toString().trim().toLowerCase()) {
                result = planMatchDB(permission, type, accessList);
            }
        })
    }
    return result;
}

const planMatchDB = (permission, type, row) => {
    // console.log("permission.id", permission.id)
    // console.log("type", type)
    // console.log("row", row)

    var result = ((permission.id.indexOf("No") !== -1) ? 0 : false);
    Object.keys(Permissions.values).forEach(function (key) {
        var access = Permissions.values[key];

        if (permission.id === access.id) {
            var data = row[key];

            if (permission.id.indexOf("No") !== -1)
                data = (data === undefined || data === null) ? 0 : data;
            else
                data = (data === undefined || data === null) ? false : data;


            result = data;
        }

    });

    return result;
}

export {
    doAccessListRequest,
    matchAccessList
}