import BeneficiaryService from 'src/modules/beneficiary/beneficiaryService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import GuardiansService from 'src/modules/guardians/guardiansService';
import { formActionNavigator } from 'src/utils/functions';

const prefix = 'BENEFICIARY_FORM';

const doLegalGuardian = async (child_id, guardian_id) => {
  var guardians_list = await GuardiansService.list(
    { Child_Beneficiary: child_id },
    null,
    null,
    null,
  );

  if (guardians_list.count === 0 && guardian_id) {
    await GuardiansService.create({
      Child_Beneficiary: child_id,
      Guardian_1: guardian_id,
    });
  } else if (guardians_list.count > 0 && guardian_id) {
    var guardians = guardians_list.rows[0];
    guardians.Guardian_1 = guardian_id;

    await GuardiansService.update(guardians.id, guardians);
  } else if (guardians_list.count > 0) {
    var guardians = guardians_list.rows[0];

    await GuardiansService.destroyAll([guardians.id]);
  }
};

const beneficiaryFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: beneficiaryFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await BeneficiaryService.find(id);
      }

      dispatch({
        type: beneficiaryFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: beneficiaryFormActions.INIT_ERROR,
      });

      formActionNavigator('beneficiary', 'home');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: beneficiaryFormActions.CREATE_STARTED,
      });
      await BeneficiaryService.create(values);

      dispatch({
        type: beneficiaryFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.beneficiary.create.success'),
      );

      formActionNavigator('beneficiary', 'home');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: beneficiaryFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: beneficiaryFormActions.UPDATE_STARTED,
      });

      await BeneficiaryService.update(id, values);

      dispatch({
        type: beneficiaryFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.beneficiary.update.success'),
      );

      formActionNavigator('beneficiary', 'home');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: beneficiaryFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default beneficiaryFormActions;
