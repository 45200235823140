import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import admzip from 'adm-zip';
import axios from 'axios';

export const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const EXCEL_TYPE_WITH_CHARSET = `${EXCEL_TYPE};charset=UTF-8`;
export const EXCEL_EXTENSION = '.xlsx';

export const ZIP_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const ZIP_TYPE_WITH_CHARSET = `${ZIP_TYPE};charset=UTF-8`;
export const ZIP_EXTENSION = '.zip';

export class Excel {
  static exportAsExcelFile(json, header, fileName) {
    let worksheet = XLSX.utils.json_to_sheet(json, {
      header,
      skipHeader: false,
    });

    let workbook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };

    let excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, fileName);
  }

  static async saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], {
      type: EXCEL_TYPE_WITH_CHARSET,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  static exportMultipleAsFile(rows, fileName, bufferList) {
    let workbook = {
      Sheets: {},
      SheetNames: [],
    };

    rows.forEach((item) => {
      let worksheet = XLSX.utils.json_to_sheet(item.data, {
        header: item.labels,
        skipHeader: false,
      });

      workbook.Sheets[item.title] = worksheet;
      workbook.SheetNames.push(item.title);
    });

    let excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsMultipleFile(
      excelBuffer,
      fileName,
      bufferList,
    );
  }

  static async saveAsMultipleFile(
    buffer,
    fileName,
    bufferList,
  ) {
    const data = new Blob([buffer], {
      type: EXCEL_TYPE_WITH_CHARSET,
    });
    // FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);

    let zipfile = new admzip();
    zipfile.addFile(
      fileName + EXCEL_EXTENSION,
      Buffer.from(await data.arrayBuffer()),
    );

    for (var i = 0; i < bufferList.length; i++) {
      var item = bufferList[i];

      const response = await axios.get(item.url, {
        responseType: 'arraybuffer',
      });

      zipfile.addFile(
        item.type + '/' + item.title + '/' + item.name,
        Buffer.from(response.data),
      );
    }

    const zipBuffer = zipfile.toBuffer();
    const tmpdata = new Blob([zipBuffer], {
      type: ZIP_TYPE_WITH_CHARSET,
    });
    FileSaver.saveAs(
      tmpdata,
      'koha_account_' +
        new Date().getTime() +
        ZIP_EXTENSION,
    );
  }
}
