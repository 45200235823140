import { Menu, Layout, Drawer, Button } from 'antd';
// import { Link } from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import { useSelector, useDispatch } from 'react-redux';
// import PermissionChecker from 'src/modules/auth/permissionChecker';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';
import { i18n } from 'src/i18n';
import { Typography } from '@material-ui/core';
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  // FileWordOutlined,
  TeamOutlined,
  UserOutlined,
  HomeOutlined,
  // VideoCameraOutlined,
  // BankOutlined,
  FileTextOutlined,
  LockOutlined,
  BookOutlined,
  // RiseOutlined,
} from '@ant-design/icons';
import SiderWrapper from 'src/view/layout/styles/SiderWrapper';

import Snackbar from '@material-ui/core/Snackbar';

import submenus_assets from 'src/view/dashboard/subMenu/submenus_assets';
import submenus_documents from 'src/view/dashboard/subMenu/submenus_documents';
import submenus_ownership_updated from 'src/view/dashboard/subMenu/submenus_ownership_updated';
import submenus_ownership from 'src/view/dashboard/subMenu/submenus_ownership';
import submenus_digitalVault from 'src/view/dashboard/subMenu/submenus_digitalVault';
import submenus_digitalVault_assistant from '../dashboard/subMenu/submenus_digitalVault_assistant';
// import submenus_reports from 'src/view/dashboard/subMenu/submenus_reports';

import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import assistantService from 'src/modules/assistant/assistantService';
import { Alert } from '@mui/material';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authActions from 'src/modules/auth/authActions';
import onNavigate from 'src/modules/shared/navigation/navigationHandler';

const { Sider } = Layout;

const AppMenu = (props) => {
  const dispatch = useDispatch();

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );

  const cUserPlan = currentTenant?.plan
    ? currentTenant.plan
    : 'free';

  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );
  const logoUrl = useSelector(
    authSelectors.selectMenuLogoUrl,
  );

  const isComprehensive = currentTenant?.plan === "comprehensive";
  const isPaidHibah = currentTenant?.Paid_Hibah ?? false;
  const isPaidHibahRevocation = currentTenant?.Paid_HibahRevocation ?? false;
  const isPaidHartaSepencarian = currentTenant?.Paid_HartaSepencarian ?? false;
  const isValidFromSubscription = (isComprehensive || isPaidHibah || isPaidHibahRevocation || isPaidHartaSepencarian) ?? false;


  const [snackOpen, setSnackOpen] = useState(false);

  //This is the all collapsible menu in dashboard
  const rootSubmenuKeys = [
    i18n('app.meAndMyCircle'),
    i18n('app.people'),
    // i18n('app.own'),
    i18n('app.willDetails'),
    i18n('app.funeralDirectives'),
    i18n('app.importantDocument'),
    i18n('app.sharing'),
    i18n('reports.title'),
  ];

  const defaultOpenKeys = [i18n('app.meAndMyCircle')];

  const [openKeys, setOpenKeys] = useState([
    i18n('app.meAndMyCircle'),
  ]);
  const [selectedKeys, setSelectedKeys] = useState(null);

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  //Close other collapsible menus when one is selected
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => openKeys.indexOf(key) === -1,
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  //After redirecting to another page, this function will pinpoint the selected menu using url and display the correct collapsible menu
  const checkSelectedKeys = () => {
    const url = props.url;
    const match = menus.find((option) => {
      if (option.exact) {
        return url === option.path;
      }

      return (
        url === option.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      setSelectedKeys(match.path);
      var showOpenKeys = Array.isArray(match.openKeys)
        ? match.openKeys
        : [match.openKeys];

      setOpenKeys(match.openKeys ? showOpenKeys : []);
      // setOpenKeys(match.openKeys ? [match.openKeys] : []);
    }
  };

  // const permissionChecker = new PermissionChecker(
  //   currentTenant,
  //   currentUser,
  // );

  const hideMenu = () => {
    dispatch(actions.doHideMenu());
  };

  // const match = (permission) => {
  //   return permissionChecker.match(permission);
  // };

  useEffect(() => {
    hideMenu();
    checkSelectedKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Assistant
  const [isAdmin, setIsAdmin] = useState(false);
  const [tenantName, setTenantName] = useState('');
  const [
    assistantHasAccessRight,
    setAssistantHasAccessRight,
  ] = useState(false);

  useEffect(() => {
    async function fetchPermission() {
      if (
        currentTenant['createdBy'] === currentUser['id']
      ) {
        setIsAdmin(true);
        setAssistantHasAccessRight(true);
      } else {
        setIsAdmin(false);

        let assistant =
          await assistantService.findByAssistantEmail(
            currentUser['email'],
          );

        let accessRightVal = assistant['accessRight'];
        setAssistantHasAccessRight(accessRightVal);

        setTenantName(currentTenant['name']);
        handleSnackOpen();
      }
    }
    fetchPermission();
  }, [isAdmin, tenantName, assistantHasAccessRight]);

  const isWasiatWillMode =
    AuthCurrentTenant.getWillMode() === 'wasiat' ?? false;

  const closeSharingControl = () => {
    dispatch(authActions.doResetTenant(currentUser));
  };

  const menuTree = () => {
    return (
      assistantHasAccessRight && (
        <Menu
          theme="light"
          mode="inline"
          defaultOpenKeys={defaultOpenKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          style={{
            padding: '2%',
            height: '100vh',
            overflowY: 'scroll',
            paddingBottom: 100,
          }}
        >
          <Menu.Item
            onClick={() => onNavigate('/', cUserPlan)}
            key="/"
            className="navi-color"
          >
            <HomeOutlined />
            {i18n('app.dashboard')}
          </Menu.Item>

          {isAdmin && (
            <SubMenu
              key={i18n('app.meAndMyCircle')}
              title={i18n('app.meAndMyCircle')}
              icon={<UserOutlined />}
              className="navi-color"
            >
              {submenus_ownership
                .filter((menu) => {
                  if (menu.show === 'advisor') {
                    if (
                      isWasiatWillMode &&
                      menu.path === '/financial-planning'
                    )
                      return false;
                    if (
                      currentTenant.Paid_Advisory === true
                    )
                      return true;
                    else return false;
                  }

                  if (!isWasiatWillMode)
                    return menu.show !== 'wasiat';
                  else return menu.show !== 'conventional';
                })
                .map((menu) => (
                  <Menu.Item
                    key={menu.path}
                    onClick={() =>
                      onNavigate(menu.path, cUserPlan)
                    }
                  >
                    {menu.icon}
                    <span className="navi-color">
                      {menu.label}
                    </span>
                  </Menu.Item>
                ))}
            </SubMenu>
          )}

          {!isAdmin && (
            <SubMenu
              key={i18n('app.meAndMyCircle')}
              title={i18n('app.meAndMyCircle')}
              icon={<TeamOutlined />}
              className="navi-color"
            >
              {submenus_ownership_updated.map((menu) => (
                <Menu.Item
                  key={menu.path}
                  onClick={() =>
                    onNavigate(menu.path, cUserPlan)
                  }
                >
                  {menu.icon}
                  <span className="navi-color">
                    {menu.label}
                  </span>
                </Menu.Item>
              ))}
            </SubMenu>
          )}

          <SubMenu
            key={i18n('app.importantDocument')}
            title={i18n('app.importantDocument')}
            icon={<FileTextOutlined />}
            className="navi-color"
          >
            {submenus_documents
              .filter((menu) => {

                if (menu.show === "hibah")
                  return isPaidHibah || isPaidHibahRevocation
                else if (menu.show === "harta")
                  return isPaidHartaSepencarian

                return true;
                // if (!isWasiatWillMode)
                //   return menu.show !== 'wasiat';
                // else return menu.show !== 'conventional';
              })
              .map((menu) => {
                return menu.path === '/assetListPage' ? (
                  <SubMenu
                    // key={'/assetListPage'}
                    key={i18n('app.myEstate')}
                    title={menu.label}
                    icon={menu.icon}
                    className="navi-color"
                    onTitleClick={() =>
                      onNavigate(
                        '/assetListPage',
                        cUserPlan,
                        isValidFromSubscription,
                      )
                    }
                  >
                    {submenus_assets
                      .filter((x) => {
                        return x.visible === true;
                      })
                      .map((menu) => (
                        <Menu.Item
                          key={menu.path}
                          onClick={() =>
                            onNavigate(menu.path, cUserPlan, isValidFromSubscription)
                          }
                        >
                          {menu.icon}
                          <span className="navi-color">
                            {menu.label}
                          </span>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key={menu.path}
                    onClick={() =>
                      onNavigate(menu.path, cUserPlan, isValidFromSubscription)
                    }
                  >
                    {menu.icon}
                    <span className="navi-color">
                      {menu.label}
                    </span>
                  </Menu.Item>
                );
              })}
          </SubMenu>

          <SubMenu
            key={i18n('app.sharing')}
            title={i18n('app.sharing')}
            icon={<LockOutlined />}
            className="navi-color"
          >
            {isAdmin &&
              submenus_digitalVault.map((menu) => (
                <Menu.Item
                  key={menu.path}
                  onClick={() =>
                    onNavigate(menu.path, cUserPlan)
                  }
                >
                  {menu.icon}
                  <span className="navi-color">
                    {menu.label}
                  </span>
                </Menu.Item>
              ))}

            {!isAdmin &&
              submenus_digitalVault_assistant.map(
                (menu) => (
                  <Menu.Item
                    key={menu.path}
                    onClick={() =>
                      onNavigate(menu.path, cUserPlan)
                    }
                  >
                    {menu.icon}
                    <span className="navi-color">
                      {menu.label}
                    </span>
                  </Menu.Item>
                ),
              )}
          </SubMenu>

          {/* 220517 - temporary removed business and video attestation */}
          {/* <Menu.Item
            onClick={doNavigateToVideoChat}
            key="/videoChat"
            className='navi-color'
          >
            <VideoCameraOutlined />
            {i18n('app.videoattestation')}
          </Menu.Item> */}

          {/* <Menu.Item
            onClick={doNavigateTovideoAttestation}
            key="videoChat"
            style={{ fontWeight: "bold" }}
          >
            <VideoCameraOutlined />
            {i18n('app.videoattestation')}
          </Menu.Item> */}

          <Menu.Item
            onClick={() =>
              onNavigate('/resources', cUserPlan)
            }
            key="/learnMore"
            className="navi-color"
          >
            <BookOutlined />
            {i18n('app.resources')}
          </Menu.Item>
        </Menu>
      )
    );
  };

  return (
    <>
      {/* Desktop Version */}
      {!isMobile && (
        <SiderWrapper
          style={{
            display: 'block',
          }}
        >
          <Sider
            theme="light"
            trigger={null}
            width={300}
            style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
          >
            {menuTree()}
          </Sider>
        </SiderWrapper>
      )}

      {/* Mobile Version */}
      {isMobile && assistantHasAccessRight && (
        <Drawer
          placement={isMobile ? 'bottom' : 'left'}
          onClose={hideMenu}
          visible={menuVisible}
          height={isMobile ? '80%' : '100%'}
          width={isMobile ? '100%' : '280px'}
        >
          <div
            style={{ height: '40px' }}
            onClick={() => onNavigate('/', cUserPlan)}
          >
            {logoUrl ? (
              <img
                src={logoUrl}
                width="100px"
                alt={i18n('app.title')}
              />
            ) : (
              <a>
                <h2>{i18n('app.title')}</h2>
              </a>
            )}
          </div>
          {menuTree()}
        </Drawer>
      )}

      <Snackbar open={snackOpen} onClose={handleSnackClose}>
        <Alert severity="info" onClose={handleSnackClose}>
          {i18n('assistant.accessing') +
            tenantName +
            i18n('assistant.portal')}
          <br />
          <div style={{ textAlign: 'center' }}>
            <Button
              type="link"
              onClick={closeSharingControl}
            >
              <Typography
                style={{ textDecoration: 'underline' }}
                display="inline"
              >
                {i18n('assistant.unaccessing')}
              </Typography>
            </Button>
          </div>
        </Alert>
      </Snackbar>
    </>
  );
};

export default AppMenu;
