import SpecificGiftService from 'src/modules/specificGift/specificGiftService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import globalActions from 'src/modules/global/globalActions';
import { formActionNavigator } from 'src/utils/functions';

const prefix = 'SPECIFIC_GIFT_MAIN';

const specificGiftMainActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInitMain: (id) => async (dispatch) => {
    try {
      dispatch({
        type: specificGiftMainActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await SpecificGiftService.find(id);
      }

      dispatch({
        type: specificGiftMainActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: specificGiftMainActions.INIT_ERROR,
      });

      formActionNavigator('specific-gift', 'home');
    }
  },

  doCreateMain: (values) => async (dispatch) => {
    try {
      dispatch({
        type: specificGiftMainActions.CREATE_STARTED,
      });

      await SpecificGiftService.create(values);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: specificGiftMainActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.specificGift.update.success'),
      );

      formActionNavigator('specific-gift', 'home');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: specificGiftMainActions.CREATE_ERROR,
      });
    }
  },

  doUpdateMain:
    (id, values) => async (dispatch, getState) => {
      try {
        dispatch({
          type: specificGiftMainActions.UPDATE_STARTED,
        });

        await SpecificGiftService.update(id, values);
        dispatch(globalActions.doEditForm());
        dispatch({
          type: specificGiftMainActions.UPDATE_SUCCESS,
        });

        Message.success(
          i18n('entities.specificGift.update.success'),
        );

        formActionNavigator('specific-gift', 'home');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: specificGiftMainActions.UPDATE_ERROR,
        });
      }
    },
};

export default specificGiftMainActions;
