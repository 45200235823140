import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.lastInstructions.fields.id'),
  },
  {
    name: 'instructionType',
    label: i18n(
      'entities.lastInstructions.fields.instructionType',
    ),
  },
  {
    name: 'instructionReligion',
    label: i18n(
      'entities.lastInstructions.fields.instructionReligion',
    ),
  },
  {
    name: 'instructions',
    label: i18n(
      'entities.lastInstructions.fields.instructions',
    ),
  },
  {
    name: 'instructionAttentionTo',
    label: i18n(
      'entities.lastInstructions.fields.instructionAttentionTo',
    ),
  },
  {
    name: 'instructionText',
    label: i18n(
      'entities.lastInstructions.fields.instructionText',
    ),
  },
  {
    name: 'isShowMessageInWill',
    label: i18n(
      'entities.lastInstructions.fields.isShowMessageInWill',
    ),
  },
  {
    name: 'instructionFile',
    label: i18n(
      'entities.lastInstructions.fields.instructionFile',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdDate',
    label: i18n(
      'entities.lastInstructions.fields.createdDate',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedDate',
    label: i18n(
      'entities.lastInstructions.fields.updatedDate',
    ),
    render: exporterRenders.datetime(),
  },
];
