import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.contactList.fields.id'),
  },
  {
    name: 'contactListRole',
    label: i18n(
      'entities.contactList.fields.contactListRole',
    ),
  },
  {
    name: 'contactListPhoto',
    label: i18n(
      'entities.contactList.fields.contactListPhoto',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'contactListName',
    label: i18n(
      'entities.contactList.fields.contactListName',
    ),
  },
  {
    name: 'contactListIdentificationType',
    label: i18n(
      'entities.contactList.fields.contactListIdentificationType',
    ),
  },
  {
    name: 'contactListIdentificationNo',
    label: i18n(
      'entities.contactList.fields.contactListIdentificationNo',
    ),
  },
  {
    name: 'contactListGender',
    label: i18n(
      'entities.contactList.fields.contactListGender',
    ),
  },
  {
    name: 'contactListDOB',
    label: i18n(
      'entities.contactList.fields.contactListDOB',
    ),
  },
  {
    name: 'contactListPhoneCountryCode',
    label: i18n(
      'entities.contactList.fields.contactListPhoneCountryCode',
    ),
  },
  {
    name: 'contactListPhone',
    label: i18n(
      'entities.contactList.fields.contactListPhone',
    ),
  },
  {
    name: 'contactListPhone2CountryCode',
    label: i18n(
      'entities.contactList.fields.contactListPhone2CountryCode',
    ),
  },
  {
    name: 'contactListPhone2',
    label: i18n(
      'entities.contactList.fields.contactListPhone2',
    ),
  },
  {
    name: 'contactListEmail',
    label: i18n(
      'entities.contactList.fields.contactListEmail',
    ),
  },
  {
    name: 'contactListRelationship',
    label: i18n(
      'entities.contactList.fields.contactListRelationship',
    ),
  },
  {
    name: 'contactListAddress',
    label: i18n(
      'entities.contactList.fields.contactListAddress',
    ),
  },
  {
    name: 'contactListRemarks',
    label: i18n(
      'entities.contactList.fields.contactListRemarks',
    ),
  },
  {
    name: 'createdDate',
    label: i18n('entities.contactList.fields.createdDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedDate',
    label: i18n('entities.contactList.fields.updatedDate'),
    render: exporterRenders.datetime(),
  },
];
