import FinancialPlanningService from 'src/modules/financialPlanning/financialPlanningService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'FINANCIAL_PLANNING_FORM';

const financialPlanningFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: financialPlanningFormActions.INIT_STARTED,
      });

      let record = {rows:[]};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await FinancialPlanningService.find(id);

        dispatch({
          type: financialPlanningFormActions.INIT_SUCCESS,
          payload: record,
        });
      } else{
        record = await FinancialPlanningService.list(null,null,null,null);

        dispatch({
          type: financialPlanningFormActions.INIT_SUCCESS,
          payload: record.rows[0],
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: financialPlanningFormActions.INIT_ERROR,
      });

      getHistory().push('/financial-planning');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: financialPlanningFormActions.CREATE_STARTED,
      });

      await FinancialPlanningService.create(values);

      dispatch({
        type: financialPlanningFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.financialPlanning.create.success'),
      );

      getHistory().push('/willListPage');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: financialPlanningFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: financialPlanningFormActions.UPDATE_STARTED,
      });

      await FinancialPlanningService.update(id, values);

      dispatch({
        type: financialPlanningFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.financialPlanning.update.success'),
      );

      getHistory().push('/willListPage');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: financialPlanningFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default financialPlanningFormActions;
