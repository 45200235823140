const executorEnumerators = {
  executorType: ['Executor', 'Trustee'],
  executorIDType: [
    'NRIC',
    'Old IC',
    'Passport',
    'Army/Police',
  ],
  executorRelationship: [
    'Spouse',
    'Cohabitee',
    'UnmarriedPartner',
    'Partner',
    'Parent',
    'SpouseParent',
    'GrandParent',
    'Child',
    'ChildPrevious',
    'SpouseChildPrevious',
    'GrandChild',
    'GreatGrandChild',
    'Sibling',
    'Relative',
    'Friend',
    'Solicitor',
    'NominatedSolicitor',
    'Charity',
  ],
  executorSequence: [0, 1, 2, 3, 4, 5, 6, 7],
};

export default executorEnumerators;
