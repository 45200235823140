import Permissions from 'src/security/permissions';
import Pages from 'src/security/page';
import { i18n } from 'src/i18n';

import {
  WalletOutlined,
  DollarOutlined,
  HomeOutlined,
  GiftOutlined,
  RedEnvelopeOutlined,
  TeamOutlined,
  PropertySafetyOutlined,
  // ShopOutlined,
  CarOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const permissions = Permissions.values;

export default [
  {
    path: '/account',
    permissionRequired: permissions.accountRead,
    icon: <WalletOutlined />,
    label: i18n('entities.account.menu'),
    type: Pages.values.Account,
    visible: AuthCurrentTenant.getAssistant("Accounts").view,
  },

  {
    path: '/insurance',
    permissionRequired: permissions.insuranceRead,
    icon: <PropertySafetyOutlined />,
    label: i18n('entities.insurance.insurance'),
    type: Pages.values.Debts,
    visible: AuthCurrentTenant.getAssistant("Insurances").view,
  },

  {
    path: '/property',
    permissionRequired: permissions.propertyRead,
    icon: <HomeOutlined />,
    label: i18n('entities.property.menu'),
    type: Pages.values.Property,
    visible: AuthCurrentTenant.getAssistant("Properties").view,
  },

  {
    path: '/vehicle',
    permissionRequired: permissions.vehicleRead,
    icon: <CarOutlined />,
    label: i18n('entities.vehicle.menu'),
    type: Pages.values.Vehicle,
    visible: AuthCurrentTenant.getAssistant("Vehicles").view,
  },

  // {
  //   path: '/liquid-asset',
  //   permissionRequired: permissions.otherAssetRead,
  //   icon: <RedEnvelopeOutlined />,
  //   label: i18n('app.liquidityAssets'),
  //   type: Pages.values.LiquidityAssets,
  // },

  {
    path: '/other-asset',
    permissionRequired: permissions.otherAssetRead,
    icon: <GiftOutlined />,
    label: i18n('entities.otherAsset.otherValuables'),
    type: Pages.values.Gifts,
    visible: AuthCurrentTenant.getAssistant("OtherGifts").view,
  },

  {
    path: '/digital',
    permissionRequired: permissions.digitalRead,
    icon: <MoneyCollectOutlined />,
    label: i18n('entities.digital.menu'),
    type: Pages.values.Digital,
    visible: AuthCurrentTenant.getAssistant("Digital").view,
  },

  {
    path: '/debt',
    permissionRequired: permissions.debtRead,
    icon: <DollarOutlined />,
    label: i18n('entities.debt.menu'),
    type: Pages.values.Debts,
    visible: AuthCurrentTenant.getAssistant("Debts").view,
  },

  // {
  //   path: '/guardians',
  //   permissionRequired: permissions.guardiansRead,
  //   icon: <TeamOutlined />,
  //   label: i18n('entities.guardians.menu'),
  //   type: Pages.values.Guardians,
  // },

  // 220517 - temporary removed business and video attestation
  // {
  //   path: '/business',
  //   permissionRequired: permissions.otherAssetRead,
  //   icon: <ShopOutlined />,
  //   label: i18n('entities.business.menu'),
  //   type: Pages.values.OtherAsset,
  // },

  // {
  //   path: '/cash',
  //   permissionRequired: permissions.cashRead,
  //   icon: <RightOutlined />,
  //   label: i18n('entities.cash.menu'),
  // },
].filter(Boolean);
