import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.videochat.fields.id'),
  },
  {
    name: 'roomName',
    label: i18n('entities.videochat.fields.roomName'),
  },
  {
    name: 'participant',
    label: i18n('entities.videochat.fields.participant'),
  },
  {
    name: 'videoRecord',
    label: i18n('entities.videochat.fields.videoRecord'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.videochat.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.videochat.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
