import { useDispatch, useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import authSelectors from 'src/modules/auth/authSelectors';
import actions from 'src/modules/appReleaseNote/view/appReleaseNoteViewActions';
import selectors from 'src/modules/appReleaseNote/view/appReleaseNoteViewSelectors';
import updateActions from 'src/modules/appReleaseNote/form/appReleaseNoteFormActions';
import parse from 'html-react-parser';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AppReleaseNoteModal = (props: {
  onClose?: any;
  isOpen?: boolean;
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(
    props.isOpen !== undefined ? props.isOpen : false,
  );
  const [content, setContent] =
    useState<any>('Stay Tuned..');

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );

  useEffect(() => {
    dispatch(actions.doFind({ type: 'testator' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appReleaseNote = useSelector(
    selectors.selectRecord,
  );

  useEffect(() => {
    const testatorNote =
      appReleaseNote?.filter(
        (item) => item.type === 'testator' && item.active,
      ) || [];
    if (
      (!currentTenant.appReleaseNoteRead || props.isOpen) &&
      testatorNote &&
      testatorNote.length > 0
    ) {
      setContent(testatorNote[0].content);
      setModalOpen(true);
    }
  }, [appReleaseNote]);

  const handleClose = () => {
    setModalOpen(false);
    dispatch(
      updateActions.doUpdate(currentTenant.id, {
        appReleaseNoteRead: true,
      }),
    );
    currentTenant.appReleaseNoteRead = true;
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby={i18n('common.appReleaseNote')}
    >
      <DialogTitle id="scroll-dialog-title">
        {i18n('common.appReleaseNote')}
      </DialogTitle>
      <DialogContent> {parse(content)}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          paddingBottom: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            marginTop: '20px',
            width: '180px',
            height: '36.5px',
            borderRadius: '64px',
            background:
              'linear-gradient(to right, rgba(0, 74, 158, 1), rgba(0, 120, 255, 1))',
            textTransform: 'none',
          }}
          onClick={handleClose}
        >
          {i18n('common.close').toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppReleaseNoteModal;
