import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import WillSettingsService from 'src/modules/willSettings/willSettingsService';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';

const prefix = 'WILLSETTINGS';

const willSettingsActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  SAVE_STARTED: `${prefix}_SAVE_STARTED`,
  SAVE_SUCCESS: `${prefix}_SAVE_SUCCESS`,
  SAVE_ERROR: `${prefix}_SAVE_ERROR`,

  doInit: () => async (dispatch) => {
    try {
      dispatch({
        type: willSettingsActions.INIT_STARTED,
      });

      const settings = await WillSettingsService.find();

      dispatch({
        type: willSettingsActions.INIT_SUCCESS,
        payload: settings,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: willSettingsActions.INIT_ERROR,
      });

      getHistory().push('/');
    }
  },

  doSave: (values) => async (dispatch) => {
    try {
      dispatch({
        type: willSettingsActions.SAVE_STARTED,
      });

      const willSettings = await WillSettingsService.save(values);

      dispatch({
        type: willSettingsActions.SAVE_SUCCESS,
        payload: willSettings,
      });

      Message.success(
        i18n('willSettings.save.success'),
      );

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: willSettingsActions.SAVE_ERROR,
      });
    }
  },
};

export default willSettingsActions;
