import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'solicitorPhoto',
    label: i18n(
      'entities.solicitor.fields.solicitorPhoto',
    ),
    schema: schemas.images(
      i18n('entities.solicitor.fields.solicitorPhoto'),
      {},
    ),
  },
  {
    name: 'solicitorName',
    label: i18n(
      'entities.solicitor.fields.solicitorName',
    ),
    schema: schemas.string(
      i18n('entities.solicitor.fields.solicitorName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'solicitorPhone',
    label: i18n(
      'entities.solicitor.fields.solicitorPhone',
    ),
    schema: schemas.string(
      i18n('entities.solicitor.fields.solicitorPhone'),
      {},
    ),
  },
  {
    name: 'solicitorPhone2',
    label: i18n(
      'entities.solicitor.fields.solicitorPhone2',
    ),
    schema: schemas.string(
      i18n('entities.solicitor.fields.solicitorPhone2'),
      {},
    ),
  },
  {
    name: 'solicitorEmail',
    label: i18n(
      'entities.solicitor.fields.solicitorEmail',
    ),
    schema: schemas.string(
      i18n('entities.solicitor.fields.solicitorEmail'),
      {},
    ),
  },
  {
    name: 'solicitorAddress',
    label: i18n(
      'entities.solicitor.fields.solicitorAddress',
    ),
    schema: schemas.string(
      i18n(
        'entities.solicitor.fields.solicitorAddress',
      ),
      {},
    ),
  },
  {
    name: 'solicitorRemarks',
    label: i18n(
      'entities.solicitor.fields.solicitorRemarks',
    ),
    schema: schemas.string(
      i18n(
        'entities.solicitor.fields.solicitorRemarks',
      ),
      {},
    ),
  },
];