import list from 'src/modules/personalMessage/list/personalMessageListReducers';
import form from 'src/modules/personalMessage/form/personalMessageFormReducers';
import view from 'src/modules/personalMessage/view/personalMessageViewReducers';
import destroy from 'src/modules/personalMessage/destroy/personalMessageDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
