import { Form, Input } from 'antd';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FormErrors from 'src/view/shared/form/formErrors';
import { isMobile } from 'react-device-detect';
import { Link } from '@material-ui/core';
import { i18n } from 'src/i18n';

export const InputFormItem = (props) => {
  const {
    label,
    name,
    hint,
    layout,
    size,
    type,
    placeholder,
    autoFocus,
    autoComplete,
    prefix,
    externalErrorMessage,
    required,
    addonAfter,
    values,
    constantWidth,
    disabled,
    labelAlign,
    hidden,
    suffix,
  } = props;

  const {
    setValue,
    watch,
    register,
    errors,
    formState: { touched, isSubmitted },
  } = useFormContext();

  useEffect(() => {
    register({ name });
  }, [register, name]);

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touched,
    isSubmitted,
    externalErrorMessage,
  );

  return (
    <Form.Item
      {...layout}
      label={label}
      labelAlign={labelAlign}
      required={required}
      validateStatus={errorMessage ? 'error' : 'success'}
      // help={errorMessage || hint}
      help={
        props.hintRemoveButton ? (
          <div>
            <div style={{ float: 'right' }}>
              {!disabled && (
                <Link
                  component="button"
                  variant="caption"
                  color="secondary"
                  onClick={(event) => {
                    event.preventDefault();
                    props.handleCount(name, false);
                    // handleSelect(undefined)
                  }}
                >
                  {i18n('common.remove')}
                </Link>
              )}
            </div>
          </div>
        ) : (
          errorMessage || hint
        )
      }
      hidden={hidden}
    >
      <div
        style={{
          maxWidth:
            !isMobile && !constantWidth ? '450px' : '100%',
        }}
      >
        {type === 'password' && (
          <Input.Password
            id={name}
            name={name}
            type={type}
            value={values ?? watch(name)}
            onChange={(event) => {
              setValue(name, event.target.value, {
                shouldValidate: true,
              });
              props.onChange &&
                props.onChange(event.target.value);
            }}
            onBlur={(event) => {
              props.onBlur && props.onBlur(event);
            }}
            size={size || undefined}
            placeholder={placeholder || undefined}
            autoFocus={autoFocus || false}
            autoComplete={autoComplete || undefined}
            prefix={prefix || undefined}
            suffix={suffix || undefined}
            addonAfter={addonAfter || undefined}
            disabled={disabled}
            hidden={hidden}
          />
        )}

        {type !== 'password' && (
          <Input
            id={name}
            name={name}
            type={type}
            value={values ?? watch(name)}
            onChange={(event) => {
              setValue(name, event.target.value, {
                shouldValidate: true,
              });
              props.onChange &&
                props.onChange(event.target.value);
            }}
            onBlur={(event) => {
              props.onBlur && props.onBlur(event);
            }}
            size={size || undefined}
            placeholder={placeholder || undefined}
            autoFocus={autoFocus || false}
            autoComplete={autoComplete || undefined}
            prefix={prefix || undefined}
            suffix={suffix || undefined}
            addonAfter={addonAfter || undefined}
            disabled={disabled}
            hidden={hidden}
          />
        )}
      </div>
    </Form.Item>
  );
};

InputFormItem.defaultProps = {
  layout: null,
  type: 'text',
  required: false,
  constantWidth: false,
  disabled: false,
  hidden: false,
};

InputFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  layout: PropTypes.object,
  values: PropTypes.string,
  errorMessage: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  externalErrorMessage: PropTypes.string,
  addonAfter: PropTypes.any,
  constantWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  refresh: PropTypes.bool,
  hintRemoveButton: PropTypes.bool,
  handleCount: PropTypes.func,
  labelAlign: PropTypes.string,
  suffix: PropTypes.string,
};

export default InputFormItem;
