import { createSelector } from 'reselect';

const selectRaw = (state) => state.userSettings;

const selectUserSettings = createSelector(
  [selectRaw],
  (raw) => raw.userSettings,
);

const selectInitLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.initLoading),
);

const selectSaveLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.saveLoading),
);

const userSettingsSelectors = {
  selectInitLoading,
  selectSaveLoading,
  selectUserSettings,
  selectRaw,
};

export default userSettingsSelectors;
