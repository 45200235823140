import { i18n } from 'src/i18n';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core/styles';
import routes from 'src/view/routes';
import Button from '@material-ui/core/Button';
import { getHistory } from 'src/modules/store';
import MobileStepper from '@material-ui/core/MobileStepper';
import { isMobile, isTablet } from 'react-device-detect';

import selectors from 'src/modules/auth/authSelectors';
import userSettingsActions from 'src/modules/userSettings/userSettingsActions';
// import userSettingsSelectors from 'src/modules/userSettings/userSettingsSelectors';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  HomeOutlined,
} from '@material-ui/icons';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import Plans from 'src/security/plans';

import onNavigate from 'src/modules/shared/navigation/navigationHandler';

//#region Styles
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: theme.spacing(4),
    backgroundColor: '#12244B',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  button: {
    color: '#fff',
    backgroundColor: '#12244B',
    borderRadius: '8px',
    minInlineSize: 'max-content',
    width: isMobile
      ? isTablet
        ? 'max-content'
        : 'min-content'
      : '',
    '&:hover': {
      backgroundColor: '#722ed1',
      color: '#fff',
    },
    '&:disabled': {
      color: 'grey',
    },
  },
}));

const MobileStepperView = withStyles({
  root: {
    width: '100%',
    flexGrow: 1,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: '#fff',
  },
  progress: {
    backgroundColor: '#ede7f6',
    '& *': {
      backgroundColor: '#12244B',
    },
  },
})(MobileStepper);
//#endregion

const NavigationFooter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    selectors.selectCurrentUser,
  );
  const currentTenant = useSelector(
    selectors.selectCurrentTenant,
  );

  const remote =
    currentTenant['createdBy'] !== currentUser['id'];
  const isWasiatWillMode =
    AuthCurrentTenant.getWillMode() === 'wasiat' ?? false;
  const [routeWithStep, setRouteWithStep] = useState([]);
  const [maxSteps, setMaxSteps] = useState(null);
  const [activeStep, setActiveStep] = useState(
    props.stepper,
  );

  useEffect(() => {
    if (routeWithStep.length <= 0) {
      getRouteWithStep();

      // if (isWasiatWillMode && activeStep > 3) {
      //   setActiveStep(activeStep - 1)
      // }
    }

    if (
      routeWithStep.length !== null &&
      routeWithStep.length !== undefined
    ) {
      if (setMaxSteps.length !== 0)
        setMaxSteps(routeWithStep.length);
    }
  }, [routeWithStep, isWasiatWillMode]);

  useEffect(() => {
    dispatch(userSettingsActions.doInitOnly());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  @ checkPlanExpiry() - Return True if plan has expired
  */
  const checkPlanExpiry = () => {
    var currentTenantExpiryDate = new Date(
      currentTenant?.planExpiresDate,
    );
    var sysDate = new Date();
    var diff =
      currentTenantExpiryDate.getTime() - sysDate.getTime();
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    if (
      currentTenant.plan === Plans.values.free ||
      currentTenant?.planExpiresDate === undefined ||
      diffDays < 0
    )
      return true;
    else return false;
  };

  const getRouteWithStep = () => {
    var temp = [] as any;
    routes.privateRoutes
      .filter(
        (x) => x.step !== undefined && x.step !== null,
      )
      .filter((x) => {
        if (isWasiatWillMode) {
          return x.isWasiatRequired === null ||
            x.isWasiatRequired === undefined
            ? true
            : x.isWasiatRequired;
        }
        return true;
      })
      .filter(
        (x, idx, list) =>
          list.findIndex((s) => s.section === x.section) ===
          idx,
      )
      .sort((a, b) => (a.step > b.step ? 1 : -1))
      .forEach((e, index, list) => {
        var previousText =
          index === 0
            ? i18n('common.back')
            : isWasiatWillMode
            ? i18n(
                `mainPage.list.wasiat.${
                  list[index - 1].section
                }`,
              )
            : i18n(
                `mainPage.list.${list[index - 1].section}`,
              );
        var previousUrl =
          index === 0
            ? '/willListPage'
            : list[index - 1].path;

        var nextText =
          index === list.length - 1
            ? i18n('mainPage.progress.completedHeader')
            : isWasiatWillMode
            ? i18n(
                `mainPage.list.wasiat.${
                  list[index + 1].section
                }`,
              )
            : i18n(
                `mainPage.list.${list[index + 1].section}`,
              );
        var nextUrl =
          index === list.length - 1
            ? '/willListPage'
            : list[index + 1].path;

        // if (isWasiatWillMode) {
        //   console.log("e", e)
        //   console.log("index", index)
        //   console.log("previousText", previousText)
        //   console.log("previousUrl", previousUrl)
        //   console.log("nextText", nextText)
        //   console.log("nextUrl", nextUrl)

        //   console.log("=========================")
        // }

        temp.push({
          path: e.path,
          step: index,
          previousText: previousText,
          previousUrl: previousUrl,
          nextText: nextText,
          nextUrl: nextUrl,
        });
      });
    setRouteWithStep(temp);
  };

  const handleBack = () => {
    if (getHistory().location.pathname === '/profile')
      path = 'willListPage';

    var path = routeWithStep[activeStep - 1].previousUrl;
    if (remote === true && path === '/profile')
      path = '/profile-assistant';

    if (path === undefined || path === null) {
      onNavigate('/willListPage');
      // getHistory().go(-1);
    }
    // else if (checkPlanExpiry()) onNavigate('/will-plan');
    else {
      onNavigate(path);
    }
  };

  const handleNext = () => {
    var path = routeWithStep[activeStep - 1].nextUrl;

    // if (checkPlanExpiry()) onNavigate('/will-plan');
    // else
    if (getHistory().location.pathname === '/willListPage')
      onNavigate('/');
    else if (activeStep === null || activeStep === 8)
      onNavigate('/willListPage');
    else if (activeStep === maxSteps)
      onNavigate('/willListPage');
    else if (path !== null) {
      onNavigate(path);
    }
  };

  const showMenu = () => {
    if (getHistory().location.pathname === '/willListPage')
      onNavigate('/');
    else onNavigate('/willListPage');
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingLeft: '3%',
        // paddingRight: '3%',
        position: 'initial',
        width: '-webkit-fill-available',
        maxWidth: '1300px',
      }}
    >
      {maxSteps && (
        <MobileStepperView
          steps={maxSteps}
          position="static"
          variant="progress"
          activeStep={activeStep - 1}
          backButton={
            <Button
              className={classes.button}
              size="small"
              onClick={handleBack}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {routeWithStep.length > 0 &&
                routeWithStep[activeStep - 1].previousText}
            </Button>
          }
          nextButton={
            <Button
              className={classes.button}
              size="small"
              onClick={handleNext}
            >
              {routeWithStep.length > 0 &&
                routeWithStep[activeStep - 1].nextText}
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
        />
      )}

      {isMobile && (
        <Button
          className={classes.button}
          style={{
            position: 'absolute',
          }}
          onClick={showMenu}
        >
          <HomeOutlined />
        </Button>
      )}
    </div>
  );
};

export default NavigationFooter;
