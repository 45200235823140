import ExecutorService from 'src/modules/executor/executorService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'EXECUTOR_FORM';

const executorFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  INVITE_STARTED: `${prefix}_INVITE_STARTED`,
  INVITE_SUCCESS: `${prefix}_INVITE_SUCCESS`,
  INVITE_ERROR: `${prefix}_INVITE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: executorFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await ExecutorService.find(id);
      }

      dispatch({
        type: executorFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: executorFormActions.INIT_ERROR,
      });

      getHistory().push('/executor');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: executorFormActions.CREATE_STARTED,
      });

      await ExecutorService.create(values);

      dispatch({
        type: executorFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.executor.create.success'),
      );

      getHistory().push('/executor');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: executorFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: executorFormActions.UPDATE_STARTED,
      });

      await ExecutorService.update(id, values);

      dispatch({
        type: executorFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.executor.update.success'),
      );

      getHistory().push(`/executor/${id}`);

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: executorFormActions.UPDATE_ERROR,
      });
    }
  },

  doUpdateMultiple:
    (values, silent) => async (dispatch, getState) => {
      try {
        dispatch({
          type: executorFormActions.UPDATE_STARTED,
        });
        await values.map((item, index) => {
          let tempData = {
            index: index,
            executorName: item.executorName,
            executorType: item.executorType || [],
            executorIDType: item.executorIDType,
            executorIdentification:
              item.executorIdentification,
            executorAddress: item.executorAddress,
            executorPhone: item.executorPhone,
            executorPhone2: item.executorPhone2,
            executorEmail: item.executorEmail,
            executorPhoto: item.executorPhoto || [],
            fee: item.fee,
            executorCurrency:
              item.executorCurrency || 'malaysianRinggit',
            executorRemarks: item.executorRemarks,
            executorSequence: index,
            executorRelationship: item.executorRelationship,
            executorStatus: item.executorStatus,
          };

          ExecutorService.update(item.id, tempData);
          //BC19012022 - bypass array-callback-warning
          return index;
        });

        dispatch({
          type: executorFormActions.UPDATE_SUCCESS,
        });

        Message.success(
          i18n(
            `entities.executor.update.${silent ? 'silent' : 'success'
            }`,
          ),
        );

        getHistory().push('/executor');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: executorFormActions.UPDATE_ERROR,
        });
      }
    },


  doInvite: (values, currentTenant) => async (dispatch) => {
    try {
      dispatch({
        type: executorFormActions.INVITE_STARTED,
      });

      var para = {
        email: values.executorEmail,
        token: values.executorToken,
        currentTenant: currentTenant,
      }

      await ExecutorService.invite(values, para);

      dispatch({
        type: executorFormActions.INVITE_SUCCESS,
      });

      Message.success(
        i18n('entities.executor.invite.success'),
      );

      getHistory().push('/executor');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: executorFormActions.INVITE_ERROR,
      });
    }
  },
};

export default executorFormActions;
