import listActions from 'src/modules/beneficiary/list/beneficiaryListActions';
import contactListListActions from 'src/modules/contactList/list/contactListListActions';
import BeneficiaryService from 'src/modules/beneficiary/beneficiaryService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';
import AccountService from 'src/modules/account/accountService';
import PropertyService from 'src/modules/property/propertyService';
import ResiduaryService from 'src/modules/residuary/residuaryService';
import AssetsService from 'src/modules/otherAsset/otherAssetService';
import InstructionService from 'src/modules/lastInstructions/lastInstructionsService';
import GuardiansService from 'src/modules/guardians/guardiansService';
import ContactListService from 'src/modules/contactList/contactListService';
import { formActionNavigator } from 'src/utils/functions';

const prefix = 'BENEFICIARY_DESTROY';

const is_binded = async (id) => {
  let response = await Promise.all([
    // AccountService.list(
    //   { accBen: `${id}` },
    //   null,
    //   null,
    //   null,
    // ),
    PropertyService.list(
      { propBen: `${id}` },
      null,
      null,
      null,
    ),
    AssetsService.list(
      { othBen: `${id}` },
      null,
      null,
      null,
    ),
    ResiduaryService.list(
      { resBen: `${id}` },
      null,
      null,
      null,
    ),
    InstructionService.list(
      { instructionAttentionTo: `${id}` },
      null,
      null,
      null,
    ),
  ]).then((data) => {
    var Is_Binding = false;

    data.forEach((item) => {
      if (item.count > 0) {
        Is_Binding = true;
      }
    });

    return Is_Binding;
  });

  let ben_info = await BeneficiaryService.find(id);

  return {
    is_binded: response,
    ben_name: ben_info?.benName?.toUpperCase(),
  };
};

const deleteLegalGuardian = async (ids) => {
  await ids.forEach(async (item) => {
    var guardians_list = await GuardiansService.list(
      { Child_Beneficiary: item },
      null,
      null,
      null,
    );

    if (guardians_list.count > 0) {
      var guardians = guardians_list.rows[0];

      await GuardiansService.destroyAll([guardians.id]);
    }
  });
};

const beneficiaryDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, type?) => async (dispatch) => {
    try {
      dispatch({
        type: beneficiaryDestroyActions.DESTROY_STARTED,
      });

      await BeneficiaryService.destroyAll([id]);

      dispatch({
        type: beneficiaryDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.beneficiary.destroy.success'),
      );

      switch (type) {
        case 'Contact-List':
          dispatch(
            contactListListActions.doFetchCurrentFilter(),
          );
          getHistory().push('/contact-list');
          break;
        default:
          dispatch(listActions.doFetchCurrentFilter());
          formActionNavigator('beneficiary', 'home');
          break;
      }
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: beneficiaryDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyContact: (id, type?) => async (dispatch) => {
    try {
      dispatch({
        type: beneficiaryDestroyActions.DESTROY_STARTED,
      });

      await ContactListService.destroyAll([id]);

      dispatch({
        type: beneficiaryDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.contactList.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      formActionNavigator('beneficiary', 'home');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: beneficiaryDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: beneficiaryDestroyActions.DESTROY_ALL_STARTED,
      });

      await BeneficiaryService.destroyAll(ids);

      dispatch({
        type: beneficiaryDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.beneficiary.destroyAll.success'),
      );

      formActionNavigator('beneficiary', 'home');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: beneficiaryDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default beneficiaryDestroyActions;
