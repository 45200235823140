import listActions from 'src/modules/executor/list/executorListActions';
import contactListListActions from 'src/modules/contactList/list/contactListListActions';
import ExecutorService from 'src/modules/executor/executorService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'EXECUTOR_DESTROY';

const executorDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, type?) => async (dispatch) => {
    try {
      dispatch({
        type: executorDestroyActions.DESTROY_STARTED,
      });

      await ExecutorService.destroyAll([id]);

      dispatch({
        type: executorDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.executor.destroy.success'),
      );


      switch (type){
        case "Contact-List":
          dispatch(contactListListActions.doFetchCurrentFilter());
          getHistory().push('/contact-list');
          break;
        default:
          dispatch(listActions.doFetchCurrentFilter());
          getHistory().push('/contact-list');
          break;
      }
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: executorDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: executorDestroyActions.DESTROY_ALL_STARTED,
      });

      await ExecutorService.destroyAll(ids);

      dispatch({
        type: executorDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.executor.destroyAll.success'),
      );

      getHistory().push('/executor');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: executorDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default executorDestroyActions;
